import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

type PlayerStatusStr = 'offline' | 'online' | 'playing';

const handleColorType = (status: PlayerStatusStr) => {
  switch (status) {
    case 'offline':
      return Colors.gray;
    case 'online':
      return '#5AB64E';
    case 'playing':
      return '#f56342';
    default:
      return 'black';
  }
};

const StyledP = styled.p<{ status: PlayerStatusStr }>`
  display: inline-block;
  margin: 0;
  font-size: 14px;
  &::before {
    content: '● ';
    font-family: auto;
    color: ${({ status }) => handleColorType(status)};
  }
`;

type Props = {
  status: number;
  className?: string;
  withStatusLabel?: boolean;
};

const PlayerStatus: FC<Props> = ({ status, className, withStatusLabel = true }) => {
  if (status === 0)
    return (
      <StyledP className={className} status="offline">
        {withStatusLabel && '審査中'}
      </StyledP>
    );
  if (status === 100)
    return (
      <StyledP className={className} status="offline">
        {withStatusLabel && 'メッセージ受付中'}
      </StyledP>
    );
  if (status === 101)
    return (
      <StyledP className={className} status="online">
        {withStatusLabel && 'プレイ可能'}
      </StyledP>
    );
  if (status === 102)
    return (
      <StyledP className={className} status="playing">
        {withStatusLabel && 'プレイ中'}
      </StyledP>
    );
  if (status >= 900)
    return (
      <StyledP className={className} status="offline">
        {withStatusLabel && '退会'}
      </StyledP>
    );
  return <></>;
};

export default PlayerStatus;
