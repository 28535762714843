import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const StyledLabel = styled.label`
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  display: block;
`;

const Required = styled.span`
  display: inline-block;
  height: 20px;
  background-color: ${Colors.pink};
  color: white;
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
  padding: 0 3px;
  margin: 0 3px;
`;

type Props = {
  required: boolean;
  children?: ReactNode;
  className?: string;
};

const FormLabel: FC<Props> = ({ children, required, className }) => {
  return (
    <StyledLabel className={className}>
      {children}
      {required && <Required>必須</Required>}
    </StyledLabel>
  );
};

export default FormLabel;
