
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Colors from 'Utils/Colors';

import { User } from 'Schemes/User';
import { playerMainProfileImage, userMainProfileImage } from 'Utils/ProfileImage';
import { FC } from 'react';

const Wrapper = styled.section`
  position: fixed;
  z-index: 10000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.75);
  &.invisible {
    display: none;
  }
`;

const ContentBG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
`;

const showSideBar = keyframes`
  0% { width: 0px }
  100% { width: 250px }
`;

const hideSideBar = keyframes`
  0% { width: 250px }
  100% { width: 0px }
`;

const ContentWrapper = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 100%;
  z-index: 100;
  background-color: white;
  &.visible {
    animation: ${showSideBar} 0.1s forwards;
  }
  &.invisible {
    animation: ${hideSideBar} 0.1s forwards;
  }
`;

const UserProfile = styled.div`
  display: table;
  height: 90px;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 20px 8px 20px 20px;
  float: left;
`;

const ProfileName = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 90px;
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 8px;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
`;

const MenuWrapper = styled.div`
  padding-bottom: 20px;
`;

const MenuHeader = styled.p`
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: ${Colors.bgPink};
  color: ${Colors.black};
  font-weight: bold;
  font-size: 14px;
  text-indent: 20px;
  margin: 0;
`;

const MenuList = styled.ul`
  width: 100%;
  line-height: 40px;
  padding: 0;
  margin: 0;
  color: ${Colors.bgPink};
  li {
    height: 14px;
    padding: 14px 0;
    text-indent: 30px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.link};
`;

type Props = {
  showSideBar: boolean;
  setShowSidebar: Function;
  user: User | null;
};

const Sidebar: FC<Props> = ({ showSideBar, setShowSidebar, user }) => {
  const renderProfile = (user: User) => {
    if (user.PlayerProfile) {
      return (
        <UserProfile>
          <ProfileImage profileImage={playerMainProfileImage(user.PlayerProfile)} />
          <ProfileName>{user.PlayerProfile.Name}</ProfileName>
        </UserProfile>
      );
    } else {
      return (
        <UserProfile>
          <ProfileImage profileImage={userMainProfileImage(user)} />
          <ProfileName>{user.Name}</ProfileName>
        </UserProfile>
      );
    }
  };

  return (
    <Wrapper className={`${showSideBar ? 'visible' : 'invisible'}`}>
      <ContentBG onClick={() => setShowSidebar(false)}></ContentBG>
      <ContentWrapper className={`${showSideBar ? 'visible' : 'invisible'}`}>
        {user && (
          <>
            {renderProfile(user)}
            {user.PlayerProfile && user.PlayerProfile.PlayerStatus > 99 && (
              <MenuWrapper>
                <MenuHeader>プレイヤー管理</MenuHeader>
                <MenuList>
                  <li>
                    <StyledLink to="/" onClick={() => setShowSidebar(false)}>
                      ダッシュボード
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/player/inboxes" onClick={() => setShowSidebar(false)}>
                      メッセージ
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/player/payments" onClick={() => setShowSidebar(false)}>
                      購入者一覧
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/player/followers" onClick={() => setShowSidebar(false)}>
                      フォロワー
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to="/player/payments/balance" onClick={() => setShowSidebar(false)}>
                      売上確認・振込申請
                    </StyledLink>
                  </li>
                </MenuList>
              </MenuWrapper>
            )}
            <MenuWrapper>
              <MenuHeader>メニュー</MenuHeader>
              <MenuList>
                <li>
                  <StyledLink to="/players" onClick={() => setShowSidebar(false)}>
                    プレイヤーを探す
                  </StyledLink>
                </li>
                <li>
                  <StyledLink to="/settings/receipts" onClick={() => setShowSidebar(false)}>
                    購入履歴
                  </StyledLink>
                </li>
                <li>
                  <StyledLink to="/settings/followers" onClick={() => setShowSidebar(false)}>
                    フォロー
                  </StyledLink>
                </li>
                <li>
                  <StyledLink to="/settings" onClick={() => setShowSidebar(false)}>
                    アカウント設定
                  </StyledLink>
                </li>
              </MenuList>
            </MenuWrapper>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Sidebar;
