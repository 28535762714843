import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCommentAlt } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';

import logo from 'Assets/logo.svg';
import { User, UserContextType } from 'Schemes/User';
import Colors from 'Utils/Colors';
import Config from 'Config/Config';

const HeaderWrapper = styled.header`
  width: 100%;
  height: 70px;
  background-color: white;
  border-bottom: 1px solid #dadada;
  display: flex;
  justify-content: space-between;
`;

const FlexBox = styled.div`
  width: 100px;
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
`;

const LogoImg = styled.img`
  width: auto;
  height: 51px;
  margin: 10px 0 9px;
`;

const Icon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  padding: 28px;
  cursor: pointer;
`;

const StyledLogoLink = styled(Link)`
  display: block;
  margin: 0 auto;
`;

const StyledLoginLink = styled(Link)`
  color: ${Colors.pink};
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
  display: block;
  margin-right: 2px;
  padding: 4px 8px;
`;

const StyledRegisterLink = styled(Link)`
  background: ${Colors.pink};
  color: ${Colors.white};
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  text-decoration: none;
  padding: 4px 8px;
`;

const InboxLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.black};
  display: block;
  float: right;
  position: relative;
`;

const NotificationCircle = styled.p`
  z-index: 10;
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background-color: ${Colors.pink};
  right: 15px;
  top: 4px;
`;

const LoginLinkWrapper = styled.div`
  position: absolute;
  width: 160px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

type Props = {
  showLoginLink: boolean;
  setShowSidebar?: Function;
  userContext: UserContextType;
};

const Header: FC<Props> = ({ setShowSidebar, userContext, showLoginLink }) => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [lastUnreadFetched, setLastUnreadFetched] = useState(0);
  const user = userContext.user;

  const updateUnread = (user: User | null) => {
    if (user === null) {
      setUnreadCount(0);
    } else {
      const unreadLink = user.PlayerProfile ? '/player/unread_inboxes' : '/user/unread_inboxes';
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}${unreadLink}`)
        .then((response) => {
          const data = response.data ? response.data.Count : 0;
          setUnreadCount(data);
          setTimeout(() => {
            const date = new Date();
            setLastUnreadFetched(date.getTime());
          }, 60 * 1000);
        });
    }
  };

  useEffect(() => {
    updateUnread(user);
  }, [user, lastUnreadFetched]);

  const renderInboxLink = (user: User | null) => {
    if (user) {
      const inboxLink = user.PlayerProfile ? '/player/inboxes' : '/inboxes';
      return (
        <InboxLink to={inboxLink}>
          {unreadCount > 0 && <NotificationCircle />}
          <Icon icon={faCommentAlt} />
        </InboxLink>
      );
    } else {
      return <></>;
    }
  };

  if (!userContext.initialLoaded) {
    return (
      <HeaderWrapper>
        <StyledLogoLink to="/">
          <LogoImg src={logo} alt="2PLAY" />
        </StyledLogoLink>
      </HeaderWrapper>
    );
  }

  return (
    <HeaderWrapper>
      <FlexBox>{user && setShowSidebar && <Icon icon={faBars} onClick={() => setShowSidebar(true)} />}</FlexBox>
      <StyledLogoLink to="/">
        <LogoImg src={logo} alt="2PLAY" />
      </StyledLogoLink>
      <FlexBox>
        {renderInboxLink(user)}
        {showLoginLink && user === null && (
          <LoginLinkWrapper>
            <StyledLoginLink to="/login">ログイン</StyledLoginLink>
            <StyledRegisterLink to="/sign_up">新規登録</StyledRegisterLink>
          </LoginLinkWrapper>
        )}
      </FlexBox>
    </HeaderWrapper>
  );
};

export default Header;
