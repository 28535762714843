const Colors: any = {
  bgPink: '#FEF8F8',
  bgGray: '#F3F3F3',
  linkPink: '#FB2877',
  pink: '#FB2877',
  borderPink: '#FDC2D8',
  gray: '#666666',
  borderGray: '#DADADA',
  onlineGreen: '#5AB64E',
  black: '#1C1C1C',
  orange: '#FDA05E',
  link: '#041282',
  blue: '#041282',
  lineGreen: '#00B900',
  twitterBlue: '#1DA1F2',
  white: '#FFFFFF',
  red: '#EB534F',
  green: '#69BE72',
  notificationAlertBg: '#FCECEB',
  notificationSuccessBg: '#EEF8ED',
};
export default Colors;
