import Axios from 'axios';
import InboxList from 'Components/Parts/Inbox/List';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useState } from 'react';
import { Inbox } from 'Schemes/Inbox';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import { Helmet } from 'react-helmet';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.black};
`;

const TabArea = styled.div`
  height: 50px;
  background-color: ${Colors.bgPink};
  border-bottom: 1px solid ${Colors.borderGray};
`;

const Tab = styled.span<{ selected?: boolean }>`
  cursor: pointer;
  margin: 0 20px;
  line-height: 50px;
  display: inline-block;
  height: 50px;
  box-sizing: border-box;
  font-weight: bold;
  ${(props) =>
    props.selected
      ? css`
          border-bottom: 5px solid ${Colors.pink};
          color: ${Colors.black};
        `
      : css`
          color: ${Colors.gray};
        `}
`;

const NoMessage = styled.p`
  margin: 20px;
`;

const InboxPage: FC = () => {
  const [currentTab, setCurrentTab] = useState<'player' | 'user'>('player');
  const [userInboxes, setUserInboxs] = useState<Inbox[]>([]);
  const [playerInboxes, setPlayerInboxs] = useState<Inbox[]>([]);
  const fetchInboxs = async () => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/player/inboxes`)
      .then((res) => setPlayerInboxs(res.data));
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/user/inboxes`)
      .then((res) => setUserInboxs(res.data));
  };

  useEffect(() => {
    fetchInboxs();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <TabArea>
          <Tab selected={currentTab === 'player'} onClick={() => setCurrentTab('player')}>
            プレイヤー
          </Tab>
          <Tab selected={currentTab === 'user'} onClick={() => setCurrentTab('user')}>
            ユーザー
          </Tab>
        </TabArea>
        {currentTab === 'player' &&
          playerInboxes.map((inbox) => {
            return (
              <StyledLink key={`/player/chat/${inbox.UserUUID}`} to={`/player/chat/${inbox.UserUUID}`}>
                <InboxList inbox={inbox} readFlg={inbox.ReadFlg} />
              </StyledLink>
            );
          })}
        {currentTab === 'player' && playerInboxes.length === 0 && <NoMessage>まだメッセージはありません。</NoMessage>}
        {currentTab === 'user' &&
          userInboxes.map((inbox) => {
            return (
              <StyledLink key={`/chat/${inbox.PlayerUUID}`} to={`/chat/${inbox.PlayerUUID}`}>
                <InboxList inbox={inbox} readFlg={inbox.ReadFlg} />
              </StyledLink>
            );
          })}
        {currentTab === 'user' && userInboxes.length === 0 && <NoMessage>まだメッセージはありません。</NoMessage>}
      </BasicLayout>
    </>
  );
};

export default InboxPage;
