import { useEffect, useState, useRef, FC, useContext } from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { UserContext } from 'App';
import Config from 'Config/Config';
import Colors from 'Utils/Colors';
import { PlayerList } from 'Schemes/Player';
import { shufflePlayerList } from 'Utils/ShuffleList';
import PlayerBlock from 'Components/Players/Block';
import MenuTitle from 'Components/Parts/MenuTitle';
import Pagination from 'Components/Pagination';

const StyledPlayerBlock = styled(PlayerBlock)`
  background-color: ${Colors.bgPink};
`;

const PlayersWrapper = styled.section`
  text-align: center;
  background-color: ${Colors.white};
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 3% 6%;
  box-sizing: border-box;
`;

const PlayerListAll: FC = () => {
  const { user } = useContext(UserContext);
  const [players, setPlayers] = useState([]);
  const [featured, setFeatured] = useState([]);
  const [rookies, setRookies] = useState([]);
  const [playersTotalCount, setPlayersTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const playersTopRef = useRef<HTMLDivElement>(null);
  const playersPerPage = 20;

  const fetchPlayers = (page: number, limit: number) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/players`, { params: { page: page, limit: limit }})
      .then((res) => {
        setPlayersTotalCount(res.data.TotalCount);
        setPlayers(res.data.Players);
      });
  };

  const handlePageChangePlayers = (page: number) => {
    const nextPage = page + 1;
    fetchPlayers(nextPage, playersPerPage);
    setCurrentPage(nextPage);
    playersTopRef.current?.scrollIntoView();
  }

  useEffect(()=>{
    const fetchFeatured = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/featured`)
        .then((res) => {
          setFeatured(res.data);
        });
    }
    const fetchRookies = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/rookie`)
        .then((res) => {
          setRookies(res.data);
        });
    }

    fetchPlayers(currentPage, playersPerPage);
    fetchFeatured();
    fetchRookies();
  }, [currentPage])

  return (
    <>
      <MenuTitle>注目の2PLAYプレイヤー</MenuTitle>
      <PlayersWrapper>
        {featured.map((player: PlayerList) => {
          return <PlayerBlock key={`layer${player.UUID}`} player={player} currentUser={user} />;
        })}
      </PlayersWrapper>
      <MenuTitle>新人2PLAYプレイヤー</MenuTitle>
      <PlayersWrapper>
        {shufflePlayerList(rookies).map((player: PlayerList) => {
          return <PlayerBlock key={`layer${player.UUID}`} player={player} currentUser={user} />;
        })}
      </PlayersWrapper>
      <div ref={playersTopRef}></div>
      <MenuTitle>2PLAY公式プレイヤー</MenuTitle>
      <PlayersWrapper>
        {players.map((player: PlayerList) => {
          return <StyledPlayerBlock key={`layer${player.UUID}`} player={player} currentUser={user} />;
        })}
      </PlayersWrapper>
      <Pagination
        totalCount={playersTotalCount}
        perPage={playersPerPage}
        handlePageChange={handlePageChangePlayers}
      />
    </>
  )
}

export default PlayerListAll;
