import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-solid-svg-icons';

type Props = {
  audioURL: string;
  audioDuration: number;
  className?: string;
  onAudioDurationChange?: (duration: number) => void;
};

const Wrapper = styled.div`
  display: inline-block;
  border-radius: 5px;
  border: 1px solid ${Colors.orange};
  height: 30px;
  line-height: 30px;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  vertical-align: middle;
  color: ${Colors.orange};
`;

const StyledAudioIcon = styled(FontAwesomeIcon)`
  padding: 0 5px;
  font-size: 14px;
`;

const StyledAudioDuration = styled.span`
  font-size: 14px;
  line-height: 30px;
  font-weight: bold;
`;

const StyledAudioLabel = styled.p`
  font-size: 12px;
  line-height: 30px;
  height: 30px;
  vertical-align: middle;
  padding: 0 8px 0 20px;
  font-weight: bold;
  margin: -3px 0 0;
  display: inline-block;
`;

const AudioButton: FC<Props> = ({ audioURL, audioDuration, className, onAudioDurationChange }) => {
  const [audioPlaying, setAudioPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  audioRef.current?.addEventListener('ended', (_) => {
    setAudioPlaying(false);
  });

  const onLoadedMetadata = () => {
    const duration = audioRef.current?.duration;
    if (duration === undefined || isNaN(duration) || onAudioDurationChange === undefined) return;
    onAudioDurationChange(duration);
  };

  const toggleAudioPlay = () => {
    if (audioPlaying) {
      audioRef.current?.pause();
      setAudioPlaying(false);
    } else {
      audioRef.current?.play();
      setAudioPlaying(true);
    }
  };

  const flooredAudioDuration = () => {
    return Math.floor(audioDuration * 10) / 10;
  };

  useEffect(() => {
    setAudioPlaying(false);
  }, [audioURL]);

  const icon = audioPlaying ? faPauseCircle : faPlayCircle;

  if (audioURL === null) return <></>;

  return (
    <Wrapper onClick={toggleAudioPlay} className={className}>
      <StyledAudioIcon icon={icon} />
      {audioDuration > 0 && <StyledAudioDuration>{flooredAudioDuration()}秒</StyledAudioDuration>}
      <StyledAudioLabel>再生する</StyledAudioLabel>
      <audio ref={audioRef} src={audioURL} onLoadedMetadata={onLoadedMetadata} />
    </Wrapper>
  );
};

export default AudioButton;
