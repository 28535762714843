import { FC } from 'react';
import { PlayerGame } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { BaseButton } from '../Buttons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';

const SelectGame = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.white};
  justify-content: space-between;
`;

const Left = styled.div`
  display: table;
`;

const GameIcon = styled.img`
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 10px;
`;

const GameTitle = styled.p`
  height: 60px;
  vertical-align: middle;
  display: table-cell;
  margin: 0px;
  text-align: left;
  font-size: 14px;
`;

const ActionButtonArea = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  border-bottom: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.bgGray};
`;

const ActionButton = styled.div`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.pink};
  line-height: 20px;
  padding: 10px 0;
`;

const ChangeButton = styled(BaseButton)`
  height: 36px;
  margin: 12px 20px 12px 0;
  font-size: 12px;
  float: right;
  color: ${Colors.gray};
  border-color: ${Colors.gray};
`;

type Props = {
  currentGame: PlayerGame | null;
  setShowSelectGameModal: (value: boolean) => void;
  setPurchasing: (value: boolean) => void;
};

const InboxDefaultAction: FC<Props> = ({ currentGame, setShowSelectGameModal, setPurchasing }) => {
  return (
    <>
      <SelectGame>
        <Left>
          {currentGame && (
            <>
              <GameIcon src={currentGame.IconURL} />
              <GameTitle>「{currentGame.Name}」を選択中</GameTitle>
            </>
          )}
          {currentGame === null && (
            <>
              <GameIcon src="/assets/noimage.jpg" />
              <GameTitle>現在ゲームは選択されていません。</GameTitle>
            </>
          )}
        </Left>
        <ChangeButton onClick={() => setShowSelectGameModal(true)} value="変更する" />
      </SelectGame>
      <ActionButtonArea>
        <ActionButton onClick={() => setPurchasing(true)}>
          <FontAwesomeIcon icon={faTicketAlt} />
          <br />
          チケット購入
        </ActionButton>
      </ActionButtonArea>
    </>
  );
};

export default InboxDefaultAction;
