import { FC, FormEvent, useState } from 'react';
import { useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import FormLabel from 'Components/Parts/Forms/Label';
import MenuTitle from 'Components/Parts/MenuTitle';
import Config from 'Config/Config';
import Axios from 'axios';
import PriceInfo from './PriceInfo';
import { CurrentPayment } from 'Schemes/Payment';
import { NotificationAlert } from 'Components/Parts/Notification';

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormWrapper = styled.section`
  padding: 0 20px 20px 20px;
  background-color: ${Colors.white};
`;

const InputWrapper = styled.div`
  padding: 20px 0 0;
`;

const inputStyle = {
  lineHeight: '50px',
  color: `${Colors.black}`,
};

const PayWithPaymentMethodWrapper = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PayWithPaymentMethod = styled.span`
  text-decoration: underline;
  color: ${Colors.pink};
  display: block;
  cursor: pointer;
`;

const CartElementStyle = css`
  border: none;
  border-radius: 5px;
  border: 1px solid ${Colors.borderGray};
  width: 100%;
  height: 50px;
  display: block;
  margin-top: 8px;
  padding-left: 1em;
  box-sizing: border-box;
`;

const StyledCardNumberElement = styled(CardNumberElement)`
  ${CartElementStyle}
`;

const StyledCardExpiryElement = styled(CardExpiryElement)`
  ${CartElementStyle}
`;

const StyledCardCvcElement = styled(CardCvcElement)`
  ${CartElementStyle}
`;

const NotificationWrapper = styled.div`
  padding: 20px 0 0;
  background-color: ${Colors.white};
`;

const StyledButton = styled.button`
  width: 60%;
  height: 60px;
  padding: 0 11px;
  border-radius: 5px;
  border: 1px solid ${Colors.pink};
  background-color: ${Colors.pink};
  color: white;
  font-weight: bold;
  line-height: 60px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

type Props = {
  userID: string;
  playerID: string;
  orderCount: number;
  price: number;
  discountPrice: number;
  campaignCode: string;
  closeModal: () => void;
  setOrderByNewCard: (value: boolean) => void;
  currentPayment: CurrentPayment | null;
  hasPaymentMethods: boolean;
  afterCheckoutAction?: () => void;
  isFirstTimeDiscount?: boolean;
};

const CheckoutFormWithNewCard: FC<Props> = ({
  closeModal,
  userID,
  playerID,
  price,
  discountPrice,
  campaignCode,
  orderCount,
  hasPaymentMethods,
  setOrderByNewCard,
  currentPayment,
  afterCheckoutAction,
  isFirstTimeDiscount
}) => {
  const [cardAlertMessage, setCardAlertMessage] = useState<string | null>(null);
  const [checkouting, setCheckouting] = useState<boolean>(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCheckouting(true);
    setCardAlertMessage(null);

    const url =
      currentPayment?.Status === 1
        ? `${Config.API_HOST}/user/checkout/extend`
        : `${Config.API_HOST}/user/checkout/intent`;

    let res;
    try {
      res = await Axios.create({ withCredentials: true }).post(url, {
        PaymentUUID: currentPayment?.UUID,
        Amount: price,
        PlayerID: playerID,
        Quontity: orderCount,
        CampaignCode: campaignCode,
      });
    } catch (error) {
      setCardAlertMessage('決済に失敗しました。');
      setCheckouting(false);
    }

    if (!res) return;
    const secret = res.data.ClientSecret;
    const card = elements?.getElement('cardNumber');
    const result = await stripe?.confirmCardPayment(secret, {
      payment_method: {
        card: card!,
        billing_details: {
          name: userID,
        },
      },
      setup_future_usage: 'off_session',
    });

    if (result?.error) {
      Axios.create({ withCredentials: true }).post(`${Config.API_HOST}/user/checkout/intent/cancel_failed`, {
        playerID: playerID,
      });
      setCardAlertMessage(result?.error.message || null);
    } else {
      if (result?.paymentIntent?.status === 'requires_capture' || result?.paymentIntent?.status === 'succeeded') {
        closeModal();
      }
      console.log(result);
      if (afterCheckoutAction) afterCheckoutAction();
    }
    setCheckouting(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <MenuTitle>
          クレジットカード情報入力
        </MenuTitle>
        <FormWrapper>
          {cardAlertMessage && (
            <NotificationWrapper>
              <NotificationAlert>{cardAlertMessage}</NotificationAlert>
            </NotificationWrapper>
          )}
          <InputWrapper>
            <FormLabel required={true}>カード番号</FormLabel>
            <StyledCardNumberElement options={{ style: { base: inputStyle } }} id="card_number" />
          </InputWrapper>
          <FlexWrap>
            <InputWrapper style={{ width: '46%' }}>
              <FormLabel required={true}>有効期限</FormLabel>
              <StyledCardExpiryElement options={{ style: { base: inputStyle } }} />
            </InputWrapper>
            <InputWrapper style={{ width: '46%' }}>
              <FormLabel required={true}>CVC</FormLabel>
              <StyledCardCvcElement options={{ style: { base: inputStyle } }} />
            </InputWrapper>
          </FlexWrap>
        </FormWrapper>

        <PriceInfo 
          price={price} 
          discountPrice={discountPrice} 
          orderCount={orderCount} 
          isFirstTimeDiscount={isFirstTimeDiscount}
        />

        <StyledButton type="submit" disabled={!stripe || checkouting}>
          チケットを購入する
        </StyledButton>
        {hasPaymentMethods && (
          <PayWithPaymentMethodWrapper>
            <PayWithPaymentMethod onClick={() => setOrderByNewCard(false)}>
              登録済のカードを利用する
            </PayWithPaymentMethod>
          </PayWithPaymentMethodWrapper>
        )}
      </form>
    </>
  );
};

export default CheckoutFormWithNewCard;
