import styled from 'styled-components';
import Colors from 'Utils/Colors';

export const InputField = styled.input`
  border: none;
  border-radius: 5px;
  border: 1px solid ${Colors.borderGray};
  width: 100%;
  height: 50px;
  font-size: 14px;
  text-indent: 1em;
  display: block;
  margin-top: 8px;
  padding: 0;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
`;
