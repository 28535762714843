
import { Inbox } from 'Schemes/Inbox';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { formatTimeString } from 'Utils/TimeUtil';
import { FC } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const Wrapper = styled.section<{ readFlg: boolean }>`
  width: 100%;

  height: 90px;
  border-bottom: 1px solid ${Colors.borderGray};
  position: relative;
  box-sizing: border-box;

  ${(props) =>
    props.readFlg
      ? css`
          background: white;
        `
      : css`
          border-left: 4px solid ${Colors.pink};
          background-color: ${Colors.bgPink};
        `}
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 20px 8px 20px 20px;
  float: left;
`;

const MessageInfo = styled.div`
  margin: 15px 0 0 0;
  width: calc(100% - 50px - 8px - 8px - 20px - 24px - 30px);
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 8px;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
  float: left;
`;

const EllipsisableText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileName = styled.p`
  ${EllipsisableText};
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;

const Message = styled.p`
  ${EllipsisableText};
  font-size: 12px;
  margin: 4px 0;
  color: ${Colors.gray};
`;

const Date = styled.p`
  font-size: 10px;
  margin: 4px 0;
  color: ${Colors.gray};
`;

const StyledAngle = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: 33px 20px;
  line-height: 90px;
  height: 24px;
  display: inline-block;
  color: ${Colors.black};
  position: absolute;
  top: 0;
  right: 0;
`;

type Props = {
  inbox: Inbox;
  readFlg: boolean;
};

const InboxList: FC<Props> = ({ inbox, readFlg }) => {
  const profileImage = inbox.ProfileImage || '/assets/noimage.jpg';

  return (
    <Wrapper readFlg={readFlg}>
      {inbox.PlayerUUID === undefined ? 
        <ProfileImage profileImage={profileImage} /> : 
        <Link to={`/players/${inbox.PlayerUUID}`}>
          <ProfileImage profileImage={profileImage} />
        </Link>
       }
      <MessageInfo>
        <ProfileName>{inbox.Name}</ProfileName>
        <Message>{parse(inbox.Message)}</Message>
        <Date>{formatTimeString(inbox.SentAt)}</Date>
      </MessageInfo>
      <StyledAngle icon={faAngleRight} />
    </Wrapper>
  );
};

export default InboxList;
