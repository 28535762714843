
import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const StyledRadioWrapper = styled.div`
  margin: 0.8rem 0;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    color: ${Colors.black};
    font-size: 14px;
    + .radio-label {
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 1px solid ${Colors.borderGray};
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 0.1em;
        margin-right: 0.4em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: ${Colors.pink};
          box-shadow: inset 0 0 0 4px white;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: ${Colors.pink};
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px ${Colors.borderGray};
          border-color: darken(${Colors.borderGray}, 25%);
          background: darken(${Colors.borderGray}, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
`;

type Props = {
  label: string;
  name: string;
  onChange: Function;
  checked: boolean;
};

const StyledRadio: FC<Props> = ({ label, checked, name, onChange }) => {
  return (
    <StyledRadioWrapper onClick={() => onChange()}>
      <label>
        {checked && <input type="radio" name={name} defaultChecked />}
        {!checked && <input type="radio" name={name} />}
        <label className="radio-label">{label}</label>
      </label>
    </StyledRadioWrapper>
  );
};

export default StyledRadio;
