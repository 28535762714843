import { useState, useEffect, FC } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { Game } from 'Schemes/Game';
import GameBlock from './Block';
import Config from 'Config/Config';

type GameListProps = {
  page?: number;
};

const Wrapper = styled.section`
  text-align: center;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 24px 12px;
  box-sizing: border-box;
  background-color: ${Colors.bgPink};
  flex-wrap: wrap;
  justify-content: space-between;
`;

const GameList: FC<GameListProps> = ({ page }) => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    const getGames = async () => {
      const response = await Axios.create({ withCredentials: true }).get(`${Config.API_HOST}/games`);
      setGames(response.data);
    };
    getGames();
  }, [page]);

  return (
    <Wrapper>
      {games.map((game: Game) => {
        return <GameBlock key={`games${game.ID}`} game={game} />;
      })}
    </Wrapper>
  );
};

export default GameList;
