

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStartSolid, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStartRegular } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { FC } from 'react';

type Props = {
  score: number;
  className?: string;
};

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  font-weight: normal;
`;

const ScoreString = styled.span`
  font-size: 14px;
  padding-left: 4px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  padding-left: 8px;
  color: ${Colors.pink};
`;

const FiveStarRate: FC<Props> = ({ score, className }) => {
  const renderStars = () => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
      if (score - i <= 0) {
        stars.push(<StarIcon key={i} icon={faStartRegular} />);
      } else if (score - i <= 0.5) {
        stars.push(<StarIcon key={i} icon={faStarHalfAlt} />);
      } else {
        stars.push(<StarIcon key={i} icon={faStartSolid} />);
      }
    }

    return stars;
  };

  return (
    <Wrapper className={className}>
      {renderStars()}
      <ScoreString>{score}</ScoreString>
    </Wrapper>
  );
};

export default FiveStarRate;
