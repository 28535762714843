import styled from 'styled-components';
import Colors from 'Utils/Colors';

export const SimpleUl = styled.ul`
  margin: 0;
  padding: 0;
  li {
    display: inline-block;
    list-style: none;
    color: ${Colors.black};
    padding: 10px 20px 10px 0;
    font-size: 14px;
    a {
      text-decoration: none;
      color: ${Colors.pink};
      font-weight: bold;
    }
  }
`;
