import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

type Props = {
  children?: ReactNode;
};

const Wrapper = styled.div`
  width: 100%;
  background-color: ${Colors.bgPink};
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  padding: 8px 16px;
  box-sizing: border-box;
  margin: 0;
`;

const MenuTitle: FC<Props> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MenuTitle;
