import { PlayerList, PlayerProfile } from 'Schemes/Player';
import { User } from 'Schemes/User';

export const userMainProfileImage = (user: User | null): string => {
  if (user === null || user.MainProfileImageURL === null) return '/assets/noimage.jpg';
  return user.MainProfileImageURL;
};

export const playerMainProfileImage = (player: PlayerProfile | PlayerList | null): string => {
  if (player === null || player.MainProfileImageURL === null) return '/assets/noimage.jpg';
  return player.MainProfileImageURL;
};
