import { Link } from 'react-router-dom';
import 'Assets/stylesheets/top/entry.css';
import EntryHead2X from 'Assets/images/top/entry_head@2x.webp';
import EntryHeadSp from 'Assets/images/top/entry_head_sp.webp';
import EntryLine2X from 'Assets/images/top/entry_line@2x.webp';
import EntryLineSp from 'Assets/images/top/entry_line_sp.webp';

const Entry = () => {
  return (
    <div className="p-m-index-entry is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-entry__top">
            <h2 className="p-m-index-entry__head">
              <picture>
                <source media="(min-width: 751px)" width="599" height="140" srcSet={EntryHead2X} />
                <source media="(max-width: 750px)" width="484" height="114" srcSet={EntryHeadSp} />
                <img src={EntryHead2X} loading="lazy" alt="ENTRY" />
              </picture>
            </h2>
            <h3 className="p-m-index-entry__h3">登録は無料！</h3>
            <p className="p-m-index-entry__p01">
              年会費や更新料なども、
              <br className="u-m-only-sp" />
              <span className="aux01">⼀切かかりません！</span>
            </p>
            <div className="u-m-container-row p-m-index-entry__container">
              <figure className="p-m-index-entry__line">
                <picture>
                  <source media="(min-width: 751px)" width="109" height="109" srcSet={EntryLine2X} />
                  <source media="(max-width: 750px)" width="225" height="225" srcSet={EntryLineSp} />
                  <img src={EntryLine2X} loading="lazy" alt="LINE" />
                </picture>
              </figure>
              <h4 className="p-m-index-entry__h4">
                LINEでの登録なので、簡単に
                <br />
                登録＆ログインできます。
              </h4>
            </div>
            <p className="p-m-index-entry__p02">
              ※LINE IDを連携しても、
              <br className="u-m-only-sp" />
              利⽤状況等が外部に知られることはありません。
            </p>
            <Link to="/sign_up" className="p-m-index-entry__button u-m-button--gradation">
              今すぐ登録する
            </Link>
            <p className="p-m-index-entry__p03">※プレイヤーチケットを購⼊する際に費⽤がかかります。</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entry;
