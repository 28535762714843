import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Game } from 'Schemes/Game';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

type Props = {
  game: Game;
};

const Wrapper = styled(Link)`
  display: block;
  text-decoration: none;
  width: 30%;
  text-align: center;
`;

const GameIcon = styled.img`
  width: 48%;
  border-radius: 10px;
`;

const GameTitle = styled.p`
  margin: 12px 0 24px;
  font-weight: normal;
  font-size: 14px;
  color: ${Colors.black};
`;

const GameBlock: FC<Props> = ({ game }) => {
  return (
    <Wrapper to={`/players?game_id=${game.ID}`}>
      <GameIcon src={game.IconURL} alt={game.Name} />
      <GameTitle>{game.Name}</GameTitle>
    </Wrapper>
  );
};

export default GameBlock;
