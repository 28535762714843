import Axios from 'axios';
import { PrimaryButton } from 'Components/Parts/Buttons';
import PlayerStatus from 'Components/Players/PlayerStatus';
import Config from 'Config/Config';
import { FC, useState } from 'react';
import Select, { OptionsType } from 'react-select';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const StyledTitle = styled.p`
  font-size: 12px;
  margin: 0;
  padding: 0 20px;
  line-height: 40px;
  font-weight: bold;
  background-color: ${Colors.bgGray};
`;

const StyledP = styled.p`
  font-size: 12px;
  padding: 0 20px;
`;

const SelectWrapper = styled.div`
  padding: 20px;
`;

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 40%;
  height: 50px;
  line-height: 38px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: calc(100% - 20px);
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  margin-top: 20px;
  font-size: 16px;
`;

type Props = {
  player: PlayerProfile;
};

const PlayerStatusOptions = (): OptionsType<{ label: string; value: string }> => {
  return [
    { value: '0', label: '審査中' },
    { value: '100', label: 'メッセージ受付中' },
    { value: '101', label: 'プレイ可能' },
    { value: '102', label: 'プレイ中' },
    { value: '900', label: '退会' },
  ];
};

const findPlyaerStatusOptions = (status: string) => {
  return PlayerStatusOptions().find((elm) => elm.value === status);
};

const AdminPlayerStatus: FC<Props> = ({ player }) => {
  const [playerStatus, setPlayerStatus] = useState(findPlyaerStatusOptions(player.PlayerStatus.toString()));

  const updatePlayerStatus = () => {
    if (playerStatus === undefined) return;
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/players/status`, { PlayerStatus: Number(playerStatus.value), UUID: player.UUID })
      .then(() => {
        window.location.reload();
      });
  };

  return (
    <>
      <StyledTitle>現在のステータス</StyledTitle>
      <StyledP>
        <PlayerStatus status={player.PlayerStatus} />
      </StyledP>
      <StyledTitle>ステータス更新({playerStatus?.value})</StyledTitle>
      <SelectWrapper>
        <StyledSelect value={playerStatus} onChange={setPlayerStatus} options={PlayerStatusOptions()}></StyledSelect>
        <StyledPrimaryButton onClick={updatePlayerStatus}>出勤する</StyledPrimaryButton>
      </SelectWrapper>
    </>
  );
};

export default AdminPlayerStatus;
