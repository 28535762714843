import FiveStarRate from 'Components/Parts/FiveStarRate';
import { FC } from 'react';
import { Review } from 'Schemes/Review';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { formatTimeString } from 'Utils/TimeUtil';

const Wrapper = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${Colors.borderGray};
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  width: 50px;
  padding-right: 12px;
`;

const UserIcon = styled.img`
  border-radius: 25px;
  width: 50px;
  height: 50px;
`;

const Right = styled.div`
  flex-grow: 1;
`;

const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.p`
  color: ${Colors.black};
  font-size: 14px;
  font-weight: bold;
  line-height: 14px;
  height: 24px;
  width: 100px;
  white-space: nowrap;
  margin: 0;
`;

const Date = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
  width: 100px;
  margin: 0;
`;

const Content = styled.p`
  margin: 0;
  padding: 20px 0;
  background-color: ${Colors.white};
  line-height: 1.4em;
  white-space: pre-wrap;
`;

const ThanksChip = styled.span`
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  padding: 4px 8px;
  background-color: ${Colors.pink};
  border-radius: 50px;
  color: ${Colors.white};
  margin-top: 6px;
`

type Props = {
  review: Review;
  className?: string;
};

const ReviewList: FC<Props> = ({ review, className }) => {
  console.log(review)
  return (
    <Wrapper className={className}>
      <Left>
        <UserIcon src={review.IconURL || '/assets/noimage.jpg'} />
      </Left>
      <Right>
        <Info>
          <div>
            <Name>{review.UserName}</Name>
            <Date>{formatTimeString(review.ReviewedAt)}</Date>
          </div>
          <div>
            <FiveStarRate score={review.Rating} />          
            {review.ChipsCount > 0 && (
              <ThanksChip>チップありがとう！</ThanksChip>
            )}
          </div>
        </Info>
        <Content>{review.Comment}</Content>
      </Right>
    </Wrapper>
  );
};

export default ReviewList;
