import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Button = styled.span`
  height: 36px;
  padding: 0 11px;
  border-radius: 5px;
  border: 1px solid ${Colors.pink};
  color: ${Colors.pink};
  background-color: transparent;
  font-weight: bold;
  line-height: 36px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  &.loading {
    opacity: 0.1;
    cursor: wait;
  }
  &.primary {
  }
`;

type Props = {
  value?: string;
  onClick?: Function;
  className?: string;
  loading?: boolean;
  children?: ReactNode;
};

export const BaseButton: FC<Props> = ({ value, onClick, className, loading, children }) => {
  const loadingClassName = loading ? 'loading' : '';
  const handleOnclick = () => {
    if (loading) return;
    if (onClick) onClick();
  };
  return (
    <Button className={`${loadingClassName} ${className}`} onClick={handleOnclick}>
      {value}
      {children}
    </Button>
  );
};

export const PrimaryButton = styled(BaseButton)`
  background-color: ${Colors.pink};
  color: white;
`;

export const CancelButton = styled(BaseButton)`
  background-color: ${Colors.white};
  color: ${Colors.gray};
  border: 1px solid ${Colors.gray};
`;
