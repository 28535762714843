import { FC } from 'react';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

const Notification = styled.div`
  width: 100%;
  margin: 8px auto;
  line-height: 40px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  padding: 0 0.5em;
`;

const Success = styled(Notification)`
  color: ${Colors.green};
  background-color: ${Colors.notificationSuccessBg};
`;

const Alert = styled(Notification)`
  color: ${Colors.red};
  background-color: ${Colors.notificationAlertBg};
`;

type Props = {
  children: ReactNode;
  className?: string;
};

export const NotificationSuccess: FC<Props> = ({ className, children }) => {
  return (
    <Success className={className}>
      <StyledIcon icon={faCheckCircle} />
      {children}
    </Success>
  );
};

export const NotificationAlert: FC<Props> = ({ className, children }) => {
  return (
    <Alert className={className}>
      <StyledIcon icon={faTimesCircle} />
      {children}
    </Alert>
  );
};
