import Axios from 'axios';
import MenuTitle from 'Components/Parts/MenuTitle';
import PlayerPaymentBlock from 'Components/PlayerPayment/Block';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useState } from 'react';
import { PlayerPayment } from 'Schemes/Payment';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  padding: 10px 20px 10px;
  flex-grow: 1;
  background-color: ${Colors.bgPink};
`;

const NoPayments = styled.p`
  margin: 20px;
`;

const PalyerPaymentsPage: FC = () => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [payments, setPayments] = useState<PlayerPayment[]>([]);

  useEffect(() => {
    const fetchPayment = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/player/payments`)
        .then((res) => {
          setInitialLoaded(true);
          setPayments(res.data);
        });
    };

    fetchPayment();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>購入者一覧</MenuTitle>
        <Wrapper>
          {payments.map((payment) => {
            return <PlayerPaymentBlock key={payment.UUID} payment={payment} />;
          })}
          {initialLoaded && payments.length === 0 && (
            <NoPayments>
              購入されたユーザーはまだいません。
              <br />※ ユーザーとのプレイが終了すると表示されます
            </NoPayments>
          )}
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default PalyerPaymentsPage;
