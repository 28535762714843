import MenuTitle from 'Components/Parts/MenuTitle';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';

const PriceInfoWrapper = styled.section`
  background-color: ${Colors.white};
`;

const PriceCSS = css`
  width: 80%;
  margin: 0 10%;
  line-height: 60px;

  display: flex;
  justify-content: space-between;
  > p {
    margin: 0;
    display: inline-block;
  }
`;

const SubTotal = styled.div`
  border-bottom: 1px solid ${Colors.borderGray};
  height: 60px;
  color: ${Colors.gray};
  font-size: 14px;
  ${PriceCSS}
`;

const Discount = styled.div`
  border-bottom: 1px solid ${Colors.borderGray};
  height: 60px;
  color: ${Colors.gray};
  font-size: 14px;
  ${PriceCSS}
`;

const Total = styled.div`
  height: 60px;
  ${PriceCSS}
  font-weight: bold;
  font-size: 18px;
`;

type Props = {
  price: number;
  orderCount: number;
  discountPrice: number;
  isFirstTimeDiscount?: boolean;
};

const PriceInfo: FC<Props> = ({ price, discountPrice, orderCount, isFirstTimeDiscount=false }) => {
  const firstTimeDiscountPrice = isFirstTimeDiscount ? Math.round((price / orderCount) / 2) : 0;

  return (
    <>
      <MenuTitle>お支払い情報</MenuTitle>
      <PriceInfoWrapper>
        <SubTotal>
          <p>プレイチケット{30 * orderCount}分</p>
          <p>{numberToPrice(price)}</p>
        </SubTotal>
        {isFirstTimeDiscount && (
          <Discount>
            <p>初回プレイ割引</p>
            <p>-{numberToPrice(firstTimeDiscountPrice)}</p>
          </Discount>
        )}
        {discountPrice > 0 && (
          <Discount>
            <p>割引</p>
            <p>-{numberToPrice(discountPrice)}</p>
          </Discount>
        )}
        <Total>
          <p>合計</p>
          <p>{numberToPrice(price - discountPrice - firstTimeDiscountPrice)}</p>
        </Total>
      </PriceInfoWrapper>
    </>
  );
};

export default PriceInfo;
