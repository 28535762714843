import 'Assets/stylesheets/top/suggest.css';
import SuggestHead2X from 'Assets/images/top/suggest_head@2x.webp';
import SuggestHeadSp from 'Assets/images/top/suggest_head_sp.webp';
import { PlayerList } from '../../Schemes/Player';
import PlayerBlock from '../Players/Block';

const Suggest = ({ featurePlayers }: { featurePlayers: PlayerList[] }) => {
  return (
    <div className="p-m-index-suggest is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-suggest__top">
            <h2 className="p-m-index-suggest__head">
              <picture>
                <source media="(min-width: 751px)" width="867" height="145" srcSet={SuggestHead2X} />
                <source media="(max-width: 750px)" width="701" height="117" srcSet={SuggestHeadSp} />
                <img src={SuggestHead2X} loading="lazy" alt="SUGGEST" />
              </picture>
            </h2>
            <h3 className="p-m-index-suggest__h3">注目のプレイヤー</h3>
            <p className="p-m-index-suggest__p01">
              2PLAYには<span className="aux01">ライバー・VTuber・声優・タレントから一般の方まで</span>オンラインで一緒に遊んでくれるプレイヤーがいっぱい！
              <br />
              皆さんのお誘いを待ってます♪気軽にメッセージを送ってね。
            </p>
          </div>
          <div className="p-m-index-suggest__center">
            {featurePlayers.map((player: PlayerList) => (
              <div key={`layer${player.UUID}`} className="p-m-index-suggest__player">
                <PlayerBlock player={player} />
              </div>
            ))}
            <div className="p-m-index-suggest__player is-m-dummy"></div>
            <div className="p-m-index-suggest__player is-m-dummy"></div>
          </div>
          <div className="p-m-index-suggest__bottom">
            {/* プレイヤーへジャンプ */}
            <a href="#players" className="p-m-index-suggest__button u-m-button">
              もっと見る
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Suggest;
