import { PlayerList } from "Schemes/Player";

export const shufflePlayerList = (players: Array<PlayerList>): Array<PlayerList> => {
  const clonedPleyers = [...players]

  for (let i = clonedPleyers.length - 1; i >= 0; i--) {
    let rand = Math.floor(Math.random() * (i + 1));
    let tmpStorage = clonedPleyers[i];
    clonedPleyers[i] = clonedPleyers[rand];
    clonedPleyers[rand] = tmpStorage;
  }

  return clonedPleyers;
} 