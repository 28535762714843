
import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 71px - 122px);
  background-image: url(/loginbackground.png);
  background-repeat: no-repeat;
  background-color: ${Colors.bgColor};
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentInner = styled.div``;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: ${Colors.black};
  line-height: 36px;
`;

const Caption = styled.p`
  color: ${Colors.gray};
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
`;

const ErrorPage: FC = () => {
  return (
    <Wrapper>
      <ContentInner>
        <Title>エラーが発生しました</Title>
        <Caption>
          ご迷惑をおかけしております。
          <br />
          再度お時間頂いてアクセスいただくか、運営までお問い合わせください。
        </Caption>
      </ContentInner>
    </Wrapper>
  );
};

export default ErrorPage;
