import { FC, useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Colors from 'Utils/Colors';
import CheckoutFormWithNewCard from 'Components/Checkout/CheckoutFormWithNewCard';
import { PlayerProfile, PlayerGame } from 'Schemes/Player';
import MenuTitle from 'Components/Parts/MenuTitle';
import OrderCounter from 'Components/Parts/OrderCounter';
import { numberToPrice } from 'Utils/NumberHelper';
import Axios from 'axios';
import Config from 'Config/Config';
import { CurrentPayment, PaymentMethod, PaymentStatus } from 'Schemes/Payment';
import CheckoutFormWithPaymentMethod from './CheckoutFormWithPaymentMethod';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import FormLabel from 'Components/Parts/Forms/Label';
import { InputField } from 'Components/Parts/Forms/Input';
import { PrimaryButton } from 'Components/Parts/Buttons';
import { CampaignApply } from 'Schemes/CampaignCode';
import { NotificationAlert } from 'Components/Parts/Notification';
import CheckoutZero from './CheckoutZero';
import FirstPlayDiscountPrice from 'Components/Players/FirstPlayDiscountPrice';

const stripePK = process.env.REACT_APP_STRIPE_PUBKEY || 'pk_test_QgnztLux20pEwbCROZCFqL3i00xGYjRkw2';
const stripePromise = loadStripe(stripePK);

const Header = styled.header`
  height: 70px;
  font-weight: 70px;
  position: relative;
  background-color: ${Colors.white};
`;

const StyledClose = styled(FontAwesomeIcon)`
  font-size: 24px;
  padding: 23px;
  color: ${Colors.black};
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const HeaderTitle = styled.h2`
  margin: 0;
  width: 100%;
  line-height: 70px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const TicketArea = styled.div`
  display: flex;
  min-height: 130px;
  width: 100%;
  background-color: ${Colors.white};
`;

const IconArea = styled.div`
  position: relative;
  width: 130px;
`;

const PriceArea = styled.div``;

const PlayerIcon = styled.img`
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 10px;
`;

const GameIcon = styled.img`
  position: absolute;
  z-index: 10;
  top: 60px;
  left: 60px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

const PlayerName = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const GameName = styled.p`
  font-size: 12px;
`;

const StyledOrderCounter = styled(OrderCounter)`
  margin: 11px 0;
  display: inline-block;
`;

const Price = styled.span`
  color: ${Colors.pink};
  font-weight: bold;
  padding-right: 20px;
`;

const CampaignArea = styled.div`
  padding: 20px;
  background-color: ${Colors.white};
`;

const StyledCampaignCodeInput = styled(InputField)`
  border-radius: 5px 0 0 5px;
  width: 80%;
  display: inline-block;
  box-sizing: border-box;
  border-right: none;
`;

const CampaignCodeApply = styled(PrimaryButton)`
  width: 20%;
  border-radius: 0 5px 5px 0;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
  border: 1px solid ${Colors.pink};
`;

const PriceValue = styled.span<{ isStrike: boolean }>`
  font-size: 20px;
  text-decoration: ${(props) => (props.isStrike ? 'line-through' : 'none')};
`;

type Props = {
  player: PlayerProfile;
  userID: string;
  game: PlayerGame | null;
  currentPayment: CurrentPayment | null;
  afterCheckoutAction?: () => void;
  closeModal: () => void;
  isFirstPayment?: boolean;
};

const CheckoutFullPageModal: FC<Props> = ({
  closeModal,
  userID,
  player,
  game,
  currentPayment,
  afterCheckoutAction,
  isFirstPayment=false
}) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | null>(null);
  const [orderByNewCard, setOrderByNewCard] = useState(false);
  const [orderCount, setOrderCount] = useState(2);
  const [campaignCode, setCampaignCode] = useState<string>('');
  const [campaign, setCampaign] = useState<CampaignApply | null>(null);
  const [campaignApplyError, setCampaignApplyError] = useState<string | null>(null);
  const [campaignApplied, setCampaignApplied] = useState(false);
  const price = game?.Price || player.Price || 1100;
  const isFirstTimeDiscount = player.IsFirstTimeDiscount && isFirstPayment;

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      const res = await Axios.create({ withCredentials: true }).get(`${Config.API_HOST}/user/checkout/payment_methods`);
      setPaymentMethods(res.data);
    };

    fetchPaymentMethods();
  }, []);

  const decreaseOrder = () => {
    if (orderCount <= 1) return;
    setOrderCount(orderCount - 1);
  };

  const increaseOrder = () => {
    if (orderCount >= 10) return;
    setOrderCount(orderCount + 1);
  };

  const discountPrice = (): number => {
    const discount = campaign ? campaign.DiscountPrice : 0;
    return discount;
  };

  const purchasePrice = (): number => {
    return orderCount * (price || 0);
  };

  const renderCheckoutForm = () => {
    if (paymentMethods === null) return null;

    // 0円の場合はチェックアウトを表示しない
    if (purchasePrice() - discountPrice() < 1) {
      return (
        <CheckoutZero
          closeModal={closeModal}
          price={purchasePrice()}
          discountPrice={discountPrice()}
          campaignCode={campaignCode}
          playerID={player.UUID}
          orderCount={orderCount}
          afterCheckoutAction={afterCheckoutAction}
          isFirstTimeDiscount={isFirstTimeDiscount}
        />
      );
    } else if (paymentMethods.length === 0 || orderByNewCard) {
      return (
        <CheckoutFormWithNewCard
          currentPayment={currentPayment}
          closeModal={closeModal}
          userID={userID}
          price={purchasePrice()}
          discountPrice={discountPrice()}
          campaignCode={campaignCode}
          playerID={player.UUID}
          setOrderByNewCard={setOrderByNewCard}
          hasPaymentMethods={paymentMethods.length > 0}
          orderCount={orderCount}
          afterCheckoutAction={afterCheckoutAction}
          isFirstTimeDiscount={isFirstTimeDiscount}
        />
      );
    }
    return (
      <CheckoutFormWithPaymentMethod
        currentPayment={currentPayment}
        paymentMethods={paymentMethods}
        setOrderByNewCard={setOrderByNewCard}
        closeModal={closeModal}
        price={purchasePrice()}
        discountPrice={discountPrice()}
        campaignCode={campaignCode}
        playerID={player.UUID}
        orderCount={orderCount}
        afterCheckoutAction={afterCheckoutAction}
        isFirstTimeDiscount={isFirstTimeDiscount}
      />
    );
  };

  const gameNameMessage = () => {
    if (currentPayment?.Status === PaymentStatus.Captured) return 'プレイを延長する';
    return game?.Name || 'ゲームを選択せずに購入';
  };

  const applyCampaign = () => {
    setCampaignApplyError(null);
    if (campaignCode === '') {
      setCampaignApplyError('コードを入力してください。');
      return;
    }
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/campaign/apply`, { PlayerID: player.UUID, CampaignCode: campaignCode })
      .then((res) => {
        setCampaign(res.data);
        if (res.data && res.data.ErrorReason) {
          setCampaignApplyError(res.data.ErrorReason);
        } else {
          setCampaignApplied(true);
        }
      })
      .catch(() => {
        setCampaignApplyError('通信に失敗しました。');
      });
  };

  const renderCampaignCodeInput = () => {
    if (currentPayment?.Status === PaymentStatus.Captured) return <></>;
    if (campaignApplied) {
      return (
        <>
          <MenuTitle>キャンペーンコード</MenuTitle>
          <CampaignArea>
            <p>「{campaign?.CampaignName}」キャンペーンを適用しました。</p>
          </CampaignArea>
        </>
      );
    }
    return (
      <>
        <MenuTitle>キャンペーンコード</MenuTitle>
        <CampaignArea>
          <FormLabel required={false}>コード</FormLabel>
          <StyledCampaignCodeInput
            type="text"
            onChange={(e) => {
              e.persist();
              setCampaignCode(e.target.value);
            }}
            value={campaignCode}
          />
          <CampaignCodeApply onClick={applyCampaign}>適用</CampaignCodeApply>
          {campaignApplyError && <NotificationAlert>{campaignApplyError}</NotificationAlert>}
        </CampaignArea>
      </>
    );
  };

  return (
    <>
      <Header>
        <HeaderTitle>チケット購入</HeaderTitle>
        <StyledClose icon={faTimes} onClick={closeModal} />
      </Header>
      <MenuTitle>購入情報</MenuTitle>
      <TicketArea>
        <IconArea>
          <PlayerIcon src={playerMainProfileImage(player)} />
          {game && <GameIcon src={game.IconURL} />}
        </IconArea>
        <PriceArea>
          <PlayerName>{player.Name}</PlayerName>
          <GameName>{gameNameMessage()}</GameName>
          <Price>
            {isFirstTimeDiscount && <FirstPlayDiscountPrice price={price} size="md" />}
            <PriceValue isStrike={isFirstTimeDiscount}>{numberToPrice(price)}</PriceValue> / 30分
          </Price>
          <StyledOrderCounter count={orderCount} up={increaseOrder} down={decreaseOrder} />
        </PriceArea>
      </TicketArea>

      {renderCampaignCodeInput()}
      <Elements stripe={stripePromise}>{renderCheckoutForm()}</Elements>
    </>
  );
};

export default CheckoutFullPageModal;
