import GameList from 'Components/Games/List';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';

const GamesPage: FC = () => {
  const [page, setPage] = useState(1);

  return (
    <>
      <Helmet>
        <meta name="robots" content="all" />
      </Helmet>
      <BasicLayout>
        <GameList page={page} />
        <div onClick={() => setPage(1)} />
      </BasicLayout>
    </>
  );
};

export default GamesPage;
