import Axios from 'axios';
import Config from 'Config/Config';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { CancelButton, PrimaryButton } from '../Buttons';
import CommonModal from './CommonModal';

const ConfirmText = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 20px 0;
`;

const MessageArea = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  width: 100%;
`;

const ButtonCSS = css`
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  ${ButtonCSS}
  width: 70%;
  margin-left: 5%;
`;

const StyleCancelButton = styled(CancelButton)`
  ${ButtonCSS}
  width: 25%;
`;

type Props = {
  isOpen: boolean;
  playerID: string;
  closeModal: () => void;
  afterStartPlayAction?: () => void;
};

const startPlay = (playerID: string, closeModal: () => void, afterStartPlayAction?: () => void) => {
  Axios.create({ withCredentials: true })
    .post(`${Config.API_HOST}/user/play/start`, { PlayerID: playerID })
    .then(() => {
      closeModal();
      if (afterStartPlayAction) afterStartPlayAction();
    });
};

const StartPlayModal: FC<Props> = ({ isOpen, playerID, closeModal, afterStartPlayAction }) => {
  return (
    <CommonModal title="プレイ開始" isOpen={isOpen} closeModal={closeModal}>
      <MessageArea>
        <ConfirmText>
          ゲームを開始します。
          <br />
          準備ができたら、「はい」を押してください。
        </ConfirmText>
      </MessageArea>
      <ButtonArea>
        <StyleCancelButton value="いいえ" onClick={closeModal} />
        <StyledPrimaryButton value="はい" onClick={() => startPlay(playerID, closeModal, afterStartPlayAction)} />
      </ButtonArea>
    </CommonModal>
  );
};
export default StartPlayModal;
