import { FC } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { formatTimeString } from 'Utils/TimeUtil';

type Props = {
  message: string;
  sentAt: string;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0 20px;
  justify-content: space-between;
`;

const Content = styled.div`
  margin: 0 10px;
  width: calc(100% - 36px - 20px - 18px);
`;

const Message = styled.div`
  background-color: ${Colors.blue};
  font-size: 14px;
  color: ${Colors.white};
  border-radius: 5px;
  white-space: pre-wrap;
  line-height: 24px;
  padding: 8px;
  a {
    color: ${Colors.white};
    text-decoration: underline;
    font-weight: bold;
  }
`;

const Date = styled.p`
  color: ${Colors.gray};
  font-size: 10px;
  margin: 4px 0 0;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin: 0 8px 0 10px;
`;

const SystemMessage: FC<Props> = ({ message, sentAt }) => {
  return (
    <Wrapper>
      <ProfileImage profileImage="/assets/2play_icon.jpg" />
      <Content>
        <Message>{parse(message)}</Message>
        <Date>{formatTimeString(sentAt)}</Date>
      </Content>
    </Wrapper>
  );
};

export default SystemMessage;
