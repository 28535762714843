import { ReactNode, FC } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const customStyles = {
  overlay: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(1, 1, 1, 0.5)',
  },
  content: {
    padding: '0',
    width: '80%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-40%',
    transform: 'translate(-50%, -50%)',
    border: `1px solid ${Colors.borderGray}`,
    borderRadius: '10px',
  },
};

const ModalInner = styled.div`
  position: relative;
`;

const Header = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  color: ${Colors.black};
  font-weight: bold;
  background-color: ${Colors.bgPink};
  text-align: left;
  padding: 0 20px;
`;

const ContentWrapper = styled.div`
  padding: 60px 20px 20px;
  max-height: 80vh;
  box-sizing: border-box;
  overflow-y: auto;
`;

type Props = {
  children?: ReactNode;
  title: string;
  isOpen: boolean;
  closeModal: () => void;
};

const CommonModal: FC<Props> = ({ children, title, isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} style={customStyles} onRequestClose={closeModal} ariaHideApp={false}>
      <ModalInner>
        <Header>{title}</Header>
        <ContentWrapper>{children}</ContentWrapper>
      </ModalInner>
    </Modal>
  );
};
export default CommonModal;
