import styled from "styled-components"

export const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin: 0 8px 0 10px;
`;
