import styled from 'styled-components';
import Colors from 'Utils/Colors';

export const TextArea = styled.textarea`
  display: block;
  box-sizing: border-box;
  font-size: 14px;
  display: block;
  margin-top: 8px;
  padding: 10px 15px;
  line-height: 30px;
  width: 100%;
  height: 100%;
  border: 1px solid ${Colors.borderGray};
  border-radius: 5px;
  background-color: ${Colors.white};
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  -webkit-appearance: none;
  &:focus {
    outline: 0;
  }
`;
