import Axios from 'axios';
import InboxList from 'Components/Parts/Inbox/List';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useState, useContext } from 'react';
import { Inbox } from 'Schemes/Inbox';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { UserContext } from 'App';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.black};
`;

const NoMessage = styled.p`
  margin: 20px;
`;

type Props = {} & RouteComponentProps;

const InboxPage: FC<Props> = ({ history }) => {
  const userContext = useContext(UserContext);
  const [inboxes, setInboxs] = useState<Inbox[]>([]);
  const fetchInboxs = async () => {
    const response = await Axios.create({ withCredentials: true }).get(`${Config.API_HOST}/user/inboxes`);

    setInboxs(response.data);
  };

  useEffect(() => {
    if (userContext.user?.PlayerProfile) history.push('/player/inboxes');
    fetchInboxs();
  }, [history, userContext.user?.PlayerProfile]);

  return (
    <BasicLayout>
      {inboxes.length === 0 && <NoMessage>まだメッセージはありません。</NoMessage>}
      {inboxes.map((inbox) => {
        return (
          <StyledLink to={`/chat/${inbox.PlayerUUID}`} key={`/chat/${inbox.PlayerUUID}`}>
            <InboxList inbox={inbox} readFlg={inbox.ReadFlg} />
          </StyledLink>
        );
      })}
    </BasicLayout>
  );
};

export default withRouter(InboxPage);
