import { FC, FormEvent, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import MenuTitle from 'Components/Parts/MenuTitle';
import Config from 'Config/Config';
import Axios from 'axios';
import PriceInfo from './PriceInfo';
import { NotificationAlert } from 'Components/Parts/Notification';
import { CurrentPayment, PaymentMethod } from 'Schemes/Payment';
import { MenuList, MenuUL } from 'Components/Parts/MenuList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import {
  faCcDinersClub,
  faCcAmex,
  faCcJcb,
  faCcMastercard,
  faCcVisa,
  faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';

const PayWithPaymentMethodWrapper = styled.div`
  padding: 0 0 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PayWithNewCard = styled.span`
  text-align: right;
  text-decoration: underline;
  color: ${Colors.pink};
  display: block;
  float: right;
  cursor: pointer;
`;

const StyledChecked = styled(FontAwesomeIcon)`
  padding-right: 10px;
`;

const NotificationWrapper = styled.div`
  padding: 20px;
  background-color: ${Colors.white};
`;

const StyledButton = styled.button`
  width: 60%;
  height: 60px;
  padding: 0 11px;
  border-radius: 5px;
  border: 1px solid ${Colors.pink};
  background-color: ${Colors.pink};
  color: white;
  font-weight: bold;
  line-height: 60px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

const CardLastFour = styled.span`
  padding-right: 10px;
`;

const CardExpiry = styled.span`
  color: ${Colors.gray};
`;

type Props = {
  paymentMethods: PaymentMethod[];
  setOrderByNewCard: (value: boolean) => void;
  playerID: string;
  orderCount: number;
  price: number;
  discountPrice: number;
  campaignCode: string;
  currentPayment: CurrentPayment | null;
  closeModal: () => void;
  afterCheckoutAction?: () => void;
  isFirstTimeDiscount?: boolean;
};

const CheckoutFormWithPaymentMethod: FC<Props> = (props) => {
  const {
    paymentMethods,
    setOrderByNewCard,
    closeModal,
    playerID,
    price,
    discountPrice,
    campaignCode,
    orderCount,
    currentPayment,
    afterCheckoutAction,
    isFirstTimeDiscount
  } = props;
  const [paymentMethodID, setPaymentMethodID] = useState(paymentMethods[0].ID);
  const [cardAlertMessage, setCardAlertMessage] = useState<string | null>(null);
  const [checkouting, setCheckouting] = useState<boolean>(false);
  const stripe = useStripe();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setCheckouting(true);
    setCardAlertMessage(null);

    const url =
      currentPayment?.Status === 1
        ? `${Config.API_HOST}/user/checkout/extend`
        : `${Config.API_HOST}/user/checkout/intent`;

    let res;
    try {
      res = await Axios.create({ withCredentials: true }).post(url, {
        PaymentUUID: currentPayment?.UUID,
        Amount: price,
        PlayerID: playerID,
        Quontity: orderCount,
        CampaignCode: campaignCode,
      });
    } catch (error) {
      setCardAlertMessage('決済に失敗しました。');
      setCheckouting(false);
    }

    if (!res) return;
    const secret = res.data.ClientSecret;
    const result = await stripe?.confirmCardPayment(secret, {
      payment_method: paymentMethodID,
    });

    if (result?.error) {
      Axios.create({ withCredentials: true }).post(`${Config.API_HOST}/user/checkout/intent/cancel_failed`, {
        playerID: playerID,
      });
      setCardAlertMessage(result?.error.message || null);
    } else {
      if (result?.paymentIntent?.status === 'requires_capture' || result?.paymentIntent?.status === 'succeeded') {
        closeModal();
      }
      console.log(result);
      if (afterCheckoutAction) afterCheckoutAction();
    }
    setCheckouting(false);
  };

  const cardCheckBox = (pmID: string) => {
    if (pmID === paymentMethodID) {
      return <StyledChecked icon={faCheckCircle} style={{ color: '#5AB64E' }} />;
    }
    return <StyledChecked icon={faCheckCircle} style={{ color: Colors.borderGray }} />;
  };

  const cardBrand = (brand: string) => {
    if (brand === 'amex') {
      return faCcAmex;
    } else if (brand === 'diners') {
      return faCcDinersClub;
    } else if (brand === 'discover') {
      return faCcDiscover;
    } else if (brand === 'jcb') {
      return faCcJcb;
    } else if (brand === 'mastercard') {
      return faCcMastercard;
    } else if (brand === 'visa') {
      return faCcVisa;
    } else {
      return faCreditCard;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <MenuTitle>
          クレジットカード情報入力
        </MenuTitle>

        <MenuUL>
          {cardAlertMessage && (
            <NotificationWrapper>
              <NotificationAlert>{cardAlertMessage}</NotificationAlert>
            </NotificationWrapper>
          )}
          {paymentMethods.map((pm) => {
            return (
              <MenuList key={pm.ID} onClick={() => setPaymentMethodID(pm.ID)}>
                {cardCheckBox(pm.ID)}
                <FontAwesomeIcon
                  icon={cardBrand(pm.Brand)}
                  style={{ color: Colors.pink, fontSize: '30px', padding: '10px 10px 0 0' }}
                />
                <CardLastFour>*********{pm.Last4}</CardLastFour>
                <CardExpiry>
                  {pm.ExpMonth} / {pm.ExpYear}
                </CardExpiry>
              </MenuList>
            );
          })}
        </MenuUL>
        <PriceInfo price={price} discountPrice={discountPrice} orderCount={orderCount} isFirstTimeDiscount={isFirstTimeDiscount} />
        <StyledButton type="submit" disabled={!stripe || checkouting}>
          チケットを購入する
        </StyledButton>
        <PayWithPaymentMethodWrapper>
          <PayWithNewCard onClick={() => setOrderByNewCard(true)}>新しいカードで購入する</PayWithNewCard>
        </PayWithPaymentMethodWrapper>
      </form>
    </>
  );
};

export default CheckoutFormWithPaymentMethod;
