

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useIsFirstPayment } from 'Hooks/useUserInboxes';

import Colors from 'Utils/Colors';
import FiveStarRate from 'Components/Parts/FiveStarRate';
import { numberToPrice } from 'Utils/NumberHelper';
import { PlayerList } from 'Schemes/Player';
import { Link } from 'react-router-dom';
import AudioButton from 'Components/Parts/Audio';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import PlayerStatus from './PlayerStatus';
import { FC } from 'react';
import FirstPlayDiscountPrice from './FirstPlayDiscountPrice';
import { UserContextType } from 'Schemes/User';

const Wrapper = styled.div`
  cursor: pointer;
  border-radius: 5px;
  width: 44%;
  height: auto;
  background-color: white;
  margin: 3%;
  display: inline-block;
  text-align: left;
  position: relative;
`;

const StyledAudio = styled(AudioButton)`
  position: absolute;
  bottom: 8px;
  left: 8px;
  z-index: 10;
  background-color: rgba(253, 160, 94, 0.8);
  border: none;
  color: ${Colors.white};
`;

const ProfileImageWrapper = styled.div`
  width: 100%;
  border-radius: 5px 5px 0 0;
  position: relative;
`;

const ProfileImage = styled.img`
  width: 100%;
  border-radius: 5px 5px 0 0;
`;

const ProfileName = styled.p`
  font-weight: bold;
  font-size: 14px;
  width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 8px 0 4px;
  padding-left: 8px;
  box-sizing: border-box;
`;

const OnlineIcon = styled(FontAwesomeIcon)`
  color: ${Colors.onlineGreen};
  font-size: 10px;
  padding-right: 4px;
`;

const Price = styled.p`
  color: ${Colors.pink};
  font-weight: bold;
  font-size: 20px;
  margin: 8px;
`;

const PriceUnit = styled.span`
  font-size: 14px;
`;

const PriceValue = styled.span<{ isStrike: boolean }>`
  font-size: ${(props) => (props.isStrike ? '14px' : '20px')};
  text-decoration: ${(props) => (props.isStrike ? 'line-through' : 'none')};
`;

type Props = {
  player: PlayerList;
  className?: string;
  currentUser?: UserContextType['user'] | null;
};

const PlayerBlock: FC<Props> = ({ player, className, currentUser }) => {
  const { isFirstPayment } = useIsFirstPayment(player.UUID);
  const loggedIn = !!currentUser;
  const isDiscountable = player.IsFirstTimeDiscount && (!loggedIn || (loggedIn && isFirstPayment)) 

  return (
    <Wrapper className={className}>
      <Link
        to={{
          pathname: `/players/${player.UUID}`,
          state: { playerListInfo: player },
        }}
      >
        <ProfileImageWrapper>
          <ProfileImage src={playerMainProfileImage(player)} alt={player.Name} />
          <StyledAudio audioURL={player.AudioFileURL} audioDuration={player.AudioFileDuration} />
        </ProfileImageWrapper>
        <ProfileName>
          {false && <OnlineIcon icon={faCircle} />}
          <PlayerStatus status={player.PlayerStatus} withStatusLabel={false} />
          &nbsp;{player.Name}
        </ProfileName>
        <FiveStarRate score={player.Rating} />
        <Price>
          {isDiscountable && (
            <FirstPlayDiscountPrice price={player.Price} size="xs" />
          )}
          <PriceValue isStrike={isDiscountable}>{numberToPrice(player.Price)}</PriceValue>
          <PriceUnit>/ 30分</PriceUnit>
        </Price>
      </Link>
    </Wrapper>
  );
};

export default PlayerBlock;
