import { FC } from 'react';
import { PlayerGame } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import CommonModal from './CommonModal';

const GameIconWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: space-around;
  text-align: center;
`;

const GameIcon = styled.img`
  border-radius: 10px;
  width: 80%;
  height: auto;
`;

const GameTitleWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 30%;
  text-align: center;
  padding: 0 1.5%;
`;

const GameTitle = styled.p`
  margin: 12px 0 24px;
  font-weight: normal;
  font-size: 14px;
  color: ${Colors.black};
`;

type Props = {
  isOpen: boolean;
  games: PlayerGame[];
  changeCurrentGame: (game: PlayerGame) => void;
  closeModal: () => void;
};

const SelectGameModal: FC<Props> = ({ isOpen, closeModal, games, changeCurrentGame }) => {
  const selectGame = (game: PlayerGame) => {
    changeCurrentGame(game);
    closeModal();
  };

  return (
    <CommonModal title="ゲームを選択する" isOpen={isOpen} closeModal={closeModal}>
      <GameIconWrapper>
        {games.map((game) => {
          return (
            <GameTitleWrapper key={game.Name} onClick={() => selectGame(game)}>
              <GameIcon src={game.IconURL} />
              <GameTitle>{game.Name}</GameTitle>
            </GameTitleWrapper>
          );
        })}
      </GameIconWrapper>
    </CommonModal>
  );
};
export default SelectGameModal;
