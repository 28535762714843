import MenuTitle from 'Components/Parts/MenuTitle';
import Config from 'Config/Config';
import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import BasicLayout from './Layouts/Basic';

const Wrapper = styled.section`
  padding: 20px;
  background-color: ${Colors.white};
`;

const StepLabel = styled.h2`
  color: ${Colors.blue};
  margin: 20px 0 0;
  font-size: 14px;
`;

const StyledCaption = styled.p`
  margin: 0;
  color: ${Colors.black};
`;

const StyledP = styled.p`
  margin: 0;
  color: ${Colors.gray};
`;

const LineButton = styled.a`
  display: block;
  width: 80%;
  background-color: ${Colors.lineGreen};
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  padding: 0 8px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin: 40px auto;
  text-align: center;
  font-weight: bold;
`;

const LineNotifyPage: FC = () => {
  return (
    <BasicLayout>
      <MenuTitle>LINEで通知を受け取る</MenuTitle>
      <Wrapper>
        <StyledCaption>
          「LINEで通知を受け取る」設定をすることで、プレイヤーとの新しいメッセージ、購入ステータスの更新、プレー終了時間などをリアルタイムで通知が届くようになります。ぜひご活用ください。
        </StyledCaption>
        <StepLabel>ステップ1</StepLabel>
        <StyledP>下部にある「LINEで通知を受け取る」ボタンをクリックしてください。</StyledP>
        <StepLabel>ステップ2</StepLabel>
        <StyledP>開かれたページ先で、LINEログインをしてください。</StyledP>
        <StepLabel>ステップ3</StepLabel>
        <StyledP>
          「1:1でLINE
          Notifyから通知を受け取る」をクリックしたのち、ページ下部にある「同意して連携する」をクリックしたら設定は完了です。
        </StyledP>
        <LineButton href={`${Config.API_HOST}/oauth/line_notify`}>LINEで通知を受け取る</LineButton>
      </Wrapper>
    </BasicLayout>
  );
};

export default LineNotifyPage;
