import { PrimaryButton } from 'Components/Parts/Buttons';
import MessageInputArea from 'Components/Parts/MessageInput/MessageInputArea';
import SystemMessage from 'Components/Parts/MessageObjects/SystemMessage';
import { FC, useEffect, useState, ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import MyMessage from 'Components/Parts/MessageObjects/MyMessage';
import YourMessage from 'Components/Parts/MessageObjects/YourMessage';
import Axios from 'axios';
import Config from 'Config/Config';
import { InboxObject } from 'Schemes/InboxObject';
import { Link, match } from 'react-router-dom';
import { userMainProfileImage } from 'Utils/ProfileImage';
import { User } from 'Schemes/User';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.header`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;

  height: 70px;
  font-weight: 70px;
  position: fixed;
  background-color: ${Colors.white};
  border-bottom: 1px solid ${Colors.borderGray};
`;

const StyledBack = styled(FontAwesomeIcon)`
  font-size: 24px;
  padding: 23px;
  width: 24px;
  color: ${Colors.black};
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
`;

const HeaderTitle = styled.h2`
  margin: 0;
  width: 100%;
  line-height: 70px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
`;

const MessageArea = styled.div`
  padding-top: 70px;
  padding-bottom: 60px;
  height: auto;
  background-color: ${Colors.gbPink};
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MessageInputAreaWrapper = styled.div`
  width: 100%;
  left: 0;
  height: auto;
  position: relative;
`;

const MessageInputAreaContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: ${Colors.white};
  display: flex;
  justify-content: space-between;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: 15%;
  text-align: center;
`;

const StyledMessageInputAreaWrapper = styled(MessageInputAreaWrapper)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
`;

type Props = {
  match: match<{ uuid: string }>;
} & RouteComponentProps;

const InboxDetailPage: FC<Props> = ({ match, history }) => {
  const [lastMessageFetched, setLastMessageFetched] = useState(0);
  const userID = match.params.uuid;
  const [user, setUser] = useState<User | null>(null);
  const [inputMessage, setInputMessage] = useState('');
  const [sendingInboxObjects, setSendingInboxObjects] = useState([]);
  const [inboxObjects, setInboxObjects] = useState<InboxObject[]>([]);
  const userImage = userMainProfileImage(user);

  useEffect(() => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/user`)
      .then((response) => {
        if (response.data === null) {
          history.push('/login');
        }
      });
    if (user === null) fetchUser(userID);
    fetchMessage(userID);
  }, [userID, lastMessageFetched, user, history]);

  const fetchUser = (userID: string) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/users/${userID}`)
      .then((response) => {
        setUser(response.data);
      });
  };

  const fetchMessage = (userID: string) => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/player/inboxes/${userID}`)
      .then((response) => {
        setInboxObjects((prev) => {
          const newObjects: InboxObject[] = Object.assign([], prev, response.data);
          return newObjects;
        });
        setSendingInboxObjects([]);
        setTimeout(() => {
          const date = new Date();
          setLastMessageFetched(date.getTime());
        }, 5000);
      });
  };

  // aタグをreact-routerで置き換える
  const messageNormalizer = (message: string): string => {
    const href = message.match(/href='([^"]+)'/);

    // console.log(href)

    if (href && href[1].includes("localhost")) {
      return message.replace(/<a href='(.+?)'>(.*?)<\/a>/, `<Link to="$1">$2</Link>`);
    }

    return message;
  }

  const content = (): Array<ReactNode> => {
    let children = [];

    for (let i in inboxObjects) {
      const inboxObject = inboxObjects[i];
      messageNormalizer(inboxObject.ToPlayer)
      // const yM = messageNormalizer(inboxObject.ToUser)
      // console.log(pM)
      // console.log(yM)

      if (inboxObject.ObjectType === 'player_message') {
        children.push(<MyMessage key={i} message={inboxObject.ToPlayer} sentAt={inboxObject.SentAt} />);
      } else if (inboxObject.ObjectType === 'user_message') {
        children.push(
          <YourMessage key={i} message={inboxObject.ToPlayer} profileImage={userImage} sentAt={inboxObject.SentAt} />,
        );
      } else {
        children.push(<SystemMessage key={i} message={inboxObject.ToPlayer} sentAt={inboxObject.SentAt} />);
      }
    }

    for (let i in sendingInboxObjects) {
      const message = sendingInboxObjects[i];
      children.push(<MyMessage key={i} message={message} sentAt="送信中" />);
    }

    return children;
  };

  const sendMessage = () => {
    if (inputMessage === '') return;
    setSendingInboxObjects((prev) => Object.assign([], prev, [inputMessage]));
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/player/message`, { UserID: userID, Message: inputMessage })
      .then((_) => {
        setInputMessage('');
      });
  };

  if (user === null) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Wrapper>
        <Header>
          <HeaderTitle>{user.Name}</HeaderTitle>
          <Link to="/player/inboxes">
            <StyledBack icon={faAngleLeft} />
          </Link>
        </Header>
        <MessageArea>{content()}</MessageArea>
        <StyledMessageInputAreaWrapper>
          <MessageInputAreaContent>
            <MessageInputArea onChange={setInputMessage} inputMessage={inputMessage} />
            <StyledPrimaryButton value="送信" onClick={sendMessage} />
          </MessageInputAreaContent>
        </StyledMessageInputAreaWrapper>
      </Wrapper>
    </>
  );
};

export default withRouter(InboxDetailPage);
