import PageNotFound from 'Components/PageNotFound';
import { FC } from 'react';

import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 71px - 122px);
  background-image: url(/loginbackground.png);
  background-repeat: no-repeat;
  background-color: ${Colors.bgColor};
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotFoundPage: FC = () => {
  return (
    <Wrapper>
      <PageNotFound />
    </Wrapper>
  );
};

export default NotFoundPage;
