import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

import 'Assets/stylesheets/top/review.css';
import 'Assets/stylesheets/top/swiper.css';

import ReviewHead2X from 'Assets/images/top/review_head@2x.webp';
import ReviewHeadSp from 'Assets/images/top/review_head_sp.webp';

import NoImage from 'Assets/images/top/noimage.jpg';
import ReviewReviwer16 from 'Assets/images/top/review_reviewer_1_6.jpg';
import ReviewReviwer27 from 'Assets/images/top/review_reviewer_2_7.jpg';
import ReviewReviwer3 from 'Assets/images/top/review_reviewer_3.jpg';
import ReviewReviwer4 from 'Assets/images/top/review_reviewer_4.jpg';
import ReviewReviwer9 from 'Assets/images/top/review_reviewer_9.jpg';

import ReviewPlayer167 from 'Assets/images/top/review_player_1_6_7.jpg';
import ReviewPlayer28 from 'Assets/images/top/review_player_2_8.jpg';
import ReviewPlayer39 from 'Assets/images/top/review_player_3_9.jpg';
import ReviewPlayer4 from 'Assets/images/top/review_player_4.jpg';
import ReviewPlayer10 from 'Assets/images/top/review_player_10.jpg';
import ReviewPlayer11 from 'Assets/images/top/review_player_11.jpg';

import Star50 from 'Assets/images/common/star_50.webp';

type ReviewType = {
  id: number;
  username: string;
  reviewerImage: string;
  comment: string;
  rate: number;
  rateImage: string;
  time: number;
  playerName: string;
  playerImage: string;
  playerID: string;
};
const Review = () => {
  const reviewList: ReviewType[] = [
    {
      id: 1,
      username: 'かたつむり',
      reviewerImage: ReviewReviwer16,
      comment:
        'APEXしたりー雑談したりーとても楽しい時間でした！！声が可愛くてすごい気楽にお話出来て対応が天使で最高でした！！これはリピ確定です！！！またよろしくお願いします！！！ ',
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: 'しゅがー',
      playerImage: ReviewPlayer167,
      playerID: '916bf62c-e1b4-40fc-b308-ff8d43d3b907',
    },
    {
      id: 2,
      username: 'みやさんです',
      reviewerImage: ReviewReviwer27,
      comment: `お話ししていてめっちゃ楽しかったです!!久しぶりに人とゲームができて、感動です😿サクラの評価みたいになって心配ですが、どんな人でも楽しくゲームされそうだなぁって思うのでありがとうございました`,
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: 'ぐら',
      playerImage: ReviewPlayer28,
      playerID: '8f7f00ef-a75d-4ec1-87d4-c83129923d4a',
    },
    {
      id: 3,
      username: 'Kou',
      reviewerImage: ReviewReviwer3,
      comment: `めちゃくちゃ優しい方でした、とてもやりやすく、1時間があっという間でした！\nチャンピオンも取れたしハプニングもあったし！笑\nまたお願いしたいですなぁ！`,
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: 'るるか',
      playerImage: ReviewPlayer39,
      playerID: 'f0515f59-28b1-439f-ba39-b91002835c72',
    },
    {
      id: 4,
      username: 'UberEats',
      reviewerImage: ReviewReviwer4,
      comment: `優しい！可愛い！話やすい！ノリもいい！めちゃくちゃ楽しかったです！！！\nまたよろしくお願いします！！`,
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: 'ゆり',
      playerImage: ReviewPlayer4,
      playerID: '51770d90-7d30-4e43-b293-83e61282de04',
    },
    {
      id: 6,
      username: 'かたつむり',
      reviewerImage: ReviewReviwer16,
      comment: `今日も可愛かった…\nヴァロを初めてやって丁寧に教えて貰いながら楽しく遊べました！\n大変満足です！！！`,
      rate: 5.0,
      rateImage: Star50,
      time: 120,
      playerName: 'しゅがー',
      playerImage: ReviewPlayer167,
      playerID: '916bf62c-e1b4-40fc-b308-ff8d43d3b907',
    },
    {
      id: 7,
      username: 'みやさんです',
      reviewerImage: ReviewReviwer27,
      comment: `プレイも上手いし。お話も面白いし。\n声もめっちゃ可愛いですし。\nとても癒されたし楽しかったです〜!!\nリピされる方の気持ちがわかった気がします〜。またお願いします。`,
      rate: 5.0,
      rateImage: Star50,
      time: 90,
      playerName: 'しゅがー',
      playerImage: ReviewPlayer167,
      playerID: '916bf62c-e1b4-40fc-b308-ff8d43d3b907',
    },
    {
      id: 8,
      username: 'らと',
      reviewerImage: NoImage,
      comment: `最初から最後まで、とても話しやすく1時間があっという間でした！\nAPEXもお上手で、楽しくご一緒させていただきました！またよろしくお願いします。`,
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: 'ぐら',
      playerImage: ReviewPlayer28,
      playerID: '8f7f00ef-a75d-4ec1-87d4-c83129923d4a',
    },
    {
      id: 9,
      username: 'みこと',
      reviewerImage: ReviewReviwer9,
      comment: `とにかく一緒にやっていて時間感覚が狂うくらい楽しいです！自分に合わせた戦い方もしてくれるので凄くやりやすいし何より声も好きでした…\nまたお誘いさせていただきます！\nありがとうございました！`,
      rate: 5.0,
      rateImage: Star50,
      time: 30,
      playerName: 'るるか',
      playerImage: ReviewPlayer39,
      playerID: 'f0515f59-28b1-439f-ba39-b91002835c72',
    },
    {
      id: 10,
      username: 'M',
      reviewerImage: NoImage,
      comment: `一緒にゲーム＆おしゃべりありがとうございました！めちゃくちゃ楽しかったです！\nまた宜しくお願いします！`,
      rate: 5.0,
      rateImage: Star50,
      time: 60,
      playerName: '春緑ゆのん',
      playerImage: ReviewPlayer10,
      playerID: 'd7183278-74c3-42ec-bfa9-94116cf22dd6',
    },
    {
      id: 11,
      username: 'korio',
      reviewerImage: NoImage,
      comment: `初めて遊ばせて貰いましたが\n私のワールドレベルに合わせてくれて\nプレイしてる間のお話も楽しくてとても楽しい時間を過ごせました`,
      rate: 5.0,
      rateImage: Star50,
      time: 30,
      playerName: '八重桜',
      playerImage: ReviewPlayer11,
      playerID: '5dd59f9f-df88-4f54-b194-e2ff65ab6856',
    },
  ];
  return (
    <div className="p-m-index-review is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-review__top">
            <h2 className="p-m-index-review__head">
              <picture>
                <source media="(min-width: 751px)" width="705" height="140" srcSet={ReviewHead2X} />
                <source media="(max-width: 750px)" width="571" height="113" srcSet={ReviewHeadSp} />
                <img src={ReviewHead2X} loading="lazy" alt="REVIEW" />
              </picture>
            </h2>
            <h3 className="p-m-index-review__h3">ご利⽤者様レビュー</h3>
            <p className="p-m-index-review__p01">
              ご利⽤いただいた皆様から、<span className="aux01">嬉しいレビュー</span>をいただいております！
            </p>
          </div>
          <div className="p-m-index-review__bottom">
            <Swiper>
              {reviewList.map((review: ReviewType) => (
                <SwiperSlide key={review.id}>
                  <Link to={`/players/${review.playerID}`} className="c-m-card--review p-m-index-review__card">
                    <figure className="c-m-card--review-block p-m-index-review__reviewer">
                      <img src={review.reviewerImage} width="96" height="96" alt="レビュワー" />
                    </figure>
                    <div className="c-m-card--review-block u-m-container-row p-m-index-review__container">
                      <span className="p-m-index-review__name01">{review.username}</span>
                      <span className="p-m-index-review__time">プレイ時間:{review.time}分</span>
                      <div>
                        <figure className="p-m-index-review__star">
                          <img src={review.rateImage} width="240" height="48" alt="星" />
                          <figcaption className="p-m-index-review__point">{review.rate}</figcaption>
                        </figure>
                        <p className="p-m-index-review__comment">{review.comment}</p>
                      </div>
                    </div>
                    <span className="c-m-card--review-block p-m-index-review__span">PLAYER</span>
                    <figure className="c-m-card--review-block p-m-index-review__player">
                      <img src={review.playerImage} width="101" height="101" alt="プレイヤー" />
                      <figcaption className="p-m-index-review__name02">{review.playerName}</figcaption>
                    </figure>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Review;
