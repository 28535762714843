import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { formatTimeString } from 'Utils/TimeUtil';

type Props = {
  message: string;
  sentAt: string;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0 20px;
  justify-content: space-between;
`;

const Content = styled.div`
  flex-grow: 1;
  margin: 0 10px;
  width: calc(100% - 36px - 20px - 18px);
`;

const Message = styled.div`
  background-color: ${Colors.pink};
  font-size: 14px;
  color: ${Colors.white};
  border-radius: 5px;
  white-space: pre-wrap;
  line-height: 24px;
  padding: 8px;
`;

const Date = styled.p`
  color: ${Colors.gray};
  font-size: 10px;
  margin: 4px 0 0;
  text-align: right;
`;

const ProfileImage = styled.div`
  width: 36px;
  height: 36px;
  margin: 0 8px 0 10px;
`;

const MyMessage: FC<Props> = ({ message, sentAt }) => {
  return (
    <Wrapper>
      <ProfileImage />
      <Content>
        <Message>{message}</Message>
        <Date>{sentAt === '送信中' ? sentAt : formatTimeString(sentAt)}</Date>
      </Content>
    </Wrapper>
  );
};

export default MyMessage;
