import SectionLabel from 'Components/Parts/SectionLabel';
import { FC, useState } from 'react';
import styled from 'styled-components';
import Select, { OptionsType } from 'react-select';
import { PrimaryButton } from 'Components/Parts/Buttons';

const Wrapper = styled.section`
  margin: 40px 0 20px;
`;

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 25%;
  height: 50px;
  line-height: 38px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: calc(100% - 20px);
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  margin-top: 20px;
  font-size: 16px;
`;

const dayOptions = (): OptionsType<{ label: string; value: string }> => {
  return [
    { value: '0', label: '今日' },
    { value: '1', label: '明日' },
  ];
};

const timeOptions = (count: number): OptionsType<{ label: string; value: string }> => {
  let res = [];
  for (let i = 0; i < count; i++) {
    res.push({ value: String(i), label: String(i) });
  }
  return res;
};

const hourOptions = () => {
  return timeOptions(24);
};

const minutesOptions = () => {
  return timeOptions(60);
};

const calcDefaultValue = () => {
  const now = new Date();
  // 出勤デフォは4時間
  const defaultHour = 4;
  const defaultValue = new Date(now.getTime() + defaultHour * 60 * 60 * 1000);

  // 4時より若い場合は翌日になる
  const dayIndex = defaultValue.getHours() < defaultHour ? 1 : 0;

  return {
    day: dayIndex,
    hour: defaultValue.getHours(),
    minutes: defaultValue.getMinutes(),
  };
};

type Props = {
  startWork: (endAt: number) => void;
};

const PlayerWorkInput: FC<Props> = ({ startWork }) => {
  const [day, setDay] = useState(dayOptions()[calcDefaultValue().day]);
  const [hour, setHour] = useState(hourOptions()[calcDefaultValue().hour]);
  const [minutes, setMinutes] = useState(minutesOptions()[calcDefaultValue().minutes]);

  const stampStartWork = () => {
    let now = new Date();
    now.setHours(Number(hour.value));
    now.setMinutes(Number(minutes.value));
    let workEndAt = Math.floor(now.getTime() / 1000);
    if (day.value === '1') workEndAt += 24 * 60 * 60;

    startWork(workEndAt);
  };

  return (
    <Wrapper>
      <SectionLabel>出退勤</SectionLabel>
      <StyledSelect value={day} onChange={setDay} options={dayOptions()}></StyledSelect>　
      <StyledSelect value={hour} onChange={setHour} options={hourOptions()}></StyledSelect>：
      <StyledSelect value={minutes} onChange={setMinutes} options={minutesOptions()}></StyledSelect>
      　まで
      <StyledPrimaryButton onClick={stampStartWork}>出勤する</StyledPrimaryButton>
    </Wrapper>
  );
};

export default PlayerWorkInput;
