import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';

type SizeType = "sm" | "md" | "lg" | "xs";
const SizeList = {
  xs: "22px",
  sm: "24px",
  md: "32px",
  lg: "40px",
}

const Price = styled.span<{ color: string, size: SizeType }>`
  color: ${props => Colors[props.color]};
  font-weight: bold;
  font-size: ${props => SizeList[props.size]}!important;
  padding-right: 6px;
  text-decoration: underline;
`;

const FirstPlayDiscountPrice = ({ price, size="sm", color="pink" }: { price: number, size?: SizeType, color?: string }) => {
  return (
    <Price color={color} size={size}>{numberToPrice(price/2)}</Price>
  )
}

export default FirstPlayDiscountPrice;
