import { BaseButton } from 'Components/Parts/Buttons';
import { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlayerPayment } from 'Schemes/Payment';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';
import { formatTimeString } from 'Utils/TimeUtil';

const Wrapper = styled.div`
  background-color: ${Colors.white};
  border-radius: 5px;
  margin: 10px 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 110px;
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 70%;
`;

const Image = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  float: left;
`;

const LastUpdatedAt = styled.p`
  margin: 0;
  color: ${Colors.gray};
  font-size: 10px;
`;

const UserName = styled.p`
  margin: 0;
  color: ${Colors.black};
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
`;

const Sales = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.pink};
`;

const StyledBaseButton = styled(BaseButton)`
  margin: 17px 0;
`;

type Props = {
  payment: PlayerPayment;
} & RouteComponentProps;

const PlayerPaymentBlock: FC<Props> = ({ payment, history }) => {
  const image = payment.UserProfileImageURL ? payment.UserProfileImageURL : '/assets/noimage.jpg';
  return (
    <Wrapper>
      <Content>
        <Image profileImage={image} />
        <div>
          <UserName>{payment.UserName}</UserName>
          <Sales>{numberToPrice(payment.Sales)}</Sales>
          <LastUpdatedAt>{formatTimeString(payment.LastUpdatedAt)}</LastUpdatedAt>
        </div>
      </Content>
      <StyledBaseButton onClick={() => history.push(`/player/chat/${payment.UserUUID}`)}>チャット</StyledBaseButton>
    </Wrapper>
  );
};

export default withRouter(PlayerPaymentBlock);
