import 'Assets/stylesheets/top/faq.css';
import FAQHead2X from 'Assets/images/top/faq_head@2x.webp';
import FAQHeadSp from 'Assets/images/top/faq_head_sp.webp';

const FAQ = () => {
  return (
    <div id="faq" className="p-m-index-faq is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-faq__top">
            <h2 className="p-m-index-faq__head">
              <picture>
                <source media="(min-width: 751px)" width="354" height="182" srcSet={FAQHead2X} />
                <source media="(max-width: 750px)" width="286" height="146" srcSet={FAQHeadSp} />
                <img src={FAQHead2X} loading="lazy" alt="FAQ" />
              </picture>
            </h2>
            <h3 className="p-m-index-faq__h3">よくある質問</h3>
          </div>
          <div className="p-m-index-faq__bottom">
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q1" />
                <label htmlFor="q1" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">会員登録は無料ですか？</span>
                </label>
                <div className="c-m-accordion__folder">
                  <p className="p-m-index-faq__p01">
                    会員登録は無料です。
                    <br />
                    その他年会費や更新料など一切かからずにご利用いただけます。
                    <br />
                    プレイヤーチケットを購入する際に費用がかかります。
                  </p>
                </div>
              </div>
            </div>
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q2" />
                <label htmlFor="q2" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">年齢制限はありますか？</span>
                </label>
                <div className="c-m-accordion__folder">
                  <p className="p-m-index-faq__p01">
                    未成年者の場合は、親権者または未成年後見人（以下「親権者等」といいます。）の承諾を得た上で、本サービスをご利用ください。
                    <br />
                    未成年者の会員が本サービスを利用した場合、親権者等の承諾を得たものとみなします。
                  </p>
                </div>
              </div>
            </div>
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q3" />
                <label htmlFor="q3" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">会員登録・ログインはどのように行いますか？</span>
                </label>
                <div className="c-m-accordion__folder">
                  <p className="p-m-index-faq__p01">2PLAYの会員登録・ログインは、LINEログインを使用します。</p>
                </div>
              </div>
            </div>
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q4" />
                <label htmlFor="q4" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">間違えて購入したためキャンセルしたい</span>
                </label>
                <div className="c-m-accordion__folder">
                  <p className="p-m-index-faq__p01">
                    チケットを間違えて購入された場合でも2PLAYメッセージ内でプレイヤーが同意していればキャンセル可能です。
                    <br />
                    プレイヤー同意後お問い合わせフォームよりご連絡ください。
                  </p>
                </div>
              </div>
            </div>
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q5" />
                <label htmlFor="q5" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">利用できる支払い方法について</span>
                </label>
                <div className="c-m-accordion__folder">
                  <p className="p-m-index-faq__p01">
                    2PLAYで利用できる支払い方法はクレジットカードのみとなります。
                    <br />
                    クレジットカード以外のお支払いは現在行えません。
                  </p>
                </div>
              </div>
            </div>
            <div className="c-m-accordion p-m-index-faq__accordion">
              <div className="c-m-accordion__block">
                <input type="checkbox" id="q6" />
                <label htmlFor="q6" className="c-m-accordion__label">
                  <span className="p-m-index-faq__q">Q.</span>
                  <span className="p-m-index-faq__label">ボイスチャット（VC）はどのように行いますか？</span>
                </label>
                <div className="c-m-accordion__folder">
                  <span className="p-m-index-faq__p01">Discordアプリなどを活用いただきます。</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
