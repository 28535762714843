
import { FC } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

type Props = {
  errors: string[];
};

const Error = styled.p`
  color: ${Colors.pink};
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
  margin: 0;
`;

const FormErrors: FC<Props> = ({ errors }) => {
  const setErrorNodes = () => {
    let errorNodes = [];
    for (var i in errors) {
      errorNodes.push(<Error>{errors[i]}</Error>);
    }
    return errorNodes;
  };

  return <>{setErrorNodes()}</>;
};

export default FormErrors;
