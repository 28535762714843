import { Link } from "react-router-dom";

import "Assets/stylesheets/top/header.css";
import Logo2X from "Assets/images/top/header_logo@2x.webp";

const Header = () => {
  return (
    <header className="l-m-header">
      <div className="c-m-inner c-m-inner--wide">
        <div className="c-m-inner__content c-m-inner__content--expand">
          <div className="l-m-header__left">
            <figure className="l-m-header__logo">
              <picture>
                <source
                  media="(min-width: 751px)"
                  width="93" height="24"
                  srcSet={Logo2X}
                />
                <source
                  media="(max-width: 750px)"
                  width="134"
                  height="35"
                  srcSet={Logo2X}
                />
                <img src={Logo2X} alt="2PLAYのロゴ" />
              </picture>
            </figure>
          </div>
          <div className="l-m-header__right">
            <Link to="login" className="l-m-header__login u-m-button--white">ログイン</Link>
            <Link to="sign_up" className="l-m-header__line u-m-button--grad">LINE登録</Link>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;