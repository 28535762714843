import { numberToByte } from './NumberHelper';

const FileValidator = (
  file: File,
  maxFileSize: number = 10 * Math.pow(1024, 2),
  acceptContentType: string[] = ['image/jpeg', 'image/jpg', 'image/png'],
): string[] => {
  let errors: string[] = [];

  if (file.size > maxFileSize) {
    errors.push(`ファイルサイズが${numberToByte(maxFileSize)}を超えてます。`);
  }

  if (file.type && !acceptContentType.includes(file.type)) {
    errors.push(`ファイル形式が不正です。 ${file.type}`);
  }

  return errors;
};
export default FileValidator;
