import SectionLabel from 'Components/Parts/SectionLabel';
import { FC, useState } from 'react';
import styled from 'styled-components';
import Select, { OptionsType } from 'react-select';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import { PlayerProfile } from 'Schemes/Player';
import { stringToHM, stringToTime } from 'Utils/TimeUtil';
import Colors from 'Utils/Colors';

const Wrapper = styled.section`
  margin: 40px 0 20px;
`;

const StyledSelect = styled(Select)`
  display: inline-block;
  width: 80%;
  height: 50px;
  line-height: 38px;
`;

const ExtendTime = styled.div`
  width: 55%;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 40%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin-left: 20px;
  font-size: 16px;
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

const WorkText = styled.p`
  margin: 0 0 20px;
  line-height: 20px;
  font-size: 14px;
`;

const StyledWorkEndButton = styled(BaseButton)`
  border: 1px solid ${Colors.gray};
  color: ${Colors.gray};
  margin-top: 2px;
`;

const extendOptions = (): OptionsType<{ label: string; value: string }> => {
  return [
    { value: '0.5', label: '30分' },
    { value: '1', label: '1時間' },
  ];
};

type Props = {
  playerProfile: PlayerProfile;
  startWork: (endAt: number) => void;
  endWork: () => void;
};

const PlayerWorkUpdate: FC<Props> = ({ playerProfile, startWork, endWork }) => {
  const [extendTime, setExtendTime] = useState(extendOptions()[0]);

  const stampStartWork = () => {
    const currentEndAt = Math.floor(stringToTime(playerProfile.WorkEndAt) / 1000);
    const workEndAt = currentEndAt + Number(extendTime.value) * 60 * 60;
    startWork(workEndAt);
  };

  return (
    <Wrapper>
      <SectionLabel>出退勤</SectionLabel>
      <FlexWrap>
        <WorkText>
          退勤時刻は、{stringToHM(playerProfile.WorkEndAt)}になります。
          <br />※ 時間になったら自動退勤します。
        </WorkText>
        <StyledWorkEndButton onClick={endWork}>退勤する</StyledWorkEndButton>
      </FlexWrap>
      <FlexWrap>
        <ExtendTime>
          <StyledSelect
            onChange={setExtendTime}
            isSearchable={false}
            value={extendTime}
            options={extendOptions()}
          ></StyledSelect>
        </ExtendTime>
        <StyledPrimaryButton onClick={stampStartWork}>延長する</StyledPrimaryButton>
      </FlexWrap>
    </Wrapper>
  );
};

export default PlayerWorkUpdate;
