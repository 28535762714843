import { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BasicLayout from 'Pages/Layouts/Basic';
import { match } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PlayerReviewForm from 'Components/PlayerReviews/Form';

const stripePK = process.env.REACT_APP_STRIPE_PUBKEY || 'pk_test_QgnztLux20pEwbCROZCFqL3i00xGYjRkw2';
const stripePromise = loadStripe(stripePK);

type Props = {
  match: match<{ uuid: string }>;
};

const UserPlayerReviewPage: FC<Props> = ({ match }) => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <Elements stripe={stripePromise}>
          <PlayerReviewForm paymentUUID={match.params.uuid} />
        </Elements>
      </BasicLayout>
    </>
  );
};

export default UserPlayerReviewPage;
