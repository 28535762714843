import DevelopmentConfig from './Environments/development';
import StagingConfig from './Environments/staging';
import ProductionConfig from './Environments/production';

const getConfig: any = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return ProductionConfig;
    case 'staging':
      return StagingConfig;
    default:
      return DevelopmentConfig;
  }
};

const Config: any = getConfig();
export default Config;
