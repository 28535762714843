import { FC, useEffect, useState } from 'react';
import MenuTitle from 'Components/Parts/MenuTitle';
import { User } from 'Schemes/User';
import styled from 'styled-components';
import Axios from 'axios';
import Config from 'Config/Config';
import Colors from 'Utils/Colors';
import FollowBox from 'Components/Users/FollowBox';
import BasicLayout from 'Pages/Layouts/Basic';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledFollowBox = styled(FollowBox)`
  text-decoration: none;
  color: ${Colors.black};
  border-bottom: 1px solid ${Colors.borderGray};
  display: block;
`;

const NoFollowers = styled.p`
  margin: 20px;
`;

const PlayerFollowersPage: FC = () => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  const fetchFollowers = () => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/player/followers`)
      .then((res) => {
        setInitialLoaded(true);
        setUsers(res.data);
      });
  };

  useEffect(() => {
    fetchFollowers();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <Wrapper>
          <MenuTitle>フォローしてくれたユーザー</MenuTitle>
          {users.map((user) => {
            return <StyledFollowBox key={user.UUID} user={user} isFollowing={true} />;
          })}
          {initialLoaded && users.length === 0 && <NoFollowers>フォローされたユーザーはまだいません。</NoFollowers>}
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default PlayerFollowersPage;
