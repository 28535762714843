import Axios from 'axios';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import Config from 'Config/Config';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import { playerMainProfileImage } from 'Utils/ProfileImage';

const Wrapper = styled.section`
  width: 100%;
  background: white;
  height: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  display: table;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 20px 8px 20px 20px;
  float: left;
`;

const ProfileName = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 90px;
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 8px;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
`;

const FollowButton = styled(BaseButton)`
  margin: 27px 20px 27px 0;
`;

const UnFollowButton = styled(PrimaryButton)`
  margin: 27px 20px 27px 0;
`;

type Props = {
  player: PlayerProfile;
  isFollowing: boolean;
  className?: string;
};

const FollowBox: FC<Props> = ({ player, isFollowing, className }) => {
  const [following, setFollowing] = useState(isFollowing);
  useEffect(() => {
    setFollowing(isFollowing);
  }, [isFollowing]);

  const follow = async (player: PlayerProfile) => {
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/follow`, { PlayerID: player.UUID })
      .then((_) => {
        setFollowing(true);
      });
  };

  const unFollow = async (player: PlayerProfile) => {
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/unfollow`, { PlayerID: player.UUID })
      .then((_) => {
        setFollowing(false);
      });
  };

  return (
    <Wrapper className={className}>
      <StyledLink to={`/players/${player.UUID}`}>
        <ProfileImage profileImage={playerMainProfileImage(player)} />
        <ProfileName>{player?.Name}</ProfileName>
      </StyledLink>
      <div>
        {following && <UnFollowButton value="フォロー中" onClick={() => unFollow(player)} />}
        {!following && <FollowButton value="フォローする" onClick={() => follow(player)} />}
      </div>
    </Wrapper>
  );
};

export default FollowBox;
