import { useState, useEffect, FC, useContext } from 'react';
import Axios from 'axios';

import { PlayerGame, PlayerList, PlayerProfile } from 'Schemes/Player';
import { match } from 'react-router-dom';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import MenuTitle from 'Components/Parts/MenuTitle';
import ProfileImages from 'Components/Players/ProfileImages';
import Colors from 'Utils/Colors';
import styled, { css } from 'styled-components';
import FixedFooter from 'Components/Players/FixedFooter';
import { Review } from 'Schemes/Review';
import ReviewList from 'Components/Players/ReviewList';
import loading from 'Assets/loading.svg';
import PageNotFound from 'Components/PageNotFound';
import { UserContext } from 'App';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import PlayerStatus from 'Components/Players/PlayerStatus';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import AdminPlayerStatus from 'Components/Admin/PlayerStatus';
import { Helmet } from 'react-helmet';
// import XIcon from "Assets/images/common/logo-x.png";

const LoadingImg = styled.img`
  display: block;
  margin: 100px auto;
  width: 50px;
  height: 50px;
`;

const StyledBasicLayout = styled(BasicLayout)`
  padding-bottom: 146px;
`;

const Header = styled.div`
  height: 82px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-right: 20px;
  float: left;
`;

const NameArea = styled.div`
  float: left;
`;

const PlayerName = styled.p`
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  font-weight: bold;
`;

const ButtonCSS = css`
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  margin: 2px;
  font-weight: bold;
  display: block;
  float: right;
`;

const FollowButton = styled(BaseButton)`
  ${ButtonCSS}
`;

const UnFollowButton = styled(PrimaryButton)`
  ${ButtonCSS}
`;

const Content = styled.p`
  margin: 0;
  padding: 20px;
  background-color: ${Colors.white};
  line-height: 1.4em;
  white-space: pre-wrap;
`;

const GameIconWrapper = styled.div`
  background-color: ${Colors.white};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: space-between;
  text-align: center;
`;

const GameIcon = styled.img`
  border-radius: 10px;
  width: 80%;
  height: auto;
`;

const GameTitleWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 22%;
  text-align: center;
  padding: 0 1.5%;
`;

const GameTitle = styled.p`
  margin: 12px 0 4px;
  font-weight: normal;
  line-height: 20px;
  min-height: 45px;
  font-size: 14px;
  color: ${Colors.black};
`;

const ReviewWrapper = styled.section`
  padding: 0 20px;
  > div:last-child {
    border: none;
  }
`;

// NOTE: アイコンverに変更後適用
// const SnsUrlWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 20px 0;
// `;
// const SnsUrl = styled.a`
//   display: block;
//   margin: 20px;
//   text-align: center;
//   text-decoration: none;
//   color: ${Colors.black};
// `;

const SnsUrlList = styled.dl`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const SnsName = styled.dt`
  min-width: 130px;
`;
const SnsUrlWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: start;
  align-items: center;
`;
const SnsUrlLink = styled.a`
  word-wrap:break-word;
  max-width: 330px;
  display: block;
  text-align: center;
  text-decoration: underline;
  color: ${Colors.black};
`;

type Props = {
  location: {
    state: {
      playerListInfo: PlayerList | undefined;
    };
  };
  match: match<{ uuid: string }>;
} & RouteComponentProps;

const PlayerDetailPage: FC<Props> = ({ location, match, history }) => {
  const playerListInfo = location.state && location.state.playerListInfo ? location.state.playerListInfo : null;
  const [playerInfo, setPlayerInfo] = useState<PlayerList | PlayerProfile | null>(playerListInfo);
  const [games, setGames] = useState<PlayerGame[]>([]);
  const [initialLoadFinished, setInitialLoadFinishied] = useState(playerInfo !== null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [notFound, setNotFound] = useState(false);
  const [hasSnsAccount, setHasSnsAccount] = useState<boolean>(false);
  const userContext = useContext(UserContext);
  const user = userContext.user;

  useEffect(() => {
    const playerId = match.params.uuid;
    const fetchPlayer = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/s/${playerId}`)
        .then((res) => {
          setPlayerInfo(res.data);
          setInitialLoadFinishied(true);

          const { XUrl, InstagramUrl, YoutubeUrl, TiktokUrl, TwitchUrl } = res.data;
          setHasSnsAccount(XUrl || InstagramUrl || YoutubeUrl || TiktokUrl || TwitchUrl);
        })
        .catch((err) => {
          if (err.response.status === 404) setNotFound(true);
        });
    };

    const fetchGames = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/s/${playerId}/games`)
        .then((res) => {
          setGames(res.data);
        });
    };

    const getUserFollowStatus = async () => {
      const playerId = match.params.uuid;
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/followers/${playerId}`)
        .then((res) => setIsFollowing(res.data.Count > 0))
        .catch((err) => {
          if (err.response.status !== 401) console.error(err);
        });
    };

    const fetchReviews = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/s/${playerId}/reviews`)
        .then((res) => {
          setReviews(res.data);
        });
    };

    const createPlayerInbox = () => {
      Axios.create({ withCredentials: true })
        .post(`${Config.API_HOST}/user/inboxes/${playerId}`)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        })
    }

    fetchPlayer();
    fetchGames();
    getUserFollowStatus();
    fetchReviews();
    createPlayerInbox();
  }, [match.params.uuid]);

  const follow = async (player: PlayerProfile) => {
    if (userContext.user === null) {
      history.push('/login');
    }
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/follow`, { PlayerID: player.UUID })
      .then((_) => {
        setIsFollowing(true);
      });
  };

  const unfollow = async (player: PlayerProfile) => {
    if (userContext.user === null) {
      history.push('/login');
    }
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/unfollow`, { PlayerID: player.UUID })
      .then((_) => {
        setIsFollowing(false);
      });
  };

  if (notFound)
    return (
      <BasicLayout>
        <PageNotFound />
      </BasicLayout>
    );

  if (initialLoadFinished && playerInfo) {
    const player = playerInfo as PlayerProfile;

    return (
      <>
        <Helmet>
          <meta name="robots" content="all" />
        </Helmet>
        <StyledBasicLayout>
          <Header>
            <ProfileImage profileImage={playerMainProfileImage(player)} />
            <NameArea>
              <PlayerName>{player.Name}</PlayerName>
              <PlayerStatus status={player.PlayerStatus} />
            </NameArea>
            {isFollowing && <UnFollowButton value="フォロー中" onClick={() => unfollow(player)} />}
            {!isFollowing && <FollowButton value="フォローする" onClick={() => follow(player)} />}
          </Header>
          <ProfileImages player={player} />
          {user && user.IsAdmin && (
            <>
              <MenuTitle>管理</MenuTitle>
              <AdminPlayerStatus player={player} />
            </>
          )}
          <MenuTitle>自己紹介</MenuTitle>
          <Content>{player.Introduction}</Content>
          {hasSnsAccount && (
            <>
              <MenuTitle>SNSアカウント</MenuTitle>
              <Content>
                <SnsUrlList>
                  <SnsUrlWrapper show={player.XUrl.length > 0}>
                    <SnsName>X（旧Twitter）：</SnsName>
                    <dd style={{ margin: '0' }}>
                      <SnsUrlLink href={`https://www.x.com/${player.XUrl}`} target="_blank" rel="noopener noreferrer">
                        {`https://www.x.com/${player.XUrl}`}
                      </SnsUrlLink>
                    </dd>
                  </SnsUrlWrapper>
                  <SnsUrlWrapper show={player.InstagramUrl.length > 0}>
                    <SnsName>Instagram：</SnsName>
                    <dd style={{ margin: '0' }}>
                      <SnsUrlLink href={`https://www.instagram.com/${player.InstagramUrl}`} target="_blank" rel="noopener noreferrer">
                        {`https://www.instagram.com/${player.InstagramUrl}`}
                      </SnsUrlLink>
                    </dd>
                  </SnsUrlWrapper>
                  <SnsUrlWrapper show={player.YoutubeUrl.length > 0}>
                    <SnsName>YouTube：</SnsName>
                    <dd style={{ margin: '0' }}>
                      <SnsUrlLink href={`https://www.youtube.com/${player.YoutubeUrl}`} target="_blank" rel="noopener noreferrer">
                        {`https://www.youtube.com/${player.YoutubeUrl}`}
                      </SnsUrlLink>
                    </dd>
                  </SnsUrlWrapper>
                  <SnsUrlWrapper show={player.TiktokUrl.length > 0}>
                    <SnsName>TikTok：</SnsName>
                    <dd style={{ margin: '0' }}>
                      <SnsUrlLink href={`https://www.tiktok.com/${player.TiktokUrl}`} target="_blank" rel="noopener noreferrer">
                        {`https://www.tiktok.com/${player.TiktokUrl}`}
                      </SnsUrlLink>
                    </dd>
                  </SnsUrlWrapper>
                  <SnsUrlWrapper show={player.TwitchUrl.length > 0}>
                    <SnsName>Twitch：</SnsName>
                    <dd style={{ margin: '0' }}>
                      <SnsUrlLink href={`https://www.twitch.tv/${player.TwitchUrl}`} target="_blank" rel="noopener noreferrer">
                        {`https://www.twitch.tv/${player.TwitchUrl}`}
                      </SnsUrlLink>
                    </dd>
                  </SnsUrlWrapper>
                </SnsUrlList>
              </Content>
              {/* <SnsUrlWrapper>
                <SnsUrl
                  href={`https://www.x.com/${player.XUrl}`}
                  target="_blank"
                  style={{ pointerEvents: player.XUrl ? "unset" : "none" }}
                >
                  <img src={XIcon} width={30} height={30} alt="Xのロゴ" />
                </SnsUrl>
                <SnsUrl
                  href={`https://www.instagram.com/${player.InstagramUrl}`}
                  target="_blank"
                  style={{ pointerEvents: player.InstagramUrl ? "unset" : "none" }}
                >
                  <img src={XIcon} width={30} height={30} alt="Instagramのロゴ" />
                </SnsUrl>
                <SnsUrl
                  href={`https://www.youtube.com/${player.YoutubeUrl}`}
                  target="_blank"
                  style={{ pointerEvents: player.YoutubeUrl ? "unset" : "none" }}
                >
                  <img src={XIcon} width={30} height={30} alt="Youtubeのロゴ" />
                </SnsUrl>
                <SnsUrl
                  href={`https://www.tiktok.com/${player.TiktokUrl}`}
                  target="_blank"
                  style={{ pointerEvents: player.TiktokUrl ? "unset" : "none" }}
                >
                  <img src={XIcon} width={30} height={30} alt="Tiktokのロゴ" />
                </SnsUrl>
                <SnsUrl
                  href={`https://www.twitch.tv/${player.TwitchUrl}`}
                  target="_blank"
                  style={{ pointerEvents: player.TwitchUrl ? "unset" : "none" }}
                >
                  <img src={XIcon} width={30} height={30} alt="Xのロゴ" />
                </SnsUrl>
              </SnsUrlWrapper> */}
            </>
          )}

          <MenuTitle>プレイしやすい時間帯</MenuTitle>
          <Content>{player.WorkingTime}</Content>
          <MenuTitle>対応できるゲーム・雑談・オンライン飲み会など</MenuTitle>
          <GameIconWrapper>
            {games.map((game) => {
              return (
                <GameTitleWrapper key={game.Name}>
                  <GameIcon src={game.IconURL} />
                  <GameTitle>{game.Name}</GameTitle>
                </GameTitleWrapper>
              );
            })}
          </GameIconWrapper>

          {reviews.length > 0 && (
            <>
              <MenuTitle>購入者の評価</MenuTitle>
              <ReviewWrapper>
                {reviews.map((review) => {
                  return <ReviewList key={review.ReviewedAt} review={review} />;
                })}
              </ReviewWrapper>
            </>
          )}

          <FixedFooter player={player} />
        </StyledBasicLayout>
      </>
    );
  } else {
    return (
      <BasicLayout>
        <LoadingImg src={loading} alt="loading" />
      </BasicLayout>
    );
  }
};

export default withRouter(PlayerDetailPage);
