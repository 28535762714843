import { FC, useContext, useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';

import { UserContext } from 'App';
import RegistrationStep from 'Components/Player/RegistrationStep';
import Root from '../../Components/Top/Root';
import PlayerDashboardPage from '../../Components/Player/Dashboard/PlayerDashboard';

const RootPage: FC = () => {
  const search = useLocation().search;
  const query = queryString.parse(search);
  const userContext = useContext(UserContext);
  const user = userContext.user;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, setCookie] = useCookies();

  useEffect(() => {
    if (query.cxsid) {
      setCookie('session_id', query.cxsid);
    }
  }, [query.cxsid, setCookie]);

  if (!userContext.initialLoaded) return <></>;
  if (user?.PlayerProfile && user.PlayerProfile.PlayerStatus === 0) {
    return <RegistrationStep />;
  } else if (user?.PlayerProfile) {
    return <PlayerDashboardPage playerProfile={user.PlayerProfile} />;
  } else if (user) {
    return <Redirect to="/players" />
  } else {
    return <Root />;
  }
};

export default RootPage;
