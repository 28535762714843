import { useState, useEffect } from 'react';
import Axios from 'axios';
import Config from 'Config/Config';

export const useIsFirstPayment = (playerUUID: string | number): { isFirstPayment: boolean } => {
  const [isFirstPayment, setIsFirstPayment] = useState(false);

  useEffect(() => {
    const fetchIsFirstPayment = async () => {
      await Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/inboxes/${playerUUID}/first_payment`)
        .then((res) => {
          setIsFirstPayment(res.data.IsFirstPayment);
        })
    }

    fetchIsFirstPayment();
  }, [playerUUID]);

  return { isFirstPayment: isFirstPayment };
}
