import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Colors from 'Utils/Colors';
import { formatTimeString } from 'Utils/TimeUtil';
import { ProfileImage } from 'Components/Parts/ProfileImage';

type Props = {
  message: string;
  profileImage: string;
  sentAt: string;
  playerUUID?: string;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 10px 0 20px;
  justify-content: space-between;
`;

const Content = styled.div`
  margin: 0 10px;
  width: calc(100% - 36px - 20px - 18px);
`;

const Message = styled.div`
  background-color: ${Colors.white};
  font-size: 14px;
  color: ${Colors.black};
  border-radius: 5px;
  white-space: pre-wrap;
  line-height: 24px;
  padding: 8px;
`;

const Date = styled.p`
  color: ${Colors.gray};
  font-size: 10px;
  margin: 4px 0 0;
`;

const YourMessage: FC<Props> = ({ message, profileImage, sentAt, playerUUID }) => {
  return (
    <Wrapper>
      {playerUUID ?
        <Link to={`/players/${playerUUID}`}>
          <ProfileImage profileImage={profileImage} />
        </Link>
        :
        <ProfileImage profileImage={profileImage} />
      }
      <Content>
        <Message>{message}</Message>
        <Date>{formatTimeString(sentAt)}</Date>
      </Content>
    </Wrapper>
  );
};

export default YourMessage;
