
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FC } from 'react';

type Props = {
  count: number;
  up: Function;
  down: Function;
  className?: string;
};

const Up = styled.span`
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid ${Colors.borderPink};
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  color: ${Colors.pink};
  background-color: '#FEF8F8';
  border-radius: 0 5px 5px 0;
  cursor: pointer;
`;

const Count = styled.span`
  width: 36px;
  height: 36px;
  line-height: 36px;
  background-color: ${Colors.pink};
  border: 1px solid ${Colors.pink};
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  color: white;
  font-weight: bold;
`;

const Down = styled.span`
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid ${Colors.borderPink};
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  background-color: '#FEF8F8';
  border-radius: 5px 0 0 5px;
  color: ${Colors.pink};
  cursor: pointer;
`;

const OrderCounter: FC<Props> = ({ count, up, down, className }) => {
  return (
    <div className={className}>
      <Down onClick={() => down()}>
        <FontAwesomeIcon icon={faMinus} />
      </Down>
      <Count>{count}</Count>
      <Up onClick={() => up()}>
        <FontAwesomeIcon icon={faPlus} />
      </Up>
    </div>
  );
};

export default OrderCounter;
