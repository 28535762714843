import Axios from 'axios';
import { BaseButton } from 'Components/Parts/Buttons';
import MenuTitle from 'Components/Parts/MenuTitle';
import SectionLabel from 'Components/Parts/SectionLabel';
import Config from 'Config/Config';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { EnabledA } from 'Components/Parts/EnabledLink';
import BasicLayout from 'Pages/Layouts/Basic';

const Wrapper = styled.div`
  background-color: ${Colors.white};
`;

const Content = styled.div`
  padding: 20px;
`;

const StyledBaseButton = styled(BaseButton)`
  width: calc(100% - 20px);
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid ${Colors.black};
  color: ${Colors.black};
  position: relative;
  display: block;
  margin: 10px 0;
`;

const StyledChecked = styled(FontAwesomeIcon)`
  padding-right: 10px;
`;

const StyledAngle = styled(FontAwesomeIcon)`
  font-size: 24px;
  margin: 0 20px;
  line-height: 50px;
  height: 50px;
  display: inline-block;
  color: ${Colors.black};
  position: absolute;
  top: 0;
  right: 0;
`;

type PlayerStatus = {
  StripeConnected: boolean;
  LineNotifyConnected: boolean;
};

const RegistrationStep: FC = () => {
  const [playerStatus, setPlayerStatus] = useState<PlayerStatus | null>(null);
  useEffect(() => {
    const getStatus = async () => {
      await Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/player/status`)
        .then((response) => setPlayerStatus(response.data));
    };
    getStatus();
  }, []);

  const profileComplete = () => {
    return true;
  };

  const stripeConnected = () => {
    return playerStatus?.StripeConnected;
  };

  const lineConnected = () => {
    return playerStatus?.LineNotifyConnected;
  };

  if (playerStatus === null) return <></>;

  return (
    <BasicLayout>
      <Wrapper>
        <MenuTitle>ダッシュボード</MenuTitle>
        <Content>
          <SectionLabel>プレイヤー設定</SectionLabel>
          <p>
            プレイヤーとして活動いただくには、初期セットアップを行う必要があります。ご不明な点があれば、気軽に2PLAY事務局に相談ください。
          </p>
          <Link to="/player/profile">
            <StyledBaseButton>
              <StyledChecked
                icon={faCheckCircle}
                style={{ color: profileComplete() ? '#5AB64E' : Colors.borderGray }}
              />
              プロフィール編集
              <StyledAngle icon={faAngleRight} />
            </StyledBaseButton>
          </Link>
          <EnabledA link={`${Config.API_HOST}/player/stripe`} enabled={!stripeConnected()}>
            <StyledBaseButton>
              <StyledChecked
                icon={faCheckCircle}
                style={{ color: stripeConnected() ? '#5AB64E' : Colors.borderGray }}
              />
              入金口座設定（Stripe）
              {!stripeConnected() && <StyledAngle icon={faAngleRight} />}
            </StyledBaseButton>
          </EnabledA>
          <Link to={'/line_notify'}>
            <StyledBaseButton>
              <StyledChecked icon={faCheckCircle} style={{ color: lineConnected() ? '#5AB64E' : Colors.borderGray }} />
              通知設定（LINE）
              {!lineConnected() && <StyledAngle icon={faAngleRight} />}
            </StyledBaseButton>
          </Link>
        </Content>
      </Wrapper>
    </BasicLayout>
  );
};

export default RegistrationStep;
