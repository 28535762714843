import { FC, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import PriceInfo from './PriceInfo';
import Axios from 'axios';
import Config from 'Config/Config';
import { NotificationAlert } from 'Components/Parts/Notification';

const StyledButton = styled.button`
  width: 60%;
  height: 60px;
  padding: 0 11px;
  border-radius: 5px;
  border: 1px solid ${Colors.pink};
  background-color: ${Colors.pink};
  color: white;
  font-weight: bold;
  line-height: 60px;
  font-size: 18px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

const NotificationWrapper = styled.div`
  padding: 20px;
  background-color: ${Colors.white};
`;

type Props = {
  playerID: string;
  orderCount: number;
  price: number;
  discountPrice: number;
  campaignCode: string;
  closeModal: () => void;
  afterCheckoutAction?: () => void;
  isFirstTimeDiscount?: boolean;
};

const CheckoutZero: FC<Props> = (props) => {
  const { price, discountPrice, orderCount, playerID, closeModal, afterCheckoutAction, campaignCode, isFirstTimeDiscount } = props;

  const [checkouting, setCheckouting] = useState(false);
  const [cardAlertMessage, setCardAlertMessage] = useState<string | null>(null);

  const checkout = () => {
    if (checkouting) return;
    setCheckouting(true);
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/user/checkout/zero`, {
        Amount: price,
        PlayerID: playerID,
        Quontity: orderCount,
        CampaignCode: campaignCode,
      })
      .then((_) => {
        if (afterCheckoutAction) afterCheckoutAction();
        setCheckouting(false);
        closeModal();
      })
      .catch((_) => {
        setCheckouting(false);
        setCardAlertMessage('決済に失敗しました。');
      });
  };

  return (
    <>
      <PriceInfo 
        price={price} 
        discountPrice={discountPrice} 
        orderCount={orderCount} 
        isFirstTimeDiscount={isFirstTimeDiscount}
      />
      {cardAlertMessage && (
        <NotificationWrapper>
          <NotificationAlert>{cardAlertMessage}</NotificationAlert>
        </NotificationWrapper>
      )}
      <StyledButton disabled={checkouting} onClick={checkout}>
        チケットを購入する
      </StyledButton>
    </>
  );
};

export default CheckoutZero;
