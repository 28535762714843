export const stringToTime = (str: string) => {
  return new Date(str).getTime();
};

const zeroPadding = (time: number) => {
  return ('00' + time).slice(-2);
};

export const formatTimeString = (str: string) => {
  const date = new Date(str);
  const now = new Date();

  const year = date.getFullYear() === now.getFullYear() ? '' : `${date.getFullYear()}年`;
  const month = date.getMonth() + 1 + '月';
  const day = date.getDate() + '日';
  const time = zeroPadding(date.getHours()) + ':' + zeroPadding(date.getMinutes());

  return `${year}${month}${day} ${time}`;
};

export const remainedSec = (str: string) => {
  const date = new Date(str).getTime();
  const now = new Date().getTime();

  return Math.max(Math.floor((date - now) / 1000), 0);
};

export const remainedTimeStr = (str: string) => {
  let sec = remainedSec(str);

  if (sec < 60) return `${zeroPadding(sec)}秒`;

  let minutes = Math.floor(sec / 60);
  sec = sec % 60;
  if (minutes < 60) return `${zeroPadding(minutes)}分${zeroPadding(sec)}秒`;

  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (hours < 24) return `${hours}時間${zeroPadding(minutes)}分${zeroPadding(sec)}秒`;

  const days = Math.floor(hours / 24);
  hours = hours % 24;
  return `${days}日${hours}時間${zeroPadding(minutes)}分${zeroPadding(sec)}秒`;
};

export const minutesToStr = (minutes: number) => {
  if (minutes < 60) return `${zeroPadding(minutes)}分`;

  let hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  if (minutes === 0) {
    return `${hours}時間`;
  }
  return `${hours}時間${zeroPadding(minutes)}分`;
};

export const stringToHM = (str: string) => {
  const now = new Date();
  const date = new Date(str);
  const day = now.getDate() === date.getDate() ? '本日' : '明日';
  return `${day}${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}`;
};

export const stringToUnix = (str: string) => {
  return new Date(str).getTime();
};
