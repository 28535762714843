import 'Assets/stylesheets/top/price.css';
import PriceHead2X from 'Assets/images/top/price_head@2x.webp';
import PriceHeadSp from 'Assets/images/top/price_head_sp.webp';

const Price = () => {
  return (
    <div className="p-m-index-price is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-price__top">
            <h2 className="p-m-index-price__head">
              <picture>
                <source media="(min-width: 751px)" width="529" height="145" srcSet={PriceHead2X} />
                <source media="(max-width: 750px)" width="428" height="117" srcSet={PriceHeadSp} />
                <img src={PriceHead2X} loading="lazy" alt="PRICE" />
              </picture>
            </h2>
            <h3 className="p-m-index-price__h3">チケット料⾦</h3>
          </div>
          <div className="p-m-index-price__bottom">
            <ul className="c-m-list--price">
              <li className="p-m-index-price__term">
                <strong>30分</strong>あたり
              </li>
              <li className="p-m-index-price__desc">
                750<small>円</small>~
              </li>
            </ul>
            <br/>
            <p className="p-m-index-price__p01">
              更に初回<span className="aux01">50%OFF</span>キャンペーン実施中！
            </p>
            <p className="p-m-index-price__p02">
              ※プレイヤーによって料金・割引が異なります。
              <br/>
              ※お⽀払いはクレジットカード決済のみです。
              <br />
              ※チケットは5⽇間有効です。
              <br />
              ※プレイヤーから同意があれば、30分単位で延⻑することができます。
              <br />
              ※税込価格です。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
