import Axios from 'axios';
import MenuTitle from 'Components/Parts/MenuTitle';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useState } from 'react';
import { PaymentBalance } from 'Schemes/Payment';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { PrimaryButton } from 'Components/Parts/Buttons';
import CommonModal from 'Components/Parts/Modals/CommonModal';
import loading from 'Assets/loading.svg';
import { Helmet } from 'react-helmet';

const LoadingImg = styled.img`
  display: block;
  margin: 100px auto;
  width: 50px;
  height: 50px;
`;

const BalanceInfo = styled.div`
  width: 100%;
  background-color: ${Colors.white};
  text-align: center;
  border-bottom: 1px solid ${Colors.borderGray};
`;

const Title = styled.p`
  margin: 0;
  padding: 30px 0 10px 0;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.black};
`;

const Amount = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: ${Colors.pink};
`;

const PendingAmount = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
`;

const MenuUL = styled.ul`
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: ${Colors.black};
  }
`;

const MenuList = styled.li`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid ${Colors.borderGray};
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  text-indent: 20px;
  position: relative;
`;

const StyledFoward = styled(FontAwesomeIcon)`
  font-size: 24px;
  padding: 18px;
  width: 24px;
  color: ${Colors.black};
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-bottom: 20px;
`;

const StyledPayoutButton = styled(PrimaryButton)`
  display: block;
  width: calc(100% - 20px);
  text-align: center;
  line-height: 60px;
  height: 60px;
  font-size: 18px;
`;

const ModalInfo = styled.p`
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.black};
`;

const Attention = styled.span`
  color: ${Colors.pink};
`;

const PalyerPaymentsBalancePage: FC = () => {
  const [balance, setBalance] = useState<PaymentBalance | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [balancing, setBalancing] = useState(false);

  const fetchBalance = async () => {
    const response = await Axios.create({ withCredentials: true }).get(`${Config.API_HOST}/player/payments/balance`);
    setBalance(response.data);
  };

  const payoutBalance = () => {
    if (balancing) return;
    setBalancing(true);
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/player/payments/payout`)
      .then((_) => {
        fetchBalance();
        setModalOpen(false);
      })
      .finally(() => {
        setBalancing(false);
      });
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>売上確認・振込申請</MenuTitle>
        {balance && (
          <>
            <BalanceInfo>
              <Title>現在受取可能な売上高</Title>
              <Amount>{balance && numberToPrice(balance.AvailableAmount)}</Amount>
              <PendingAmount>
                未確定売上 {balance && numberToPrice(balance.PendingAmount)}
                <br />
                ※売上は450円以上の場合引き出しが可能です。
                <br />
                ※売上は4営業日程度で確定し、振込申請が可能になります。
              </PendingAmount>
              {balance.AvailableAmount > 450 && (
                <StyledPrimaryButton onClick={() => setModalOpen(true)}>売上金を引き出す</StyledPrimaryButton>
              )}
            </BalanceInfo>
            <MenuUL>
              {balance.LoginLink !== '' && (
                <a href={balance.LoginLink}>
                  <MenuList>
                    STRIPE管理画面
                    <StyledFoward icon={faAngleRight} />
                  </MenuList>
                </a>
              )}
            </MenuUL>
          </>
        )}
        {!balance && <LoadingImg src={loading} alt="loading" />}
        <CommonModal isOpen={modalOpen} title="売上金を引き出す" closeModal={() => setModalOpen(false)}>
          <ModalInfo>
            【引き出しにあたっての注意事項】
            <br />
            ・引き出しには<Attention>450円</Attention>の振込手数料がかかります。
            <br />
            ・振り込み手数料は売上から差し引かれます。
            <br />
            ・引き出し申請は確定後の中止はできません。
            <br />
            ・売上金の振り込みには2営業日程度かかります。
          </ModalInfo>
          <StyledPayoutButton onClick={payoutBalance}>売上金を引き出す</StyledPayoutButton>
        </CommonModal>
      </BasicLayout>
    </>
  );
};

export default PalyerPaymentsBalancePage;
