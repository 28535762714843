import { FC, useState } from 'react';
import PlayerList from 'Components/Players/List';
import BasicLayout from 'Pages/Layouts/Basic';
import MenuTitle from 'Components/Parts/MenuTitle';
import { Helmet } from 'react-helmet';

const PlayersPage: FC = () => {
  const [page, setPage] = useState(1);

  return (
    <>
      <Helmet>
        <meta name="robots" content="all" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>プレイヤー一覧</MenuTitle>
        <PlayerList page={page} />
        <div onClick={() => setPage(1)} />
      </BasicLayout>
    </>
  );
};

export default PlayersPage;
