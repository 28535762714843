import { useState, useEffect, FC, useContext } from 'react';
import Axios from 'axios';
import styled from 'styled-components';
import { UserContext } from 'App';
import PlayerBlock from './Block';
import { PlayerList } from 'Schemes/Player';
import Colors from 'Utils/Colors';
import GameSearchHeader from './GameSearchHeader';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import Config from 'Config/Config';
import { BaseButton } from 'Components/Parts/Buttons';
import PlayerListAll from 'Components/Players/ListAll';

type PlayerListProps = {
  page?: number;
} & RouteComponentProps<{ game_id?: string }>;

const Wrapper = styled.section`
  text-align: center;
  background-color: ${Colors.bgWhite};
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0 3% 6%;
  box-sizing: border-box;
`;

const StyledBaseButton = styled(BaseButton)`
  display: inline-block;
  margin: 0 auto;
`;

const StyledPlayerBlock = styled(PlayerBlock)`
  background-color: ${Colors.bgPink};
`;

const PlayersList: FC<PlayerListProps> = ({ page, location, history }) => {
  const { user } = useContext(UserContext);
  const [players, setPlayer] = useState([]);
  const [game, setGame] = useState(null);

  useEffect(() => {
    const getPlayer = async () => {
      const queryObject = queryString.parse(location.search);
      const gameId = queryObject.game_id;
      const query = gameId ? `?game_id=${gameId}` : '';
      const response = await Axios.get(`${Config.API_HOST}/players${query}`);
      setPlayer(response.data.Players || []);
    };
    getPlayer();

    const getGame = async () => {
      const queryObject = queryString.parse(location.search);
      const gameId = queryObject.game_id;
      if (gameId === null || gameId === undefined) return;
      const response = await Axios.get(`${Config.API_HOST}/games/${gameId}`);

      setGame(response.data);
    };
    getGame();
  }, [location, page, history]);

  return (
    <>
      <GameSearchHeader game={game} />
      {game ? (
        <>
          <Wrapper>
            {players.map((player: PlayerList) => {
              return <StyledPlayerBlock key={`player${player.UUID}`} player={player} currentUser={user} />;
            })}
          </Wrapper>
          <Wrapper>
            <StyledBaseButton
              value="全てのプレイヤーを見る"
              onClick={()=>{
                history.push('/players');
                setGame(null);
              }}>
            </StyledBaseButton>
          </Wrapper>
        </>
      ) : (
        <PlayerListAll />
      )}
    </>
  );
};

export default withRouter(PlayersList);
