import { FC, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStartSolid, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStartRegular } from '@fortawesome/free-regular-svg-icons';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

type Props = {
  score: number;
  className?: string;
  updateRating: (i: number) => void;
};

const StarIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  cursor: pointer;
  font-weight: normal;
  margin-right: 4px;
`;

const Wrapper = styled.div`
  color: ${Colors.pink};
`;

const FiveStarRateTouchable: FC<Props> = ({ score, className, updateRating }) => {
  const [innerScore, setInnerScore] = useState(score);

  useEffect(() => {
    setInnerScore(score);
  }, [score]);

  const updateInnerScore = (i: number) => {
    setInnerScore(i);
  };

  const renderStars = () => {
    const stars = [];

    for (let i = 0; i < 5; i++) {
      let icon;
      if (innerScore - i <= 0) {
        icon = faStartRegular;
      } else if (innerScore - i <= 0.5) {
        icon = faStarHalfAlt;
      } else {
        icon = faStartSolid;
      }

      stars.push(
        <StarIcon
          key={i}
          icon={icon}
          onClick={() => updateRating(i + 1)}
          onMouseEnter={() => updateInnerScore(i + 1)}
          onMouseLeave={() => updateInnerScore(score)}
        />,
      );
    }

    return stars;
  };

  return <Wrapper className={className}>{renderStars()}</Wrapper>;
};

export default FiveStarRateTouchable;
