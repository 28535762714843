import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC } from 'react';

import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 71px - 122px);
  background-image: url(/loginbackground.png);
  background-repeat: no-repeat;
  background-color: ${Colors.bgColor};
  background-size: 100% auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentInner = styled.div`
  width: 80%;
`;

const LineButton = styled.a`
  display: inline-block;
  background-color: ${Colors.lineGreen};
  width: 100%;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin-top: 20px;
`;

const LineIcon = styled.img`
  font-size: 50px;
  padding: 0;
  margin: 0;
  color: ${Colors.lineGreen};
  background-color: transparent;
  width: 55px;
  height: 55px;
  float: left;
`;

const LineLabel = styled.span`
  display: inline-block;
  margin: 0;
  text-align: center;
  width: calc(100% - 55px);
  line-height: 55px;
  font-weight: bold;
`;

const Title = styled.h2`
  margin: 0;
  font-weight: bold;
  font-size: 24px;
  color: ${Colors.black};
  line-height: 36px;
`;

const Caption = styled.p`
  color: ${Colors.gray};
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
`;

const LoginPage: FC = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="all" />
      </Helmet>
      <BasicLayout>
        <Wrapper>
          <ContentInner>
            <Title>利用を開始する</Title>
            <Caption>ログインはLINEアカウントで行います</Caption>
            <LineButton href={`${Config.API_HOST}/oauth/line`}>
              <LineIcon src="/LINE_APP.png" />
              <LineLabel>LINEで利用開始</LineLabel>
            </LineButton>
            <Caption>
              ※ LINEには一切投稿されません
              <br />
              ※ 18歳未満の方は登録できません
              <br />
              ※ご登録頂いたメールアドレスにお知らせ・メールマガジンを送付することがあります（解除可能）<br />
              登録することで、<a href="https://lp.2play.game/terms">利用規約</a>・<a href="https://lp.2play.game/privacy">プライバシーポリシー</a>
              に同意したことになります。
              <br />
            </Caption>
          </ContentInner>
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default LoginPage;
