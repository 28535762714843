import { UserContext } from 'App';
import { PrimaryButton } from 'Components/Parts/Buttons';
import { FC, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  height: 146px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  border: 1px solid ${Colors.borderGray};
  background: ${Colors.white};
`;

const ButtonArea = styled.div`
  clear: both;
  display: flex;
  justify-content: space-between;
`;

const StyledApplyButton = styled(PrimaryButton)`
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
`;

const Caption = styled.p`
  height: 36px;
  font-size: 12px;
  line-height: 18px;
  margin: 12px 0;
  color: ${Colors.gray};
`;

type Props = {
  player: PlayerProfile;
} & RouteComponentProps;

const FixedFooter: FC<Props> = ({ player, history }) => {
  const userContext = useContext(UserContext);

  return (
    <Wrapper>
      <Caption>
        ※ このプレイヤーと一緒にプレイしたい方は、「メッセージを送る」から日時調整とチケット購入をしてください。
      </Caption>
      <ButtonArea>
        <StyledApplyButton
          onClick={() => {
            if (userContext.user === null) {
              history.push('/login');
            } else {
              history.push(`/chat/${player.UUID}`);
            }
          }}
        >
          メッセージを送る
        </StyledApplyButton>
      </ButtonArea>
    </Wrapper>
  );
};

export default withRouter(FixedFooter);
