import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

import { ReactComponent as Icon } from 'Assets/touch_app.svg';
import { CurrentPayment } from 'Schemes/Payment';
import { remainedTimeStr } from 'Utils/TimeUtil';

const Notification = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.white};
  justify-content: space-between;
  font-size: 12px;
  color: ${Colors.gray};
  line-height: 20px;
  padding: 10px 20px;
`;

const ActionButtonArea = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  border-bottom: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.bgGray};
`;

const ActionButton = styled.div`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.pink};
  line-height: 20px;
  padding: 10px 0;
`;

const StyledIcon = styled(Icon)`
  height: 20px;
`;

type Props = {
  currentPayment: CurrentPayment;
  setShowStartPlayModal: (value: boolean) => void;
};

const StartPlayAction: FC<Props> = ({ currentPayment, setShowStartPlayModal }) => {
  const [time, updateTime] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => updateTime(Date.now()), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [time]);

  return (
    <>
      <Notification>
        Discord ID・ゲームIDを交換してください。 準備が整ったら、プレイ開始を押してください。 残り時間{' '}
        {remainedTimeStr(currentPayment.CaptureTimelimitAt)}。
      </Notification>
      <ActionButtonArea>
        <ActionButton onClick={() => setShowStartPlayModal(true)}>
          <StyledIcon />
          <br />
          プレイ開始！
        </ActionButton>
      </ActionButtonArea>
    </>
  );
};

export default StartPlayAction;
