import Axios from 'axios';
import { InputField } from 'Components/Parts/Forms/Input';
import FormLabel from 'Components/Parts/Forms/Label';
import StyledRadio from 'Components/Parts/Forms/Radio';
import { TextArea } from 'Components/Parts/Forms/TextArea';
import MenuTitle from 'Components/Parts/MenuTitle';
import SectionLabel from 'Components/Parts/SectionLabel';
import Config from 'Config/Config';
import { FC, useEffect, useState, useRef } from 'react';
import { PlayerProfile, PlayerSelectGame } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { Gender } from 'Utils/Gender';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { Game } from 'Schemes/Game';
import FileValidator from 'Utils/FileValidator';
import { ProfileImage } from 'Schemes/ProfileImage';
import PlayerProfileImageModal from 'Components/Parts/Modals/PlayerProfileImageModal';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import AudioButton from 'Components/Parts/Audio';
import Caption from 'Components/Parts/Forms/Caption';
import { NotificationSuccess, NotificationAlert } from 'Components/Parts/Notification';
import BasicLayout from 'Pages/Layouts/Basic';
import { Helmet } from 'react-helmet';

const CANVAS_SIZE = 1024;

const Wrapper = styled.main`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: ${Colors.white};
`;

const Pink = styled.span`
  color: ${Colors.pink};
`;

const ProfileImageWrapper = styled.div`
  margin: 12px 0;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProfileImageDiv = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  width: 65px;
  padding-top: 65px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  display: inline-block;
  border-radius: 5px;
  margin: 0 8px 0 0;
`;

const ProfileNoImage = styled(ProfileImageDiv)`
  &:hover {
    background-image: url(/assets/bg_plus.png);
  }
`;

const FormInner = styled.div`
  margin-top: 32px;
`;

const Example = styled.div`
  background-color: ${Colors.bgPink};
  border: 1px solid ${Colors.borderGray};
  border-radius: 5px;
  white-space: pre-wrap;
  padding: 8px;
  margin-top: 16px;
`;

const StyledSectionLabel = styled(SectionLabel)`
  margin-top: 40px;
`;

const GameIconWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: space-between;
  text-align: center;
`;

const GameIcon = styled.img`
  border-radius: 10px;
  width: 80%;
  height: auto;
`;

const GameTitleWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  width: 22%;
  text-align: center;
  padding: 0 1.5%;
`;

const GameTitle = styled.p`
  margin: 12px 0 4px;
  font-weight: normal;
  line-height: 20px;
  min-height: 45px;
  font-size: 14px;
  color: ${Colors.black};
`;

const StyledFACheck = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-bottom: 24px;
  color: ${Colors.pink};
`;

const StyledFASquare = styled(FontAwesomeIcon)`
  font-size: 20px;
  margin-bottom: 24px;
  color: ${Colors.gray};
`;

const StyledAudioUploadButton = styled(BaseButton)`
  display: block;
  margin: 20px auto;
  text-align: center;
  width: 60%;
  height: 40px;
  line-height: 40px;
`;

const StyledAudioButton = styled(AudioButton)`
  display: block;
  width: 140px;
  margin: 20px auto;
`;

const SubmitButton = styled(PrimaryButton)`
  line-height: 60px;
  height: 60px;
  font-size: 14px;
  text-align: center;
  margin: 20px auto;
  width: 60%;
  display: block;
  font-size: 18px;
`;

const StyledCanvas = styled.canvas`
  width: 0;
  height: 0;
  opacity: 0;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PriceUnit = styled.div`
  min-width: 90px;
  text-align: right;
  letter-spacing: 1.75px;
  font-weight: bold;
`;

const SnsFieldWrapper = styled.div`
  margin-top: 20px;
`;

const SnsName = styled.div`
  min-width: 100px;
`;

const SnsUrlInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const SnsUrlPrefix = styled.div`
  position: absolute;
  left: 115px;
  top: 55%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #555;

  &::before {
    content: '${props => props.prefix}';
    font-weight: bold;
    background: white;
    pointer-events: none;
  }
`;

const SnsInput = styled(InputField) <{ space: number }>`
  width: 100%;
  padding-left: ${(props) => props.space}px;
  display: block;
`;

const PlayerProfilePage: FC = () => {
  const [playerProfile, setPlayerProfile] = useState<PlayerProfile | null>(null);
  const [games, setGames] = useState<Game[]>([]);
  const [playerGames, setPlayerGames] = useState<PlayerSelectGame[]>([]);
  const [currentUploadImage, setCurrentUploadImage] = useState<ProfileImage | null>(null);
  const [showPlayerProfileImageModal, setShowPlayerProfileImageModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const audioInputRef = useRef<HTMLInputElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const fetchPlayerProfile = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/player/profile`)
        .then((res) => {
          setPlayerProfile(res.data);
        });
    };

    const fetchPlayerGames = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/player/profile/games`)
        .then((res) => {
          setPlayerGames(res.data);
        });
    };

    const fetchGames = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/player/games`)
        .then((res) => {
          setGames(res.data);
        });
    };

    fetchPlayerProfile();
    fetchGames();
    fetchPlayerGames();
  }, []);

  const toggleGame = (game: Game) => {
    if (canPlay(game)) {
      setPlayerGames((prev) => {
        const newPlayerGame = prev.filter((v) => v.GameID !== game.ID);
        return newPlayerGame;
      });
    } else {
      setPlayerGames((prev) => {
        return [...prev, { GameID: game.ID }];
      });
    }
  };

  const canPlay = (game: Game) => {
    let canPlay = false;
    playerGames.some((playerGame) => {
      if (playerGame.GameID === game.ID) {
        canPlay = true;
        return true;
      }
      return false;
    });
    return canPlay;
  };

  const handleDeleteProfileImage = () => {
    if (playerProfile === null) return;
    setPlayerProfile((prev) => {
      const newProfile = Object.assign({}, prev);
      let newProfileImages: ProfileImage[] = [];
      if (prev?.ProfileImages) {
        let i = 1;
        newProfile.ProfileImages.filter((image) => {
          return image.SortKey !== currentUploadImage?.SortKey;
        }).map((image) => {
          image.SortKey = i;
          newProfileImages.push(image);
          i++;
          return image;
        });
        newProfile.ProfileImages = newProfileImages;
      }
      return newProfile;
    });
    setShowPlayerProfileImageModal(false);
  };

  const handelAudioClick = () => {
    audioInputRef.current?.click();
  };

  const onAudioDurationChange = (duration: number) => {
    setPlayerProfile((prev) => {
      const newProfile = Object.assign({}, prev);
      newProfile.AudioFileDuration = duration;
      return newProfile;
    });
  };

  const handleChangeAudio = (files: any) => {
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    console.log(file.size);
    const errors = FileValidator(file, 10 * Math.pow(1024, 2), ['audio/mpeg', 'audio/aac', 'audio/x-m4a']);
    if (errors.length > 0) {
      // handleUserFormError('ProfileImage', errors);
      console.log(errors);
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setPlayerProfile((prev) => {
        const newProfile = Object.assign({}, prev);
        newProfile.Base64AudioFileURL = (reader.result as string).replace('application/octet-stream', 'audio/mpeg');
        return newProfile;
      });
      setShowPlayerProfileImageModal(false);
    };
    reader.onerror = function (error) {
      console.error(error);
    };
  };

  const handleFileClick = () => {
    inputRef.current?.click();
  };

  function drawImageProp(ctx: CanvasRenderingContext2D, img: HTMLImageElement) {
    const iw = img.width;
    const ih = img.height;

    const sw = Math.min(iw, ih);
    const sh = sw;
    const sx = (iw - sw) / 2;
    const sy = (ih - sh) / 2;

    ctx.drawImage(img, sx, sy, sw, sh, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
  }

  const handleChangeImage = (files: any) => {
    console.log(currentUploadImage);
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    console.log(file.size);
    const errors = FileValidator(file);
    if (errors.length > 0) {
      // handleUserFormError('ProfileImage', errors);
      console.log(errors);
      return;
    }
    let image = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      image.onload = function () {
        if (canvasRef.current) {
          const canvas = canvasRef.current;
          canvas.width = CANVAS_SIZE;
          canvas.height = CANVAS_SIZE;
          let ctx = canvas.getContext('2d');
          drawImageProp(ctx!, image);
          const dataURL = canvas.toDataURL('image/jpeg', 0.8);

          setPlayerProfile((prev) => {
            const newProfile = Object.assign({}, prev);
            let newProfileImage: ProfileImage;
            if (currentUploadImage) {
              console.log(currentUploadImage);
              newProfileImage = {
                SortKey: currentUploadImage.SortKey,
                Base64URL: dataURL,
                URL: '',
              };
              newProfile.ProfileImages[currentUploadImage.SortKey - 1] = newProfileImage;
            } else {
              newProfileImage = {
                SortKey: newProfile.ProfileImages.length + 1,
                Base64URL: dataURL,
                URL: '',
              };
              newProfile.ProfileImages = [...newProfile.ProfileImages, newProfileImage];
            }

            return newProfile;
          });
          setShowPlayerProfileImageModal(false);
        }
      };

      image.src = reader.result as string;
    };
    reader.onerror = function (error) {
      console.error(error);
    };
  };

  const renderProfileImages = () => {
    let nodes = [];
    let isFistNoImage = false;
    for (let i = 0; i < 10; i++) {
      const image = playerProfile?.ProfileImages[i];
      if (image !== undefined) {
        const url = image.Base64URL || image.URL;
        nodes.push(
          <ProfileImageDiv
            key={`profileImage${i}`}
            profileImage={url!}
            onClick={() => {
              setCurrentUploadImage(image);
              setShowPlayerProfileImageModal(true);
            }}
          />,
        );
      } else {
        const noImagePath = isFistNoImage ? '/assets/noimage.jpg' : '/assets/bg_plus.png';
        isFistNoImage = true;
        nodes.push(
          <ProfileNoImage
            key={`profileImage${i}`}
            profileImage={noImagePath}
            onClick={() => {
              setCurrentUploadImage(null);
              handleFileClick();
            }}
          />,
        );
      }
    }

    return nodes;
  };

  const audioFileURL = () => {
    return playerProfile?.Base64AudioFileURL || playerProfile?.AudioFileURL;
  };

  const playerGameIDS = () => {
    return playerGames.map((p) => p.GameID);
  };

  const validateParams = () => {
    if (playerProfile === null) return false;

    if (playerProfile.Name.length === 0) {
      setAlertMessage('プレイヤー名を入力してください');
      return false;
    }
    if (playerProfile.Price < 750 || playerProfile.Price > 10000) {
      setAlertMessage('チケット価格は750円以上10000円以下で設定してください');
      return false;
    }
    if (playerProfile.Introduction.length === 0) {
      setAlertMessage('自己紹介を入力してください');
      return false;
    }
    if (playerProfile.WorkingTime.length === 0) {
      setAlertMessage('プレイしやすい時間帯を入力してください');
      return false;
    }

    return true;
  }

  const sendData = () => {
    if (playerProfile === null) return;
    setSuccessMessage(null);
    setAlertMessage(null);

    if (!validateParams()) {
      window.scrollTo(0, 0);
      return;
    }

    Axios.create({ withCredentials: true })
      .patch(`${Config.API_HOST}/player/profile`, {
        ...playerProfile,
        GameIDS: playerGameIDS(),
      })
      .catch((_) => {
        setSuccessMessage(null);
        setAlertMessage('更新に失敗しました。');
        window.scrollTo(0, 0);
      })
      .then((_) => {
        setAlertMessage(null);
        setSuccessMessage('プロフィールを更新しました。');
        window.scrollTo(0, 0);
      });
  };

  if (playerProfile === null) {
    return (
      <BasicLayout>
        <MenuTitle>プレイヤー設定</MenuTitle>
      </BasicLayout>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>プレイヤー設定</MenuTitle>
        <Wrapper>
          <StyledCanvas ref={canvasRef} />
          {successMessage && <NotificationSuccess>{successMessage}</NotificationSuccess>}
          {alertMessage && <NotificationAlert>{alertMessage}</NotificationAlert>}
          <SectionLabel>基本情報</SectionLabel>

          <FormInner>
            <FormLabel required={true}>プロフィール画像</FormLabel>
            <ProfileImageWrapper>{renderProfileImages()}</ProfileImageWrapper>
            <input
              type="file"
              ref={inputRef}
              style={{ display: 'none' }}
              onChange={(e) => handleChangeImage(e.target.files)}
            />
            {currentUploadImage && (
              <PlayerProfileImageModal
                isOpen={showPlayerProfileImageModal}
                profileImage={currentUploadImage}
                closeModal={() => setShowPlayerProfileImageModal(false)}
                handleFileClick={handleFileClick}
                handleDeleteProfileImage={handleDeleteProfileImage}
              />
            )}
          </FormInner>

          <FormInner>
            <FormLabel required={true}>音声をアップロード</FormLabel>
            {audioFileURL() && (
              <StyledAudioButton
                audioURL={audioFileURL()!}
                audioDuration={playerProfile.AudioFileDuration}
                onAudioDurationChange={onAudioDurationChange}
              />
            )}
            <StyledAudioUploadButton onClick={handelAudioClick}>音声をアップロード</StyledAudioUploadButton>
            <input
              type="file"
              ref={audioInputRef}
              style={{ display: 'none' }}
              onChange={(e) => handleChangeAudio(e.target.files)}
            />
            <Caption>※3～60秒の録音をアップロードしてください</Caption>
            <Example>
              例：こんにちは、私はxxです。PUBGが大好きなので、一緒に楽しめたら嬉しいです。一緒にドン勝しましょうね！
            </Example>
          </FormInner>

          <FormInner>
            <FormLabel required={true}>プレイヤー名</FormLabel>
            <InputField
              type="text"
              value={playerProfile.Name}
              onChange={(e) => {
                e.persist();
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.Name = e.target.value;
                  return newPlayer;
                });
              }}
            />
          </FormInner>

          <FormInner>
            <FormLabel required={true}>性別</FormLabel>
            <StyledRadio
              label="女性"
              name="gender"
              checked={playerProfile.Gender === Gender.female}
              onChange={() =>
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.Gender = Gender.female;
                  return newPlayer;
                })
              }
            />
            <StyledRadio
              label="男性"
              name="gender"
              checked={playerProfile.Gender === Gender.male}
              onChange={() =>
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.Gender = Gender.male;
                  return newPlayer;
                })
              }
            />
          </FormInner>
          <FormInner>
            <FormLabel required={true}>チケット価格(最低価格750円~)</FormLabel>
            <PriceWrapper>
              <InputField
                type="number"
                step={50}
                min={750}
                max={10000}
                value={playerProfile.Price}
                onChange={(e) => {
                  e.persist();
                  setPlayerProfile((p) => {
                    const newPlayer: PlayerProfile = Object.assign({}, p);
                    newPlayer.Price = Number(e.target.value);
                    return newPlayer;
                  });
                }}
              />
              <PriceUnit>円/30分</PriceUnit>
            </PriceWrapper>
          </FormInner>

          <FormInner>
            <FormLabel required={true}>初回30分50％割引（初回購入ユーザー30分限定の価格）</FormLabel>
            <StyledRadio
              label="有り"
              name="is_discount"
              checked={playerProfile.IsFirstTimeDiscount}
              onChange={() => {
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.IsFirstTimeDiscount = true;
                  return newPlayer;
                })
              }}
            />
            <StyledRadio
              label="無し"
              name="is_discount"
              checked={!playerProfile.IsFirstTimeDiscount}
              onChange={() => {
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.IsFirstTimeDiscount = false;
                  return newPlayer;
                })
              }}
            />
          </FormInner>
          <SubmitButton onClick={sendData}>情報を更新する</SubmitButton>
          <FormInner>
            <FormLabel required={true}>自己紹介</FormLabel>
            <TextArea
              rows={10}
              value={playerProfile.Introduction}
              onChange={(e) => {
                e.persist();
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.Introduction = e.target.value;
                  return newPlayer;
                });
              }}
            />
            <Example>
              <Pink>
                こちらを参考にしてください
                <br />※ 自己紹介
              </Pink>
              <br />
              例）
              <br />
              はじめまして、xxxxです
              <br />
              <br />
              PS4とスマホでゲームしてます。
              <br />
              ぜひ仲良くしてください～(*´Д｀)
              <br />
              <br />
              <Pink>※ ゲーム紹介</Pink>
              <br />
              例）
              <br />
              ・ゲーム名
              <br />
              どんなキャラクターを使っているのか？
              <br />
              現在のレベルやランクなど、
              <br />
              ゲームのレベル感が伝わる情報を書いてください。
              <br />
              <br />
              ・ゲーム名
              <br />
              どんなキャラクターを使っているのか？
              <br />
              現在のレベルやランクなど、
              <br />
              ゲームのレベル感が伝わる情報を書いてください。
              <br />
              <br />
              ・ゲーム名
              <br />
              どんなキャラクターを使っているのか？
              <br />
              現在のレベルやランクなど、
              <br />
              ゲームのレベル感が伝わる情報を書いてください。
              <br />
              <br />
              <Pink>※ 最後に一言</Pink>
              <br />
              例）
              <br />
              雑談・ゲームともにそんなにうまくはなくて、
              <br />
              リードしてくれる人すごく助かります。
              <br />
              <br />
              ワイワイ or 落ち着いた感じ、敬語 or タメ口、などなど
              <br />
              どんな雰囲気でお話ししたいかリクエストあれば教えて
              <br />
              ください
              <br />
            </Example>
          </FormInner>

          <FormInner>
            <FormLabel required={false}>SNSアカウント</FormLabel>
            {/* X */}
            <SnsFieldWrapper>
              <SnsUrlInputWrapper>
                <SnsName><FormLabel required={false}>X（旧Twitter）</FormLabel></SnsName>
                <SnsUrlPrefix prefix="https://www.x.com/" />
                <SnsInput
                  type="text"
                  value={playerProfile.XUrl}
                  space={140}
                  onChange={(e) => {
                    e.persist();
                    setPlayerProfile((p) => {
                      const newPlayer: PlayerProfile = Object.assign({}, p);
                      newPlayer.XUrl = e.target.value;
                      return newPlayer;
                    });
                  }}
                />
              </SnsUrlInputWrapper>
              {/* Instagram */}
              <SnsUrlInputWrapper>
                <SnsName><FormLabel required={false}>Instagram</FormLabel></SnsName>
                <SnsUrlPrefix prefix="https://www.instagram.com/" />
                <SnsInput
                  type="text"
                  value={playerProfile.InstagramUrl}
                  space={200}
                  onChange={(e) => {
                    e.persist();
                    setPlayerProfile((p) => {
                      const newPlayer: PlayerProfile = Object.assign({}, p);
                      newPlayer.InstagramUrl = e.target.value;
                      return newPlayer;
                    });
                  }}
                />
              </SnsUrlInputWrapper>
              {/* Youtube */}
              <SnsUrlInputWrapper>
                <SnsName><FormLabel required={false}>YouTube</FormLabel></SnsName>
                <SnsUrlPrefix prefix="https://www.youtube.com/" />
                <SnsInput
                  type="text"
                  value={playerProfile.YoutubeUrl}
                  space={185}
                  onChange={(e) => {
                    e.persist();
                    setPlayerProfile((p) => {
                      const newPlayer: PlayerProfile = Object.assign({}, p);
                      newPlayer.YoutubeUrl = e.target.value;
                      return newPlayer;
                    });
                  }}
                />
              </SnsUrlInputWrapper>
              {/* TikTok */}
              <SnsUrlInputWrapper>
                <SnsName><FormLabel required={false}>TikTok</FormLabel></SnsName>
                <SnsUrlPrefix prefix="https://www.tiktok.com/" />
                <SnsInput
                  type="text"
                  value={playerProfile.TiktokUrl}
                  space={170}
                  onChange={(e) => {
                    e.persist();
                    setPlayerProfile((p) => {
                      const newPlayer: PlayerProfile = Object.assign({}, p);
                      newPlayer.TiktokUrl = e.target.value;
                      return newPlayer;
                    });
                  }}
                />
              </SnsUrlInputWrapper>
              {/* Twitch */}
              <SnsUrlInputWrapper>
                <SnsName><FormLabel required={false}>Twitch</FormLabel></SnsName>
                <SnsUrlPrefix prefix="https://www.twitch.tv/" />
                <SnsInput
                  type="text"
                  value={playerProfile.TwitchUrl}
                  space={158}
                  onChange={(e) => {
                    e.persist();
                    setPlayerProfile((p) => {
                      const newPlayer: PlayerProfile = Object.assign({}, p);
                      newPlayer.TwitchUrl = e.target.value;
                      return newPlayer;
                    });
                  }}
                />
              </SnsUrlInputWrapper>
            </SnsFieldWrapper>
          </FormInner>

          <FormInner>
            <FormLabel required={true}>プレーしやすい時間帯</FormLabel>
            <TextArea
              rows={10}
              value={playerProfile.WorkingTime}
              onChange={(e) => {
                e.persist();
                setPlayerProfile((p) => {
                  const newPlayer: PlayerProfile = Object.assign({}, p);
                  newPlayer.WorkingTime = e.target.value;
                  return newPlayer;
                });
              }}
            />
            <Example>
              例：
              <br />
              基本、20:00～深夜までできます！
              <br />
              12月は日中もプレイできる日が多いので、気軽にプレイ申請してくださいね( *´艸｀)
            </Example>
          </FormInner>

          <StyledSectionLabel>対応できるゲーム・雑談・オンライン飲み会など</StyledSectionLabel>
          <GameIconWrapper>
            {games.map((game) => {
              return (
                <GameTitleWrapper key={game.ID} onClick={() => toggleGame(game)}>
                  <GameIcon src={game.IconURL} />
                  <GameTitle>{game.Name}</GameTitle>
                  {canPlay(game) && <StyledFACheck icon={faCheckSquare} />}
                  {!canPlay(game) && <StyledFASquare icon={faSquare} />}
                </GameTitleWrapper>
              );
            })}
          </GameIconWrapper>
          <SubmitButton onClick={sendData}>情報を更新する</SubmitButton>
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default PlayerProfilePage;
