import FormLabel from 'Components/Parts/Forms/Label';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Axios from 'axios';
import { User } from 'Schemes/User';
import Config from 'Config/Config';
import Caption from 'Components/Parts/Forms/Caption';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import { InputField } from 'Components/Parts/Forms/Input';
import StyledRadio from 'Components/Parts/Forms/Radio';
import FormErrors from 'Components/Parts/Forms/Errors';
import FileValidator from 'Utils/FileValidator';
import BasicLayout from 'Pages/Layouts/Basic';
import { userMainProfileImage } from 'Utils/ProfileImage';
import { NotificationSuccess, NotificationAlert } from 'Components/Parts/Notification';
import { Gender } from 'Utils/Gender';
import MenuTitle from 'Components/Parts/MenuTitle';
import Colors from 'Utils/Colors';
import { Link } from 'react-router-dom';
import LineNotifyModal from 'Components/Parts/Modals/LineNotifyModal';
import { Helmet } from 'react-helmet';

const Wrapper = styled.section`
  width: 100%;
  background-color: white;
  min-height: calc(100vh - 71px - 122px);
  box-sizing: border-box;
  padding: 20px;
`;

const StyledLink = styled(Link)`
  padding-right: 20px;
`;

const PlayerNotification = styled.p`
  width: 100%;
  border: 1px solid ${Colors.borderGray};
  padding: 8px;
  box-sizing: border-box;
  margin: 0 0 20px;
`;

const FormInner = styled.div`
  margin-top: 32px;
`;

const ProfileImageContent = styled.div`
  text-align: center;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  width: 40%;
  padding-top: 40%;
  background-size: cover;
  background-position: center;
  margin-top: 20px;
  display: inline-block;
`;

const StyledBaseButton = styled(BaseButton)`
  display: block;
  width: 209px;
  margin: 10px auto 0;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: block;
  width: 209px;
  margin: 10px auto 0;
  text-align: center;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-size: 18px;
`;

const StyledNotificationSuccess = styled(NotificationSuccess)`
  margin: 0 0 20px;
`;

const StyledNotificationAlert = styled(NotificationAlert)`
  margin: 0 0 20px;
`;

const CANVAS_SIZE = 480;

const StyledCanvas = styled.canvas`
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
`;

type UserForm = {
  Base64ProfileImage?: string;
  Name: string;
  Gender: number;
};

type UserFormError = {
  ProfileImage: string[];
  Name: string[];
  Gender: string[];
};

const UserSettingProfilePage: FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userForm, setUserForm] = useState<UserForm>({ Name: '' } as UserForm);
  const [userFormError, setUserFormError] = useState<UserFormError>({
    ProfileImage: [],
    Name: [],
    Gender: [],
  });
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showLineNotifyModal, setShowLineNotifyModal] = useState(false);

  useEffect(() => {
    const getUser = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user`)
        .then((response) => {
          const user: User = response.data;
          setUser(user);
          setUserForm({
            Name: user.Name,
            Gender: user.Gender,
          });
        });
    };

    getUser();
  }, []);

  const profileImage = (): ReactNode => {
    if (userForm && userForm.Base64ProfileImage) {
      return <ProfileImage profileImage={userForm.Base64ProfileImage} />;
    }

    return <ProfileImage profileImage={userMainProfileImage(user)} />;
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileClick = () => inputRef.current?.click();

  function drawImageProp(ctx: CanvasRenderingContext2D, img: HTMLImageElement) {
    const iw = img.width;
    const ih = img.height;

    const sw = Math.min(iw, ih);
    const sh = sw;
    const sx = (iw - sw) / 2;
    const sy = (ih - sh) / 2;

    ctx.drawImage(img, sx, sy, sw, sh, 0, 0, CANVAS_SIZE, CANVAS_SIZE);
  }

  const handleChangeImage = (files: any) => {
    if (files.length === 0) {
      return;
    }
    const file = files[0];
    const errors = FileValidator(file);
    if (errors.length > 0) {
      handleUserFormError('ProfileImage', errors);
      return;
    }
    let image = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      image.onload = function () {
        if (canvasRef.current) {
          const canvas = canvasRef.current;
          canvas.width = CANVAS_SIZE;
          canvas.height = CANVAS_SIZE;
          let ctx = canvas.getContext('2d');
          drawImageProp(ctx!, image);
          const dataURL = canvas.toDataURL('image/jpeg', 0.8);
          setUserForm((prev) => {
            return {
              ...prev,
              Base64ProfileImage: dataURL,
            };
          });
        }
      };

      image.src = reader.result as string;
    };
    reader.onerror = function (error) {
      console.error(error);
    };
  };

  const handleUserFormError = (target: keyof UserFormError, errors: string[]) => {
    setUserFormError((prevUserFormError) => {
      const newUserFormError: UserFormError = Object.assign({}, prevUserFormError);
      newUserFormError[target] = errors;
      return newUserFormError;
    });
  };

  const sendData = () => {
    Axios.create({ withCredentials: true })
      .patch(`${Config.API_HOST}/user/account/profile`, {
        Name: userForm.Name,
        Base64ProfileImage: userForm.Base64ProfileImage,
        Gender: userForm.Gender,
      })
      .catch((_) => {
        setSuccessMessage(null);
        setAlertMessage('更新に失敗しました。');
        window.scrollTo(0, 0);
      })
      .then((_) => {
        setAlertMessage(null);
        setSuccessMessage('プロフィールを更新しました。');
        window.scrollTo(0, 0);

        var hideLineNotifyModalAt = localStorage.getItem('hideLineNotifyModalAt');
        if (hideLineNotifyModalAt === null && !user?.LineConnected) {
          setShowLineNotifyModal(true);
        }
      });
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>
          <StyledLink to={'/settings'}>＜</StyledLink>ユーザー情報の登録
        </MenuTitle>

        <Wrapper>
          {successMessage && <StyledNotificationSuccess>{successMessage}</StyledNotificationSuccess>}
          {alertMessage && <StyledNotificationAlert>{alertMessage}</StyledNotificationAlert>}
          {user && user.PlayerProfile && (
            <PlayerNotification>
              他プレイヤーのチケットを購入する際に、こちらの情報が使用されます。プレイヤー情報ではない写真や名前を使用したい場合は、更新してください。
            </PlayerNotification>
          )}
          <div>
            <FormLabel required={true}>プロフィール画像</FormLabel>
            <ProfileImageContent>
              {profileImage()}
              <StyledBaseButton value="写真をアップロード" onClick={handleFileClick} />
              <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={(e) => handleChangeImage(e.target.files)}
              />
              <Caption>※10MB以下の JPG、PNGファイルを選択してください</Caption>
              <FormErrors errors={userFormError.ProfileImage} />
            </ProfileImageContent>
          </div>
          <FormInner>
            <FormLabel required={true}>ユーザー名</FormLabel>
            <InputField
              type="text"
              value={userForm.Name}
              onChange={(e) => {
                e.persist();
                setUserForm((prev) => {
                  return {
                    ...prev,
                    Name: e.target.value,
                  };
                });
              }}
            />
          </FormInner>
          <FormInner>
            <FormLabel required={true}>性別</FormLabel>
            <StyledRadio
              label="女性"
              name="gender"
              checked={userForm.Gender === Gender.female}
              onChange={() => {
                setUserForm((prev) => {
                  return {
                    ...prev,
                    Gender: Gender.female,
                  };
                });
              }}
            />
            <StyledRadio
              label="男性"
              name="gender"
              checked={userForm.Gender === Gender.male}
              onChange={() => {
                setUserForm((prev) => {
                  return {
                    ...prev,
                    Gender: Gender.male,
                  };
                });
              }}
            />
          </FormInner>
          <FormInner>
            <StyledPrimaryButton value="保存する" onClick={() => sendData()} />
          </FormInner>
          <StyledCanvas ref={canvasRef} />
        </Wrapper>
        <LineNotifyModal
          closeModal={() => {
            setShowLineNotifyModal(false);
            const now = String(Date.now());
            localStorage.setItem('hideLineNotifyModalAt', now);
          }}
          isOpen={showLineNotifyModal}
        />
      </BasicLayout>
    </>
  );
};

export default UserSettingProfilePage;
