import { FC, useContext, ReactNode, useState } from 'react';

import Header from 'Components/Header';
import Sidebar from 'Components/Sidebar';
import Footer from 'Components/Footer';
import { UserContext } from 'App';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${Colors.white}!important;
  background-color: #fef8f8;
`;

const Main = styled.main`
  flex-grow: 1;
`;

type Props = {
  showLoginLink?: boolean;
  children?: ReactNode;
  className?: string;
};

const BasicLayout: FC<Props> = ({ children, showLoginLink = true, className }) => {
  const [showSideBar, setShowSideBar] = useState(false);
  const userContext = useContext(UserContext);

  return (
    <Wrapper className={className}>
      <Header setShowSidebar={setShowSideBar} userContext={userContext} showLoginLink={showLoginLink} />
      <Main>{children}</Main>
      <Footer />
      <Sidebar showSideBar={showSideBar} setShowSidebar={setShowSideBar} user={userContext.user} />
    </Wrapper>
  );
};

export default BasicLayout;
