import { FC } from 'react';
import { ProfileImage } from 'Schemes/ProfileImage';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import { BaseButton, PrimaryButton } from '../Buttons';
import CommonModal from './CommonModal';

const StyledProfileImage = styled.img`
  padding: 0 0 20px;
  max-width: 500px;
  width: 100%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
`;

const ButtonArea = styled.div`
  padding: 0;
  height: 70px;
`;

const ButtonCss = css`
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
`;

const DeleteButton = styled(BaseButton)`
  ${ButtonCss};
  float: left;
  border: 1px solid ${Colors.gray};
  color: ${Colors.gray};
  width: 30%;
`;

const ChangeButton = styled(PrimaryButton)`
  ${ButtonCss};
  float: right;
  width: 65%;
`;

type Props = {
  profileImage: ProfileImage;
  isOpen: boolean;
  closeModal: () => void;
  handleFileClick: () => void;
  handleDeleteProfileImage: () => void;
};

const PlayerProfileImageModal: FC<Props> = ({
  profileImage,
  isOpen,
  closeModal,
  handleFileClick,
  handleDeleteProfileImage,
}) => {
  return (
    <CommonModal title="写真プレビュー" isOpen={isOpen} closeModal={closeModal}>
      <StyledProfileImage src={profileImage.Base64URL || profileImage.URL} />
      <ButtonArea>
        <DeleteButton onClick={handleDeleteProfileImage}>削除する</DeleteButton>
        <ChangeButton onClick={handleFileClick}>変更する</ChangeButton>
      </ButtonArea>
    </CommonModal>
  );
};
export default PlayerProfileImageModal;
