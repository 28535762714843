import { FC, useState, useContext } from 'react';
import { PlayerProfile } from 'Schemes/Player';
import { Carousel } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { UserContext } from 'App';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import Colors from 'Utils/Colors';
import PlayerStatus from './PlayerStatus';
import AudioButton from 'Components/Parts/Audio';
import FiveStarRate from 'Components/Parts/FiveStarRate';
import { numberToPrice } from 'Utils/NumberHelper';
import FirstPlayDiscountPrice from './FirstPlayDiscountPrice';
import { useIsFirstPayment } from 'Hooks/useUserInboxes';

type ProfileImageProps = {
  profileImage: string;
};

const CarouselWrapper = styled.div`
  position: relative;
`;

const InfoCSS = css`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px;
`;

const Info = styled.div`
  ${InfoCSS}
  display: flex;
  justify-content: space-between;
  color: ${Colors.white};
  padding: 0 20px;
`;

const InfoBG = styled.div`
  ${InfoCSS}
  background-color: rgba(0, 0, 0, 0.5);
  filter: blur(40px);
`;

const Name = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 30px;
  height: 30px;
  font-weight: bold;
`;

const StyledFiveStarRate = styled(FiveStarRate)`
  color: ${Colors.white};
  padding-left: 0;
`;

const Price = styled.p`
  color: ${Colors.white};
  font-size: 24px;
  line-height: 24px;
  padding-top: 16px;
  margin: 0;
  font-weight: bold;
  float: left;
  > span {
    font-size: 14px;
  }
`;

const StyledAudioButton = styled(AudioButton)`
  margin-top: 60px;
  background-color: rgba(253, 160, 94, 0.8);
  border: none;
  color: ${Colors.white};
`;

const Image = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  width: 100%;
  padding-top: 100%;
  background-size: cover;
  background-position: center;
`;

const ImageWrapper = styled.div`
  width: 100%;
`;

const PlayerInfoWrapper = styled.div`
  box-shadow: 0px 10px 12px -12px rgba(0, 0, 0, 0.4) inset;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: ${Colors.white};
  display: flex;
  justify-content: space-between;
`;

const FollowCount = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 47px;
  > span {
    font-weight: bold;
  }
`;

const StyledPlayerStatus = styled(PlayerStatus)`
  line-height: 47px;
`;

const ThumbnailWrapper = styled.div`
  padding: 0 2% 2%;
  background-color: ${Colors.white};
`;

const Thumbnail = styled.img<{ selected: boolean }>`
  width: 18%;
  margin: 1%;
  box-sizing: border-box;
  ${(props) =>
    props.selected
      ? css`
          border: 4px solid ${Colors.pink};
        `
      : css``}
`;

const PriceValue = styled.span<{ isStrike: boolean }>`
  font-size: 24px!important;
  text-decoration: ${(props) => (props.isStrike ? 'line-through' : 'none')};
`;

const ProfileImage: FC<ProfileImageProps> = ({ profileImage }) => (
  <ImageWrapper>
    <Image profileImage={profileImage} />
  </ImageWrapper>
);

type Props = {
  player: PlayerProfile;
};

const ProfileImages: FC<Props> = ({ player }) => {
  const { user } = useContext(UserContext);  
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isFirstPayment } = useIsFirstPayment(player.UUID);
  const loggedIn = !!user;
  const isDiscountable = player.IsFirstTimeDiscount && (!loggedIn || (loggedIn && isFirstPayment));

  const getImages = () => {
    if (player.ProfileImages && player.ProfileImages.length > 1) {
      const images: JSX.Element[] = [];
      player.ProfileImages.map((image, index) =>
        images.push(<ProfileImage profileImage={image.URL} key={`profileImage${index}`} />),
      );
      return images;
    }

    return [<ProfileImage profileImage={playerMainProfileImage(player)} key={`profileImage1`} />];
  };

  return (
    <>
      <CarouselWrapper>
        <Carousel
          infiniteLoop
          useKeyboardArrows
          showThumbs={false}
          onChange={setCurrentImageIndex}
          selectedItem={currentImageIndex}
        >
          {getImages()}
        </Carousel>
        <InfoBG></InfoBG>
        <Info>
          <div>
            <Name>{player.Name}</Name>
            <StyledFiveStarRate score={player.Rating} />
            <Price>
              {isDiscountable && (
                <FirstPlayDiscountPrice price={player.Price} color="white" size="md" />
              )}
              <PriceValue isStrike={isDiscountable}>{numberToPrice(player.Price)}</PriceValue> / <span>30分</span>
            </Price>
          </div>
          <StyledAudioButton audioDuration={player.AudioFileDuration} audioURL={player.AudioFileURL} />
        </Info>
      </CarouselWrapper>
      <PlayerInfoWrapper>
        <FollowCount>
          <span>{player.FollowerCount}</span>フォロー
        </FollowCount>
        <StyledPlayerStatus status={player.PlayerStatus} />
      </PlayerInfoWrapper>
      <ThumbnailWrapper>
        {player.ProfileImages &&
          player.ProfileImages.map((image, i) => {
            return (
              <Thumbnail
                selected={i === currentImageIndex}
                key={`image${i}`}
                src={image.URL}
                onClick={() => setCurrentImageIndex(i)}
              />
            );
          })}
      </ThumbnailWrapper>
    </>
  );
};

export default ProfileImages;
