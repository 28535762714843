import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Label = styled.h2`
  border-left: 5px solid ${Colors.pink};
  font-size: 14px;
  line-height: 20px;
  margin: 10px 0 20px;
  text-indent: 0.5em;
`;

type Props = {
  className?: string;
  children: ReactNode;
};

const SectionLabel: FC<Props> = ({ children, className }) => {
  return <Label className={className}>{children}</Label>;
};

export default SectionLabel;
