import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

import { ReactComponent as Icon } from 'Assets/touch_app.svg';
import { CurrentPayment } from 'Schemes/Payment';
import { remainedTimeStr } from 'Utils/TimeUtil';

const Notification = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.white};
  justify-content: space-between;
  font-size: 12px;
  color: ${Colors.gray};
  line-height: 20px;
  padding: 10px 20px;
`;

const ActionButtonArea = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  border-top: 1px solid ${Colors.borderGray};
  border-bottom: 1px solid ${Colors.borderGray};
  box-sizing: border-box;
  background-color: ${Colors.bgGray};
`;

const ActionButton = styled.div`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.pink};
  line-height: 20px;
  padding: 10px 0;
`;

const StyledIcon = styled(Icon)`
  height: 20px;
`;

type Props = {
  currentPayment: CurrentPayment;
  setPurchasing: (value: boolean) => void;
};

const ExtendPlayAction: FC<Props> = ({ currentPayment, setPurchasing }) => {
  const [time, updateTime] = useState(Date.now());

  useEffect(() => {
    const timeoutId = setTimeout(() => updateTime(Date.now()), 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [time]);

  return (
    <>
      <Notification>
        残り時間：{remainedTimeStr(currentPayment.PlayEndAt)}
        <br />
        延長する場合は、プレイヤーと相談し、30分単位で行うことができます。
      </Notification>
      <ActionButtonArea>
        <ActionButton onClick={() => setPurchasing(true)}>
          <StyledIcon />
          <br />
          延長
        </ActionButton>
      </ActionButtonArea>
    </>
  );
};

export default ExtendPlayAction;
