import Axios from 'axios';
import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import Caption from 'Components/Parts/Forms/Caption';
import { InputField } from 'Components/Parts/Forms/Input';
import MenuTitle from 'Components/Parts/MenuTitle';
import { NotificationSuccess } from 'Components/Parts/Notification';
import SectionLabel from 'Components/Parts/SectionLabel';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CampaignCode } from 'Schemes/CampaignCode';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import PlayerWorkInput from './PlayerWorkInput';
import PlayerWorkUpdate from './PlayerWorkUpdate';

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: ${Colors.white};
`;

const StyledSectionLabel = styled(SectionLabel)`
  margin-top: 40px;
`;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 140px;
  height: 140px;
  border-radius: 70px;
  margin: 0 auto;
`;

const ProfileName = styled.p`
  margin: 20px auto 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

const StyledInputField = styled(InputField)`
  border-radius: 5px 0 0 5px;
  width: 80%;
  display: inline-block;
  box-sizing: border-box;
  border-right: none;
  margin-top: -1px;
`;

const CopyButton = styled(PrimaryButton)`
  width: 20%;
  border-radius: 0 5px 5px 0;
  display: inline-block;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  border: 1px solid ${Colors.pink};
`;

const CampaignDescription = styled.div`
  margin: 0px;
`;

const CampaignCodeLabel = styled.h3`
  margin-top: 20px;
  font-size: 14px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledA = styled.a`
  text-decoration: none;
`;

const StyledBaseButton = styled(BaseButton)`
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin-bottom: 10px;
  text-align: center;
`;

type Props = {
  playerProfile: PlayerProfile;
} & RouteComponentProps;

const PlayerDashboardPage: FC<Props> = ({ playerProfile }) => {
  const [player, setPlayer] = useState<PlayerProfile>(playerProfile);
  const [successCopyProfileURL, setSuccessCopyProfileURL] = useState(false);
  const profileURLInputRef = useRef<HTMLInputElement>(null);
  const [campaignCode, setCampaignCode] = useState<CampaignCode | null>(null);
  const [successCopyCampaignCode, setSuccessCopyCampaignCode] = useState(false);
  const campaignCodeInputRef = useRef<HTMLInputElement>(null);

  const startWork = (endAt: number) => {
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/player/work/start`, { WorkEndAt: endAt })
      .then((res) => {
        setPlayer(res.data);
      });
  };

  const endWork = () => {
    Axios.create({ withCredentials: true })
      .post(`${Config.API_HOST}/player/work/end`)
      .then((res) => {
        setPlayer(res.data);
      });
  };

  useEffect(() => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/player/campaign`)
      .then((res) => {
        setCampaignCode(res.data);
      });
  }, []);

  const playerWorkArea = () => {
    return player.WorkEndAt ? (
      <PlayerWorkUpdate playerProfile={player} startWork={startWork} endWork={endWork} />
    ) : (
      <PlayerWorkInput startWork={startWork} />
    );
  };

  const onFocusProfileURL = () => {
    if (profileURLInputRef.current) {
      profileURLInputRef.current.select();
    }
  };

  let timerId: number | null = null;
  const copyProfileURL = (timerId: number | null) => {
    if (profileURLInputRef.current) {
      profileURLInputRef.current.select();
      document.execCommand('Copy');
      if (window.getSelection) {
        window.getSelection()?.removeAllRanges();
      }
      setSuccessCopyProfileURL(true);
      if (timerId) clearInterval(timerId);
      timerId = window.setTimeout(() => {
        setSuccessCopyProfileURL(false);
      }, 2000);
    }
  };

  const onFocusCampaignCode = () => {
    if (campaignCodeInputRef.current) {
      campaignCodeInputRef.current.select();
    }
  };

  let campaignCodeTimerId: number | null = null;
  const copyCampaignCode = (timerId: number | null) => {
    if (campaignCodeInputRef.current) {
      campaignCodeInputRef.current.select();
      document.execCommand('Copy');
      if (window.getSelection) {
        window.getSelection()?.removeAllRanges();
      }
      setSuccessCopyCampaignCode(true);
      if (timerId) clearInterval(timerId);
      campaignCodeTimerId = window.setTimeout(() => {
        setSuccessCopyCampaignCode(false);
      }, 2000);
    }
  };

  return (
    <BasicLayout>
      <MenuTitle>ダッシュボード</MenuTitle>
      <Wrapper>
        <ProfileImage profileImage={playerMainProfileImage(player)} />
        <ProfileName>{player.Name}</ProfileName>

        <StyledSectionLabel>プロフィール用URL</StyledSectionLabel>
        {successCopyProfileURL && <NotificationSuccess>コピーされました</NotificationSuccess>}
        <div>
          <StyledInputField
            readOnly
            type="text"
            value={`${Config.FRONT_HOST}/p/${player.ShortURL}`}
            onFocus={onFocusProfileURL}
            ref={profileURLInputRef}
          ></StyledInputField>
          <CopyButton onClick={() => copyProfileURL(timerId)}>コピー</CopyButton>
        </div>
        <Caption>※ SNSにシェアする用にご活用ください</Caption>

        {playerWorkArea()}

        {campaignCode && (
          <>
            <StyledSectionLabel>{campaignCode.CampaignName}</StyledSectionLabel>
            <CampaignDescription>
              各プレイヤーが利用できる、<b>30分無料でプレイできる招待コードを5名分、</b>
              2PLAYからプレゼント！チケット購入時に招待コードを入力いただくことで、利用できます。直接伝えるもよし、Twitterにつぶやいて先着にするもよし、この機会に自由にお使いください！
            </CampaignDescription>
            <Caption>※ 無料分は2PLAYが負担します。プレイヤーの方々にはきちんと報酬が支払われます。</Caption>
            <CampaignCodeLabel>{player.Name}さんの無料招待コード</CampaignCodeLabel>
            <div>
              <StyledInputField
                readOnly
                type="text"
                value={campaignCode.Code}
                onFocus={onFocusCampaignCode}
                ref={campaignCodeInputRef}
              ></StyledInputField>
              <CopyButton onClick={() => copyCampaignCode(campaignCodeTimerId)}>コピー</CopyButton>
            </div>
            {successCopyCampaignCode && <NotificationSuccess>コピーされました</NotificationSuccess>}
            <Caption>
              ※ 残り<b>{campaignCode.RestCount}</b>名利用することができます
            </Caption>
          </>
        )}

        <StyledSectionLabel>プレイヤー管理</StyledSectionLabel>
        <StyledLink to="/player/inboxes">
          <StyledBaseButton>メッセージ</StyledBaseButton>
        </StyledLink>
        <StyledLink to="/player/profile">
          <StyledBaseButton>プロフィール編集</StyledBaseButton>
        </StyledLink>
        <StyledLink to="/player/payments">
          <StyledBaseButton>購入一覧</StyledBaseButton>
        </StyledLink>
        <StyledLink to="/player/followers">
          <StyledBaseButton>フォロワー</StyledBaseButton>
        </StyledLink>
        <StyledLink to="/player/payments/balance">
          <StyledBaseButton>売上確認・振込申請</StyledBaseButton>
        </StyledLink>
        <StyledA href={`${Config.API_HOST}/logout`}>
          <StyledBaseButton>ログアウト</StyledBaseButton>
        </StyledA>
      </Wrapper>
    </BasicLayout>
  );
};

export default withRouter(PlayerDashboardPage);
