import { useState, useEffect } from 'react';
import Config from '../../Config/Config';
import Axios from 'axios';

import 'Assets/stylesheets/top/style.css';
import Header from './Header';
import Mv from './Mv';
import About from './About';
import Suggest from './Suggest';
import Addition from './Addition';
import Voice from './Voice';
import Players from './Players';
import Review from './Review';
import Games from './Games';
import Entry from './Entry';
import Price from './Price';
import Flow from './Flow';
import FAQ from './FAQ';
import CTA from './CTA';
import Footer from './Footer';
import { PlayerList } from 'Schemes/Player';

const Root = () => {
  /* ページ読み込み完了フラグを設定 */
  const [initialLoaded, setInitialLoaded] = useState<boolean>(false);
  /* suggestは8名・playersは16名表示それぞれランダムに出力 */
  const [featuredForSuggest, setFeaturedForSuggest] = useState<PlayerList[]>([] as PlayerList[]);
  const [featuredForPlayers, setFeaturedForPlayers] = useState<PlayerList[]>([] as PlayerList[]);

  const addRootClassName = () => {
    const path = window.location.pathname;
    if (path === '/') {
      const root = document.getElementById('root');
      root?.classList.add('root-for-top');
    }
  };

  const removeRootClassName = () => {
    const root = document.getElementById('root');
    root?.classList.remove('root-for-top');
  };

  const shuffle = (arr: Array<any>, n: number) => {
      const result = [];
      let len = arr.length;
      const taken = new Array(len);

      if (n > len) n = len;

      while (n--) {
          const x = Math.floor(Math.random() * len);
          result[n] = arr[x in taken ? taken[x] : x];
          taken[x] = --len in taken ? taken[len] : len;
      }

      return result;
  }

  useEffect(() => {
    addRootClassName();

    const savedHash: string = window.location.hash;
    if (savedHash && initialLoaded) {
      window.location.hash = '';
      window.location.hash = savedHash;
    }
    const fetchFeatured = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/players/featured`)
        .then((res) => {
          setFeaturedForSuggest(shuffle(res.data, 8));
          setFeaturedForPlayers(shuffle(res.data, 16));
        });
    };

    fetchFeatured();
    setInitialLoaded(true);
    return () => {
      removeRootClassName();
    };
  }, [initialLoaded]);

  return (
    <div className="top">
      <Header />
      <main className="l-m-main">
        <Mv />
        <About />
        <Suggest featurePlayers={featuredForSuggest} />
        <Addition />
        <Voice />
        <Players featurePlayers={featuredForPlayers} />
        <Review />
        <Games />
        <Entry />
        <Price />
        <Flow />
        <FAQ />
        <CTA />
      </main>
      <Footer />
    </div>
  );
};

export default Root;
