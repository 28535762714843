export function numberToPrice(price: number): string {
  const priceStr = String(price).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  return `${priceStr}円`;
}

export function numberToByte(size: number): string {
  const kb = 1024;
  const mb = Math.pow(kb, 2);
  const gb = Math.pow(kb, 3);
  const tb = Math.pow(kb, 4);

  let target = 1;
  let unit = 'byte';

  if (size >= tb) {
    target = tb;
    unit = 'TB';
  } else if (size >= gb) {
    target = gb;
    unit = 'GB';
  } else if (size >= mb) {
    target = mb;
    unit = 'MB';
  } else if (size >= kb) {
    target = kb;
    unit = 'KB';
  }

  const res = Math.ceil(size / target);
  return `${res}${unit}`;
}
