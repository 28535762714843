import Axios from 'axios';
import MenuTitle from 'Components/Parts/MenuTitle';
import UserPaymentBlock from 'Components/UserSettings/UserPaymentBlock';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC, useEffect, useState } from 'react';
import { UserPayment } from 'Schemes/Payment';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  width: 100%;
  margin: 10px 0;
`;

const NoPayments = styled.p`
  margin: 20px;
`;

const UserPaymentsPage: FC = () => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [payments, setPayments] = useState<UserPayment[]>([]);

  useEffect(() => {
    const fetchPayment = () => {
      Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user/payments`)
        .then((res) => {
          setInitialLoaded(true);
          setPayments(res.data);
        });
    };

    fetchPayment();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <MenuTitle>購入履歴</MenuTitle>
        <Wrapper>
          {payments.map((payment) => {
            return <UserPaymentBlock key={payment.UUID} payment={payment} />;
          })}
          {initialLoaded && payments.length === 0 && (
            <NoPayments>
              プレイしたプレイヤーはいません。
              <br />※ プレイヤーとのプレイが終了すると表示されます
            </NoPayments>
          )}
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default UserPaymentsPage;
