import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { PrimaryButton } from './Parts/Buttons';

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const NotFound = styled.p`
  margin-top: 100px;
  font-size: 60px;
  line-height: 50px;
  color: ${Colors.pink};
  font-weight: bold;
  > span {
    line-height: 30px;
    font-size: 24px;
  }
`;

const Caption1 = styled.p`
  color: ${Colors.blue};
  margin: 40px 0 12px;
  font-weight: bold;
`;

const Caption2 = styled.p`
  color: ${Colors.gray};
  margin: 0 0 40px; ;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-size: 18px;
`;

const PageNotFound: FC = () => {
  return (
    <Wrapper>
      <div>
        <NotFound>
          404
          <br />
          <span>Not Found</span>
        </NotFound>

        <Caption1>指定されたページは存在しません。</Caption1>
        <Caption2>URLをもう一度ご確認ください</Caption2>
        <Link to="/players">
          <StyledPrimaryButton>素敵なプレイヤーに出会う</StyledPrimaryButton>
        </Link>
      </div>
    </Wrapper>
  );
};

export default PageNotFound;
