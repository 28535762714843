import { FC, useEffect, useState } from 'react';
import MenuTitle from 'Components/Parts/MenuTitle';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import Axios from 'axios';
import Config from 'Config/Config';
import Colors from 'Utils/Colors';
import FollowBox from 'Components/Players/FollowBox';
import BasicLayout from 'Pages/Layouts/Basic';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div`
  width: 100%;
`;

const NoFollowers = styled.p`
  margin: 20px;
`;

const StyledFollowBox = styled(FollowBox)`
  text-decoration: none;
  color: ${Colors.black};
  border-bottom: 1px solid ${Colors.borderGray};
`;

const UserFollowersPage: FC = () => {
  const [players, setPlayers] = useState<PlayerProfile[]>([]);
  const [initialLoaded, setInitialLoaded] = useState(false);

  const fetchFollowers = () => {
    Axios.create({ withCredentials: true })
      .get(`${Config.API_HOST}/user/followers`)
      .then((res) => {
        setInitialLoaded(true);
        setPlayers(res.data);
      });
  };

  useEffect(() => {
    fetchFollowers();
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <Wrapper>
          <MenuTitle>フォローしているプレイヤー</MenuTitle>
          {players.map((player) => {
            return <StyledFollowBox key={`players${player.UUID}`} player={player} isFollowing={true} />;
          })}
          {initialLoaded && players.length === 0 && <NoFollowers>フォローしているプレイヤーはまだいません。</NoFollowers>}
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default UserFollowersPage;
