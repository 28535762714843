import { FC, ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'Utils/Colors';

const TextAreaWrapper = styled.div`
  position: relative;
  font-size: 1rem;
  line-height: 1.8;
  width: 80%;
`;

const DummyTextArea = styled.div`
  overflow: hidden;
  visibility: hidden;
  box-sizing: border-box;
  padding: 10px 15px;
  min-height: 50px;
  line-height: 30px;
  max-height: calc(20px + 30px * 5);
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const TextArea = styled.textarea`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px 15px;
  line-height: 30px;
  width: 100%;
  height: 100%;
  border: 1px solid ${Colors.borderGray};
  border-radius: 5px;
  background-color: ${Colors.white};
  border-radius: 4px;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  resize: none;

  &:focus {
    box-shadow: 0 0 0 4px rgba(35, 167, 195, 0.3);
    outline: 0;
  }
`;

type Props = {
  inputMessage: string;
  className?: string;
  onChange: { (message: string): void };
};

const MessageInputArea: FC<Props> = ({ onChange, inputMessage, className }) => {
  const [text, setText] = useState(inputMessage);

  useEffect(() => {
    setText(inputMessage);
  }, [inputMessage]);

  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target === null) return;
    onChange(e.target.value);
  };

  return (
    <TextAreaWrapper className={className}>
      <DummyTextArea aria-hidden={true}>{`${text}\u200b`}</DummyTextArea>
      <TextArea onChange={onChangeText} value={inputMessage} />
    </TextAreaWrapper>
  );
};

export default MessageInputArea;
