import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  enabled: boolean;
  link: string;
  children: ReactNode;
};

export const EnabledLink: FC<Props> = ({ enabled, children, link }) => {
  if (!enabled) return <>{children}</>;
  return <Link to={link}>{children}</Link>;
};

export const EnabledA: FC<Props> = ({ enabled, children, link }) => {
  if (!enabled) return <>{children}</>;
  return <a href={link}>{children}</a>;
};
