import BasicLayout from './Layouts/Basic';
import styled from 'styled-components';
import { useContext, useEffect } from 'react';
import queryString from 'query-string';
import Axios from 'axios';
import { UserContext } from '../App';
import { useCookies } from 'react-cookie';

const Wrapper = styled.div``;
const TextWrapper = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 100px;
`;

const ThanksPage = () => {
  const user = useContext(UserContext)?.user;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, _setCookie] = useCookies();

  useEffect(()=>{
    const params = queryString.stringify({
      session_id: cookies.session_id,
      ma_campaign_id: process.env.REACT_APP_CAMPAIGN_ID,
      oid: user?.UUID,
    });

    const timer = setTimeout(() => {
      const postback = async () => {
        await Axios.get(`https://x-api.cir.io/postback/web_s2s?${params}`);
      };
      postback();

      window.location.href = '/players';
    }, 5000);

    return () => clearTimeout(timer);
  })

  return (
    <BasicLayout>
      <Wrapper>
        <TextWrapper>
          ご登録ありがとうございます。<br/>
          5秒後にマイページに移動します。
        </TextWrapper>
      </Wrapper>
    </BasicLayout>
  )
}

export default ThanksPage;