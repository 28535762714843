import { Link } from 'react-router-dom';
import 'Assets/stylesheets/top/cta.css';
import CTAIllust2X from 'Assets/images/top/cta_illust@2x.webp';
import CTAIllustSp from 'Assets/images/top/cta_illust_sp.webp';
import CTABalloon2X from 'Assets/images/top/cta_balloon@2x.webp';
import CTABalloonSp from 'Assets/images/top/cta_balloon_sp.webp';
import CTAImage01 from 'Assets/images/top/cta_image01@2x.webp';
import CTAImage02 from 'Assets/images/top/cta_image02@2x.webp';

const CTA = () => {
  return (
    <div className="p-m-index-cta is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-cta__top">
            <figure className="p-m-index-cta__illust">
              <picture>
                <source media="(min-width: 751px)" width="1391" height="508" srcSet={CTAIllust2X} />
                <source media="(max-width: 750px)" width="938" height="508" srcSet={CTAIllustSp} />
                <img src={CTAIllust2X} loading="lazy" alt="イラスト" />
              </picture>
            </figure>
            <p className="p-m-index-cta__p01">30分実質無料!</p>
            <figure className="p-m-index-cta__balloon">
              <picture>
                <source media="(min-width: 751px)" width="452" height="80" srcSet={CTABalloon2X} />
                <source media="(max-width: 750px)" width="414" height="79" srcSet={CTABalloonSp} />
                <img src={CTABalloon2X} loading="lazy" alt="吹き出し" />
              </picture>
            </figure>
            <p className="p-m-index-cta__p02">
              amazonギフト券
              <br />
              <span className="aux01">プレゼントキャンペーン</span>
            </p>
            <Link to="/sign_up" className="p-m-index-cta__button u-m-button--gradation">
              まずはLINE登録
            </Link>
            <figure className="p-m-index-cta__image01">
              <img src={CTAImage01} width="74" height="47" loading="lazy" alt="コントローラー" />
            </figure>
            <figure className="p-m-index-cta__image02">
              <img src={CTAImage02} width="56" height="64" loading="lazy" alt="ヘッドセット" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
