import { FC, ReactNodeArray } from 'react';
import { InboxObject } from 'Schemes/InboxObject';
import { PlayerProfile } from 'Schemes/Player';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { playerMainProfileImage } from 'Utils/ProfileImage';
import MyMessage from '../MessageObjects/MyMessage';
import SystemMessage from '../MessageObjects/SystemMessage';
import YourMessage from '../MessageObjects/YourMessage';

const Wrapper = styled.div`
  background-color: ${Colors.gbPink};
  padding-top: 70px;
  padding-bottom: 180px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;

type Props = {
  className?: string;
  inboxObjects: InboxObject[];
  sendingInboxObjects: string[];
  player: PlayerProfile | null;
};

const MessageArea: FC<Props> = ({ className, inboxObjects, sendingInboxObjects, player }) => {
  const content = (): ReactNodeArray => {
    let children = [];

    for (let i in inboxObjects) {
      const inboxObject = inboxObjects[i];
      if (inboxObject.ObjectType === 'user_message') {
        children.push(<MyMessage key={i} message={inboxObject.ToUser} sentAt={inboxObject.SentAt} />);
      } else if (inboxObject.ObjectType === 'player_message') {
        children.push(
          <YourMessage
            key={i}
            message={inboxObject.ToUser}
            profileImage={playerMainProfileImage(player)}
            sentAt={inboxObject.SentAt}
            playerUUID={player?.UUID}
          />,
        );
      } else {
        children.push(<SystemMessage key={i} message={inboxObject.ToUser} sentAt={inboxObject.SentAt} />);
      }
    }

    for (let i in sendingInboxObjects) {
      const message = sendingInboxObjects[i];
      children.push(<MyMessage key={i} message={message} sentAt="送信中" />);
    }

    return children;
  };

  return <Wrapper className={className}>{content()}</Wrapper>;
};

export default MessageArea;
