export type CurrentPayment = {
  UUID: string;
  Status: PaymentStatus;
  PlayEndAt: string;
  CaptureTimelimitAt: string;
  PlayerStatus: number;
};

export type PlayerPayment = {
  UUID: string;
  UserUUID: string;
  UserName: string;
  UserProfileImageURL: string;
  Sales: number;
  LastUpdatedAt: string;
};

export type UserPayment = {
  UUID: string;
  PlayerUUID: string;
  PlayerName: string;
  PlayerProfileImageURL: string;
  Sales: number;
  PlayMinutes: number;
  LastUpdatedAt: string;
};

export type PaymentBalance = {
  AvailableAmount: number;
  PendingAmount: number;
  LoginLink: string;
};

export type PaymentMethod = {
  ID: string;
  Brand: string;
  ExpMonth: number;
  ExpYear: number;
  Last4: string;
};

export enum PaymentStatus {
  Authorized = 0, // 仮決済
  Captured = 1, // プレー中（本決済）
  Played = 2, // プレー後
  Timeouted = 900, // 時間でキャンセル
  PlayerCanceled = 901, // プレイヤーからのキャンセル
}
