
import styled from 'styled-components';
import { User } from 'Schemes/User';
import { BaseButton } from 'Components/Parts/Buttons';
import Colors from 'Utils/Colors';
import { Link } from 'react-router-dom';
import { userMainProfileImage } from 'Utils/ProfileImage';
import { FC } from 'react';

type Props = {
  user: User;
  isFollowing: boolean;
};

const Wrapper = styled.section`
  width: 100%;
  background: white;
  height: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.borderGray};
`;

const Left = styled.div`
  display: table;
`;

const Right = styled.div``;

const ProfileImage = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 20px 8px 20px 20px;
  float: left;
`;

const ProfileName = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  height: 90px;
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 8px;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
`;

const StyledBaseButton = styled(BaseButton)`
  margin: 27px 20px;
`;

const FollowBox: FC<Props> = ({ user }) => {
  return (
    <Wrapper>
      <Left>
        <ProfileImage profileImage={userMainProfileImage(user)} />
        <ProfileName>{user.Name}</ProfileName>
      </Left>
      <Right>
        <Link to={`/player/chat/${user.UUID}`}>
          <StyledBaseButton>チャット</StyledBaseButton>
        </Link>
      </Right>
    </Wrapper>
  );
};

export default FollowBox;
