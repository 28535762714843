import Config from 'Config/Config';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import Colors from 'Utils/Colors';
import { CancelButton } from '../Buttons';
import CommonModal from './CommonModal';
import { useLocation } from 'react-router-dom';

const ConfirmText = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin: 0 0 20px 0;
`;

const MessageArea = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 20px;
`;

const ButtonArea = styled.div`
  display: flex;
  width: 100%;
`;

const ButtonCSS = css`
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
`;

const StyledA = styled.a`
  ${ButtonCSS}
  background-color: ${Colors.pink};
  color: ${Colors.white};
  border-radius: 5px;
  font-weight: bold;
  width: 65%;
  margin-left: 5%;
`;

const StyleCancelButton = styled(CancelButton)`
  ${ButtonCSS}
  width: 30%;
`;

const LineIcon = styled.img`
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  margin: 0 10px 0 0;
`;

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const LineNotifyModal: FC<Props> = ({ isOpen, closeModal }) => {
  const location = useLocation();
  const uri = Config.FRONT_HOST + location.pathname + location.search;
  const redirectURI = encodeURIComponent(uri);

  return (
    <CommonModal title="LINEで通知を受けとろう！" isOpen={isOpen} closeModal={closeModal}>
      <MessageArea>
        <LineIcon src={'/LINE_APP.png'} />
        <ConfirmText>
          カンタン連携！
          <br />
          メッセージ通知などをLINEでリアルタイムに受け取ることができます。
        </ConfirmText>
      </MessageArea>
      <ButtonArea>
        <StyleCancelButton value="いいえ" onClick={closeModal} />
        <StyledA href={`${Config.API_HOST}/oauth/line_notify?RedirectTo=${redirectURI}`}>はい</StyledA>
      </ButtonArea>
    </CommonModal>
  );
};

export default LineNotifyModal;
