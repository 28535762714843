import MenuTitle from 'Components/Parts/MenuTitle';
import Config from 'Config/Config';
import BasicLayout from 'Pages/Layouts/Basic';
import { FC } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { Helmet } from 'react-helmet';

const Wrapper = styled.div``;

const MenuUL = styled.ul`
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: ${Colors.black};
  }
`;

const MenuList = styled.li`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid ${Colors.borderGray};
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  text-indent: 20px;
`;

const UserSettingPage: FC = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <BasicLayout>
        <Wrapper>
          <MenuTitle>アカウント設定</MenuTitle>
          <MenuUL>
            <Link to="/settings/profile">
              <MenuList>プロフィール設定</MenuList>
            </Link>
            <Link to="/line_notify">
              <MenuList>通知設定（LINE）</MenuList>
            </Link>
            <a href={`${Config.API_HOST}/logout`}>
              <MenuList>ログアウト</MenuList>
            </a>
          </MenuUL>
        </Wrapper>
      </BasicLayout>
    </>
  );
};

export default UserSettingPage;
