import styled from 'styled-components';
import Colors from 'Utils/Colors';

const Caption = styled.p`
  color: ${Colors.gray};
  line-height: 20px;
  font-size: 12px;
  font-weight: normal;
`;

export default Caption;
