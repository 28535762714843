import { BaseButton, PrimaryButton } from 'Components/Parts/Buttons';
import { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { UserPayment } from 'Schemes/Payment';
import styled from 'styled-components';
import Colors from 'Utils/Colors';
import { numberToPrice } from 'Utils/NumberHelper';
import { formatTimeString } from 'Utils/TimeUtil';

const Wrapper = styled.div`
  background-color: ${Colors.white};
  margin: 10px 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  height: 110px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.borderGray};
`;

const Content = styled.div`
  flex-grow: 1;
`;

const Image = styled.div<{ profileImage: string }>`
  background-image: url(${(props) => props.profileImage});
  width: 70px;
  height: 70px;
  margin-right: 20px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  float: left;
`;

const LastUpdatedAt = styled.p`
  margin: 0;
  color: ${Colors.gray};
  font-size: 10px;
`;

const UserName = styled.p`
  margin: 0;
  color: ${Colors.black};
  line-height: 30px;
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Sales = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  color: ${Colors.pink};
`;

const StyledBaseButton = styled(BaseButton)`
  margin-bottom: 8px;
  display: block;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  display: block;
`;

type Props = {
  payment: UserPayment;
} & RouteComponentProps;

const UserPaymentBlock: FC<Props> = ({ payment, history }) => {
  return (
    <Wrapper>
      <Content>
        <Image profileImage={payment.PlayerProfileImageURL} />
        <div>
          <UserName>{payment.PlayerName}</UserName>
          <Sales>{numberToPrice(payment.Sales)}</Sales>
          <LastUpdatedAt>{formatTimeString(payment.LastUpdatedAt)}</LastUpdatedAt>
        </div>
      </Content>
      <div>
        <StyledBaseButton onClick={() => history.push(`/chat/${payment.PlayerUUID}`)}>チャット</StyledBaseButton>
        <StyledPrimaryButton onClick={() => history.push(`/settings/receipts/${payment.UUID}`)}>
          評価する
        </StyledPrimaryButton>
      </div>
    </Wrapper>
  );
};

export default withRouter(UserPaymentBlock);
