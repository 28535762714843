import styled from 'styled-components';
import Colors from 'Utils/Colors';

export const MenuUL = styled.ul`
  margin: 0;
  padding: 0;
  a {
    text-decoration: none;
    color: ${Colors.black};
  }
`;

export const MenuList = styled.li`
  cursor: pointer;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid ${Colors.borderGray};
  background-color: white;
  font-weight: bold;
  font-size: 14px;
  text-indent: 20px;
  position: relative;
`;
