import { FC } from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';

const Wrapper = styled.div`
  width: 100%;
`;

const StyledReactPaginate = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding-left: 0;
  li {
    margin-right: 6px;
  }
  li a {
    border-radius: 4px;
    padding: 6px 12px;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous a,
  li.next a,
  li.break a {
    font-size: 14px;
    border-color: transparent;
  }
  li.active a {
    background-color: #fb2877;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;

type Props = {
  totalCount: number;
  perPage: number;
  handlePageChange: (page: number) => void;
};
const Pagination: FC<Props> = ({ totalCount, perPage, handlePageChange }) => {
  return (
    <Wrapper style={{ display: totalCount > perPage ? 'block' : 'none' }}>
      <StyledReactPaginate
        pageCount={Math.ceil(totalCount / perPage)}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={({ selected }: { selected: number }) => handlePageChange(selected)}
        previousLabel={'< 前へ'}
        nextLabel={'次へ >'}
        breakLabel={'...'}
      />
    </Wrapper>
  );
};

export default Pagination;
