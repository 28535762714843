import GamesPage from 'Pages/Games/Index';
import PlayersPage from 'Pages/Players';
import UserDetailPage from 'Pages/Players/Detail';
import { createContext, FC, useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { CookiesProvider } from "react-cookie";

import LoginPage from 'Pages/Login';
import UserSettingPage from 'Pages/UserSettings';
import UserSettingProfilePage from 'Pages/UserSettings/Profile';
import UserFollowersPage from 'Pages/UserSettings/Followers';
import ErrorPage from 'Pages/Error';
import InboxDetailPage from 'Pages/Inboxs/Detail';
import PlayerInboxPage from 'Pages/Player/Inboxs/Index';
import PlayerInboxDetailPage from 'Pages/Player/Inboxs/Detail';
import InboxPage from 'Pages/Inboxs/Index';
import PalyerPaymentsPage from 'Pages/Player/Payments/Index';
import PalyerPaymentsBalancePage from 'Pages/Player/Payments/Balance';
import PlayerFollowersPage from 'Pages/Player/Followers';
import Register from 'Pages/Login/Register';
import Axios from 'axios';
import Config from 'Config/Config';
import { User, UserContextType } from 'Schemes/User';
import UserPaymentsPage from 'Pages/UserSettings/Payments';
import PlayerRegisterPage from 'Pages/Login/PlayerRegisterPage';
import RootPage from 'Pages/Top/RootPage';
import PlayerProfilePage from 'Pages/Player/Profile';
import UserPlayerReviewPage from 'Pages/UserSettings/PlayerReview';
import ScrollToTop from 'Components/ScrollTop';
import LineNotifyPage from 'Pages/LineNotify';
import ThanksPage from 'Pages/Thanks';
import NotFoundPage from 'Pages/Error/NotFound';

export const UserContext = createContext<UserContextType>({
  user: null,
  initialLoaded: false,
});

const App: FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [initialLoaded, setInitialLoaded] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      await Axios.create({ withCredentials: true })
        .get(`${Config.API_HOST}/user`)
        .then((response) => {
          setUser(response.data);
          setInitialLoaded(true);
        });
    };
    getUser();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <UserContext.Provider value={{ user: user, initialLoaded: initialLoaded }}>
        <CookiesProvider>
          <Route exact path="/" component={RootPage} />
          <Route exact path="/players" component={PlayersPage} />
          <Route path="/players/:uuid" component={UserDetailPage} />
          <Route exact path="/games" component={GamesPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/sign_up" component={Register} />
          <Route exact path="/thanks" component={ThanksPage} />

          <Route exact path="/settings" component={UserSettingPage} />
          <Route exact path="/settings/profile" component={UserSettingProfilePage} />
          <Route exact path="/settings/followers" component={UserFollowersPage} />
          <Route exact path="/settings/receipts" component={UserPaymentsPage} />
          <Route exact path="/settings/receipts/:uuid" component={UserPlayerReviewPage} />

          <Route exact path="/line_notify" component={LineNotifyPage} />

          <Route exact path="/inboxes" component={InboxPage} />
          <Route exact path="/chat/:uuid" component={InboxDetailPage} />

          <Route exact path="/player/register" component={PlayerRegisterPage} />
          <Route exact path="/player/profile" component={PlayerProfilePage} />

          <Route exact path="/player/inboxes" component={PlayerInboxPage} />
          <Route exact path="/player/chat/:uuid" component={PlayerInboxDetailPage} />
          <Route exact path="/player/followers" component={PlayerFollowersPage} />

          <Route exact path="/player/payments" component={PalyerPaymentsPage} />
          <Route exact path="/player/payments/balance" component={PalyerPaymentsBalancePage} />

          <Route exact path="/error" component={ErrorPage} />
          <Route exact path="/404" component={NotFoundPage} />
        </CookiesProvider>
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default App;
