import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'Assets/logo.svg';
import Colors from 'Utils/Colors';
import HowTo from 'Components/HowTo';
import { FC } from 'react';

const FooterWrapper = styled.footer`
  width: 100%;
  height: auto;
  background-color: ${Colors.bgPink};
  margin-top: auto;
`;

const FooterInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const FooterLeft = styled.div`
  text-align: center;
`;

const FooterRight = styled.div``;

const LogoImg = styled.img`
  width: auto;
  height: 51px;
  margin: 10px 0 9px;
`;

const TagLine = styled.p`
  font-weight: bold;
  font-size: 10px;
  margin-top: -18px;
`;

const FooterLinks = styled.ul`
  padding-top: 30px;
  font-size: 10px;
`;

const FooterLink = styled.li`
  display: inline-block;
  padding: 0 5px;
`;

const StyledLink = styled.a`
  color: ${Colors.linkPink};
`;

const CopyRight = styled.p`
  font-size: 10px;
  padding: 20px 0 10px;
  text-align: center;
  margin: 0;
`;

const CampaignImageWrapper = styled.div`
  width: 100%;
  padding: 24px 0;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const CampaignImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 90%;
  height: auto;
`;

const CampaignLink = styled.a`
  color: black;
  text-align: center;
  font-weight: bold;
  margin: 24px 0;
  font-size: 12px;
`;

const CampaignQRCodeImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 70%;
  height: auto;
`;

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <CampaignImageWrapper>
        <a href="https://media.2play.game/lp/campaign_202310" target="_blank" rel="noopener noreferrer">
          <CampaignImage src="/assets/2play_banner_20240516.jpg" alt="campaign banner image" />
        </a>
        <CampaignLink href="https://lin.ee/O75KyGd">＼2PLAY公式LINE登録で実質30分無料キャンペーン実施中／</CampaignLink>
        <CampaignLink href="https://lin.ee/O75KyGd" target="_blank" rel="noopener noreferrer">
          <CampaignQRCodeImage src="/assets/gainfriends_2dbarcodes_BW.png" alt="campaign qrcode image" />
        </CampaignLink>
      </CampaignImageWrapper>
      <HowTo />
      <FooterInner>
        <FooterLeft>
          <Link to="/">
            <LogoImg src={logo} alt="2PLAY" />
          </Link>
          <TagLine>一緒にやるから楽しい</TagLine>
        </FooterLeft>
        <FooterRight>
          <FooterLinks>
            <FooterLink>
              <StyledLink href="https://lp.2play.game/player_terms">利用規約</StyledLink>
            </FooterLink>
            |
            <FooterLink>
              <StyledLink href="https://lp.2play.game/privacy">プライバシーポリシー</StyledLink>
            </FooterLink>
            |
            <FooterLink>
              <StyledLink href="https://tayori.com/form/49cdc98fa647969dc59ede63c0df10a26a311c54">
                お問い合わせ
              </StyledLink>
            </FooterLink>
            |
            <FooterLink>
              <StyledLink href="https://lp.2play.game/about">運営会社</StyledLink>
            </FooterLink>
          </FooterLinks>
        </FooterRight>
      </FooterInner>

      <CopyRight>Copyright © 2020 2PLAY. All rights reserved.</CopyRight>
    </FooterWrapper>
  );
};

export default Footer;
