import { useState, useEffect, useMemo } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';

import Config from 'Config/Config';
import { Game } from 'Schemes/Game';
import 'Assets/stylesheets/top/game.css';

import GameHead2X from 'Assets/images/top/game_head@2x.webp';
import GameHeadSp from 'Assets/images/top/game_head_sp.webp';
import GameBalloon2X from 'Assets/images/top/game_balloon@2x.webp';
import GameBalloonSp from 'Assets/images/top/game_balloon_sp.webp';

const Games = () => {
  const FILTER_LIST: string[] = useMemo(
    () => [
      'APEX Legends',
      'VALORANT',
      'オーバーウォッチ2',
      'フォートナイト',
      'マイクラ',
      'デッドバイデイライト',
      '原神',
      '荒野行動',
      'タルコフ',
      'リーグオブレジェンド',
      'Fall Guys',
      'DARK AND DARKER',
    ],
    [],
  );
  const [games, setGames] = useState<Game[]>([]);

  useEffect(() => {
    const getGames = async () => {
      const response = await Axios.create({ withCredentials: true }).get(`${Config.API_HOST}/games`);
      setGames(() => {
        let gameList: Game[] = [];
        FILTER_LIST.forEach((str) => {
          response.data.forEach((game: Game) => {
            if (str === game.Name) {
              gameList.push(game);
            }
          });
        });

        return gameList;
      });
    };
    getGames();
  }, [FILTER_LIST]);

  return (
    <div className="p-m-index-game is-m-show">
      <div className="c-m-inner">
        <div className="c-m-inner__content">
          <div className="p-m-index-game__top">
            <h2 className="p-m-index-game__head">
              <picture>
                <source media="(min-width: 751px)" width="521" height="145" srcSet={GameHead2X} />
                <source media="(max-width: 750px)" width="420" height="117" srcSet={GameHeadSp} />
                <img src={GameHead2X} loading="lazy" alt="GAME" />
              </picture>
            </h2>
            <h3 className="p-m-index-game__h3">2PLAYで人気のゲーム一覧</h3>
            <p className="p-m-index-game__p01">
              2PLAYプレイヤーに
              <br className="u-m-only-sp" />
              <strong>人気のオンラインゲーム</strong>も<br className="u-m-only-sp" />
              一緒に遊べる
            </p>
            <figure className="p-m-index-game__balloon">
              <picture>
                <source media="(min-width: 751px)" width="988" height="58" srcSet={GameBalloon2X} />
                <source media="(max-width: 750px)" width="658" height="83" srcSet={GameBalloonSp} />
                <img src={GameBalloon2X} loading="lazy" alt="吹き出し" />
              </picture>
            </figure>
          </div>
          <div className="p-m-index-game__center">
            {games.map((game: Game) => (
              <Link key={game.ID} to={`/players?game_id=${game.ID}`}>
                <figure key={game.ID} className="p-m-index-game__icon">
                  <img src={game.IconURL} width="180" height="180" loading="lazy" alt={`${game.Name}のアイコン`} />
                  <figcaption className="p-m-index-game__figcaption">{game.Name}</figcaption>
                </figure>
              </Link>
            ))}
            <figure className="p-m-index-game__icon is-m-dummy"></figure>
            <figure className="p-m-index-game__icon is-m-dummy"></figure>
          </div>
          <p className="p-m-index-game__middle">
            ※ 2PLAYは特定のゲームで遊ぶことで対価が発生するサービスでは有りません
          </p>
          <div className="p-m-index-game__bottom">
            <Link to="/games" className="p-m-index-game__button u-m-button">
              全てのゲームを⾒る
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Games;
